import { muiTabStyles, muiTabsStyles } from 'styles/mixins';
import { theme } from 'styles/theme';

export const boxStyles = {
  borderBottom: `2px solid ${theme.colors.border}`,
};

export const tabsStyles = {
  ...muiTabsStyles,
};

export const tabStyles = {
  ...muiTabStyles,
  color: theme.colors.black,
  cursor: 'default',

  '&.Mui-selected': {
    color: theme.colors.primary,
  },

  '&.Mui-disabled': {
    color: theme.colors.textDisabled,
  },
};
