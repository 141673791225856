import Markdown from 'markdown-to-jsx';
import { styled } from 'styled-components';
import VisibilityIcon from 'assets/visibility-icon';
import { Body4, Heading7 } from 'components/typography';
import { breakpoint, radii, spacing } from 'styles/helpers';

const IMAGE_HEIGHT = '148px';

export const Card = styled.div`
  border: ${({ theme }) => theme.border.inactive};
  border-radius: ${radii.s};
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${spacing.s};
`;

export const ProductTitle = styled(Heading7)`
  margin: ${spacing['2xs']} 0;
`;

export const Tag = styled(Body4)`
  background-color: ${({ theme }) => theme.colors.highlight};
  border-radius: ${radii.s};
  color: ${({ theme }) => theme.colors.primary};
  padding: 3px ${spacing['2xs']};
  width: fit-content;
`;

export const ImageWrapper = styled.div`
  height: ${IMAGE_HEIGHT};
`;

export const StyledImg = styled.img`
  border-radius: 3px 3px 0 0;
  height: ${IMAGE_HEIGHT};
  object-fit: cover;
  width: 100%;
`;

export const DetailsContainer = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  display: flex;
  flex-direction: column;
  gap: ${spacing['2xs']};
  margin-top: auto;
  padding: ${spacing.s} 0;
`;

export const DetailsRow = styled.div`
  display: grid;
  gap: ${spacing['3xs']};
  grid-template-columns: repeat(2, 1fr);
`;

export const Description = styled(Markdown)`
  ${({ theme }) => theme.typography.body4};
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.textPrimary};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;

  ${breakpoint.mobile} {
    -webkit-line-clamp: 2;
  }
`;

export const StyledIcon = styled(VisibilityIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
