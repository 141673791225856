import { useTranslation } from 'react-i18next';

const useFormErrorTranslation = (touched?: boolean, error?: string) => {
  const { t } = useTranslation();

  if (touched && error) {
    return t(error) ?? '';
  }
  return '';
};

export default useFormErrorTranslation;
