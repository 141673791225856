import { useTranslation } from 'react-i18next';
import Table from 'components/table';
import { InputDetailsData } from 'shared/types';
import mapInputDetails from 'shared/utils/mapInputDetails';

interface InputDetailsProps {
  inputDetails?: InputDetailsData;
}

const InputDetails = ({ inputDetails }: InputDetailsProps) => {
  const { t } = useTranslation();
  const mappedInputDetails = mapInputDetails(t, inputDetails);

  return (
    <Table
      rowsData={mappedInputDetails}
      title={t('productDetails.inputDetails')}
    />
  );
};

export default InputDetails;
