const ColumnIcon = () => (
  <svg
    width="35"
    height="57"
    viewBox="0 0 35 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9.48232C3.91145 7.12471 8.54667 3.35768 11.458 1H34.5067V46.1513C31.56 48.5089 26.4446 53.589 23.498 55.9803H1V9.48232ZM22.5623 9.48232C24.7804 7.67993 29.7223 4.11993 31.8713 2.38667H11.9426L3.2516 9.48232H22.5623ZM33.12 3.14899L23.498 10.487V54.178L33.12 45.4933V3.14899ZM22.1113 10.869H2.38667V54.5936H22.1113V10.869Z"
      fill="#222465"
      stroke="#222465"
      strokeWidth="0.25"
    />
  </svg>
);

export default ColumnIcon;
