import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGetCountries from 'api/useGetCountries';
import CountryCard from 'components/country-card';
import Loader from 'components/loader';
import ScreenTitle from 'components/screen-title';
import BottomActions from 'features/bottom-actions';
import { ROUTES } from 'shared/constants';
import useLocalStorage from 'shared/hooks/useLocalStorage';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { Country } from 'shared/types';
import { CountryGrid, CountrySelectWrapper } from './styles';
import { useEffect, useState } from 'react';
import { HIDDEN_COUNTRIES } from './constants';

const CountrySelect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { countries, isLoading } = useGetCountries();
  const { getValueFromLocalStorage, saveValueToLocalStorage } = useLocalStorage();
  const [, setCountry] = useSessionStorage(SESSION_STORAGE_KEYS.country);
  const [defaultCountry, setDefaultCountry] = useState<Country | null>(null);

  const filteredCountries = countries?.filter(
    (country) => !HIDDEN_COUNTRIES.includes(country.code)
  );

  const handleCountryClick = (country: Country) => {
    saveValueToLocalStorage(SESSION_STORAGE_KEYS.country, {
      code: country.code,
      name: country.name,
    });
    setCountry({
      code: country.code,
      name: country.name,
      default: false,
    });
    navigate(ROUTES.structureSelect);
  };
  
  const navigateBack = () => navigate(ROUTES.home);
  
  useEffect(() => {
    const country = getValueFromLocalStorage(SESSION_STORAGE_KEYS.country);
    setDefaultCountry(country);
  }, []);
  
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CountrySelectWrapper>
        <ScreenTitle
          title={t('countrySelect.title')}
          tooltipText={t('countrySelect.tooltipText') ?? ''}
          tooltipWidth={165}
        />
        <CountryGrid>
          {countries &&
            filteredCountries?.map((country) => (
              <CountryCard
                key={country.name}
                title={t(`countrySelect.countries.${country.name}`)}
                onClick={() => handleCountryClick(country)}
                iconSvgString={country.flagIconSvg}
              />
            ))}
        </CountryGrid>
      </CountrySelectWrapper>
       <BottomActions
        onBackClick={navigateBack}
        hasBackButton={!!defaultCountry}
      />
    </>
  );
};

export default CountrySelect;
