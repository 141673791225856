import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import useGetLanguages from 'api/useGetLanguages';
import PromatLogo from 'assets/promat-logo.png';
import LanguageSelect from 'features/language-select';
import { DEFAULT_LANGUAGE, ROUTES } from 'shared/constants';
import useLocalStorage from 'shared/hooks/useLocalStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import getLanguageAndCountryFromLocale from 'shared/utils/getLanguageAndCountryFromLocale';
import {
  CountryText,
  HeaderContent,
  SelectWrapper,
  StyledHeader,
  StyledImg,
  StyledLink,
} from './styles';

const AppBar = () => {
  const { t, i18n } = useTranslation();
  const { languages } = useGetLanguages();
  const currentPage = useLocation();
  const { pathname } = currentPage;
  const { getValueFromLocalStorage } = useLocalStorage();
  const { languageCode } = getLanguageAndCountryFromLocale(i18n.language);
  const selectedLanguage =
    languages?.find((language) => language.code === languageCode)?.code ??
    DEFAULT_LANGUAGE;
  const selectedCountry = getValueFromLocalStorage(
    SESSION_STORAGE_KEYS.country
  );

  return (
    <StyledHeader>
      <HeaderContent>
        <StyledLink to={selectedCountry ? ROUTES.home : ROUTES.countrySelect}>
          <StyledImg src={PromatLogo} alt="Promat logo" />
        </StyledLink>
        {languages && selectedLanguage && (
          <SelectWrapper>
            <LanguageSelect
              languages={languages}
              selectedLanguage={selectedLanguage}
            />
          </SelectWrapper>
        )}
        {pathname !== ROUTES.home && selectedCountry && (
          <CountryText>
            {t('countrySelect.market', {
              countryName: t(`countrySelect.countries.${selectedCountry.name}`),
            })}
          </CountryText>
        )}
      </HeaderContent>
    </StyledHeader>
  );
};

export default AppBar;
