import { FC } from 'react';
import { DisclaimerWrapper, ParagraphWrapper } from './styles';
import useIsMobile from '../../shared/hooks/useIsMobile';

interface Props {
  text: string;
}
const Disclaimer: FC<Props> = ({ text }) => {
  const paragraphs = text.split('\n\n');
  const isMobile = useIsMobile();
  return (
    <DisclaimerWrapper $fullWidth={isMobile}>
      {paragraphs.map((paragraph, index) => (
        <ParagraphWrapper key={index}>{paragraph}</ParagraphWrapper>
      ))}
    </DisclaimerWrapper>
  );
};
export default Disclaimer;
