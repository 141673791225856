import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {
  CalculationResultData,
  InputDetailsData,
  ProductDetailsData,
} from 'shared/types';
import DownloadButton from './download-button';
import { getPdfFileName } from './logic';
import PdfTemplate from './pdf-template';

interface DownloadPdfProps {
  productDetails?: ProductDetailsData;
  calculationResult?: CalculationResultData;
  inputDetails?: InputDetailsData;
}

const DownloadPdf = ({
  productDetails,
  calculationResult,
  inputDetails,
}: DownloadPdfProps) => {
  const pdfName = getPdfFileName(productDetails?.name);

  const generatePdf = async () => {
    const blob = await pdf(
      <PdfTemplate
        productDetails={productDetails}
        calculationResult={calculationResult}
        inputDetails={inputDetails}
      />
    ).toBlob();
    saveAs(blob, pdfName);
  };

  return <DownloadButton onClick={generatePdf} />;
};

export default DownloadPdf;
