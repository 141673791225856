import { SvgProps } from './types';

const VisibilityIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79167 13.75C10.8333 13.75 11.7189 13.3856 12.4483 12.6567C13.1772 11.9272 13.5417 11.0417 13.5417 10C13.5417 8.95833 13.1772 8.07278 12.4483 7.34333C11.7189 6.61444 10.8333 6.25 9.79167 6.25C8.75 6.25 7.86445 6.61444 7.135 7.34333C6.40611 8.07278 6.04167 8.95833 6.04167 10C6.04167 11.0417 6.40611 11.9272 7.135 12.6567C7.86445 13.3856 8.75 13.75 9.79167 13.75ZM9.79167 12.25C9.16667 12.25 8.63556 12.0311 8.19833 11.5933C7.76056 11.1561 7.54167 10.625 7.54167 10C7.54167 9.375 7.76056 8.84361 8.19833 8.40583C8.63556 7.96861 9.16667 7.75 9.79167 7.75C10.4167 7.75 10.9481 7.96861 11.3858 8.40583C11.8231 8.84361 12.0417 9.375 12.0417 10C12.0417 10.625 11.8231 11.1561 11.3858 11.5933C10.9481 12.0311 10.4167 12.25 9.79167 12.25ZM9.79167 16.25C7.76389 16.25 5.91667 15.6839 4.25 14.5517C2.58333 13.42 1.375 11.9028 0.625 10C1.375 8.09722 2.58333 6.57972 4.25 5.4475C5.91667 4.31583 7.76389 3.75 9.79167 3.75C11.8194 3.75 13.6667 4.31583 15.3333 5.4475C17 6.57972 18.2083 8.09722 18.9583 10C18.2083 11.9028 17 13.42 15.3333 14.5517C13.6667 15.6839 11.8194 16.25 9.79167 16.25ZM9.79167 14.5833C11.3611 14.5833 12.8022 14.17 14.115 13.3433C15.4272 12.5172 16.4306 11.4028 17.125 10C16.4306 8.59722 15.4272 7.4825 14.115 6.65583C12.8022 5.82972 11.3611 5.41667 9.79167 5.41667C8.22222 5.41667 6.78111 5.82972 5.46833 6.65583C4.15611 7.4825 3.15278 8.59722 2.45833 10C3.15278 11.4028 4.15611 12.5172 5.46833 13.3433C6.78111 14.17 8.22222 14.5833 9.79167 14.5833Z"
      fill="currentColor"
    />
  </svg>
);

export default VisibilityIcon;
