import { SvgProps } from './types';

const CloseIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 15L5 14L9 10L5 6L6 5L10 9L14 5L15 6L11 10L15 14L14 15L10 11L6 15Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
