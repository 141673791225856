export const concreteInputDetailsSortOrder = [
  'orientationName',
  'depth',
  'width',
  'currentAxisDistance',
  'requiredAxisDistance',
  'buildingFunction',
  'humidity',
  'temperature',
  'fireRatingValue',
];
