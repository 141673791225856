import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import bgTranslation from './bg/translation.json';
import csTranslation from './cs/translation.json';
import daTranslation from './da/translation.json';
import deatTranslation from './de-at/translation.json';
import deTranslation from './de-de/translation.json';
import enTranslation from './en/translation.json';
import enAuTranslation from './en-AU/translation.json';
import enGBTranslation from './en-GB/translation.json';
import esTranslation from './es/translation.json';
import fiTranslation from './fi/translation.json';
import frTranslation from './fr/translation.json';
import hrTranslation from './hr/translation.json';
import huTranslation from './hu/translation.json';
import itTranslation from './it/translation.json';
import nlTranslation from './nl/translation.json';
import plTranslation from './pl/translation.json';
import roTranslation from './ro/translation.json';
import skTranslation from './sk/translation.json';
import slTranslation from './sl/translation.json';
import srTranslation from './sr/translation.json';
import svTranslation from './sv/translation.json';

const resources = {
  en: { translation: enTranslation },
  'en-AU': { translation: enAuTranslation },
  'en-GB': { translation: enGBTranslation },
  nl: { translation: nlTranslation },
  es: { translation: esTranslation },
  bg: { translation: bgTranslation },
  cs: { translation: csTranslation },
  da: { translation: daTranslation },
  'de-DE': { translation: deTranslation },
  'de-AT': { translation: deatTranslation },
  fi: { translation: fiTranslation },
  fr: { translation: frTranslation },
  hr: { translation: hrTranslation },
  hu: { translation: huTranslation },
  it: { translation: itTranslation },
  pl: { translation: plTranslation },
  ro: { translation: roTranslation },
  sk: { translation: skTranslation },
  sl: { translation: slTranslation },
  sr: { translation: srTranslation },
  sv: { translation: svTranslation },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    load: 'all',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
