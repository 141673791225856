import { createGlobalStyle } from 'styled-components';
import fontsCss from './fonts.css';

export default createGlobalStyle`
${fontsCss}
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;   
    }

    body {
        font-family: 'Avenir Next Regular', Helvetica, Arial, sans-serif;
        
        #onetrust-banner-sdk {
            outline: 0;
        }
    }

    #root {
        margin: 0 auto;
    }

    a, button {
        background-color: unset;
        border: 0;  
        cursor: pointer; 
        text-decoration: none;
    }
 `;
