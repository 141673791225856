import useLocale from 'shared/hooks/useLocale';
import useQuery from 'shared/hooks/useQuery';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { Product } from 'shared/types';

const useGetProducts = () => {
  const locale = useLocale();
  const [structure] = useSessionStorage(SESSION_STORAGE_KEYS.structure);
  const params = [locale, structure];

  const { data, error, isLoading, isError } = useQuery<Product[]>(
    'products',
    `v1/products/${structure}/${locale}`,
    params
  );

  return {
    products: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetProducts;
