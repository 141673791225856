const CustomProfileIcon = () => (
  <svg
    width="66"
    height="67"
    viewBox="0 0 66 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.56299 10.2286H7.81299C8.22705 10.2286 8.62549 10.0645 8.91849 9.77155C9.21149 9.47858 9.37552 9.08014 9.37552 8.66605V2.41605C9.37552 2.00199 9.21146 1.60355 8.91849 1.31055C8.62552 1.01755 8.22708 0.853516 7.81299 0.853516H1.56299C0.699708 0.853516 0.000488281 1.55274 0.000488281 2.41602V8.66602C0.000488281 9.08008 0.164548 9.47852 0.457518 9.77152C0.750488 10.0645 1.14893 10.2285 1.56302 10.2285L1.56299 10.2286ZM3.12549 3.97858H6.25049V7.10358H3.12549V3.97858Z"
      fill="#222465"
    />
    <path
      d="M7.81299 57.1048H1.56299C0.699708 57.1048 0.000488281 57.804 0.000488281 58.6673V64.9173C0.000488281 65.3313 0.164548 65.7298 0.457518 66.0228C0.750488 66.3158 1.14893 66.4798 1.56302 66.4798H7.81302C8.22708 66.4798 8.62552 66.3157 8.91852 66.0228C9.21152 65.7298 9.37555 65.3314 9.37555 64.9173V58.6673C9.37555 58.2532 9.21149 57.8548 8.91852 57.5618C8.62555 57.2688 8.22711 57.1047 7.81302 57.1047L7.81299 57.1048ZM6.25049 63.3548H3.12549V60.2298H6.25049V63.3548Z"
      fill="#222465"
    />
    <path
      d="M6.12549 14.3228C6.05908 14.1274 5.9419 13.9556 5.78174 13.8228C5.17236 13.229 4.20364 13.229 3.59424 13.8228C3.43408 13.9556 3.3169 14.1274 3.25049 14.3228C3.20361 14.5181 3.20361 14.7212 3.25049 14.9165C3.26611 15.772 3.95752 16.4634 4.81299 16.479C5.0083 16.5259 5.21143 16.5259 5.40674 16.479C5.60205 16.4126 5.77393 16.2954 5.90674 16.1353C6.19971 15.8032 6.32471 15.354 6.25049 14.9165C6.2583 14.7094 6.21533 14.5063 6.12549 14.3227V14.3228Z"
      fill="#222465"
    />
    <path
      d="M6.12549 20.5728C6.05908 20.3774 5.9419 20.2056 5.78174 20.0728C5.17236 19.479 4.20364 19.479 3.59424 20.0728C3.43408 20.2056 3.3169 20.3774 3.25049 20.5728C3.20361 20.7681 3.20361 20.9712 3.25049 21.1665C3.24658 21.5806 3.41455 21.979 3.71924 22.2603C3.85205 22.4205 4.02393 22.5376 4.21924 22.6041C4.59815 22.772 5.02783 22.772 5.40674 22.6041C5.60205 22.5377 5.77393 22.4205 5.90674 22.2603C6.16455 21.9556 6.28955 21.5611 6.25049 21.1665C6.2583 20.9595 6.21533 20.7563 6.12549 20.5728Z"
      fill="#222465"
    />
    <path
      d="M6.12549 26.8227C6.05908 26.6274 5.9419 26.4555 5.78174 26.3227C5.34424 25.8735 4.67234 25.7368 4.09424 25.979C3.71533 26.143 3.41455 26.4438 3.25049 26.8227C3.08252 27.2016 3.08252 27.6313 3.25049 28.0102C3.3169 28.2055 3.43408 28.3774 3.59424 28.5102C3.72705 28.6704 3.89893 28.7876 4.09424 28.854C4.28955 28.9008 4.49268 28.9008 4.68799 28.854C4.8833 28.8969 5.08643 28.8969 5.28174 28.854C5.65674 28.686 5.95752 28.3852 6.12549 28.0102C6.29346 27.6313 6.29346 27.2016 6.12549 26.8227Z"
      fill="#222465"
    />
    <path
      d="M5.78174 32.5728C5.17236 31.979 4.20364 31.979 3.59424 32.5728C3.43408 32.7056 3.3169 32.8774 3.25049 33.0728C3.20752 33.2681 3.20752 33.4712 3.25049 33.6665C3.20361 33.8618 3.20361 34.0649 3.25049 34.2603C3.31689 34.4556 3.43408 34.6274 3.59424 34.7603C3.8794 35.061 4.27393 35.2329 4.68804 35.2329C5.10215 35.2329 5.49663 35.061 5.78184 34.7603C5.942 34.6274 6.05918 34.4556 6.12559 34.2603C6.17246 34.0649 6.17246 33.8618 6.12559 33.6665C6.16465 33.272 6.03965 32.8774 5.78184 32.5727L5.78174 32.5728Z"
      fill="#222465"
    />
    <path
      d="M6.12549 39.3228C6.05909 39.1274 5.9419 38.9556 5.78174 38.8228C5.17236 38.229 4.20364 38.229 3.59424 38.8228C3.29346 39.1079 3.12158 39.5024 3.12158 39.9166C3.12158 40.3307 3.29346 40.7251 3.59424 41.0104C3.72705 41.1705 3.89893 41.2877 4.09424 41.3541C4.47315 41.5221 4.90283 41.5221 5.28174 41.3541C5.47705 41.2877 5.64893 41.1705 5.78174 41.0104C6.08643 40.7291 6.2544 40.3307 6.25049 39.9166C6.2544 39.7134 6.21143 39.5103 6.12549 39.3228V39.3228Z"
      fill="#222465"
    />
    <path
      d="M5.78272 45.0728C5.17334 44.479 4.20462 44.479 3.59522 45.0728C3.43506 45.2056 3.31788 45.3774 3.25147 45.5728C3.2085 45.7681 3.2085 45.9712 3.25147 46.1665C3.24756 46.5806 3.41553 46.979 3.72022 47.2603C3.85303 47.4205 4.02491 47.5376 4.22022 47.6041C4.59913 47.772 5.02881 47.772 5.40772 47.6041C5.60303 47.5377 5.77491 47.4205 5.90772 47.2603C6.2085 46.9751 6.38038 46.5806 6.38038 46.1665C6.38038 45.7524 6.2085 45.3579 5.90772 45.0727L5.78272 45.0728Z"
      fill="#222465"
    />
    <path
      d="M4.09424 50.9787C3.89893 51.0451 3.72705 51.1623 3.59424 51.3224C3.29346 51.6076 3.12158 52.0021 3.12158 52.4162C3.12158 52.8303 3.29346 53.2248 3.59424 53.51C3.72705 53.6702 3.89893 53.7874 4.09424 53.8538C4.28955 53.9006 4.49268 53.9006 4.68799 53.8538C5.10205 53.8577 5.50049 53.6897 5.78179 53.385C6.05913 53.1389 6.2271 52.7874 6.25054 52.4163C6.24273 52.0061 6.07476 51.6116 5.78179 51.3225C5.34429 50.8732 4.67239 50.7365 4.09429 50.9787L4.09424 50.9787Z"
      fill="#222465"
    />
    <path
      d="M14.6567 60.3547C14.2778 60.1985 13.8481 60.1985 13.4692 60.3547C13.2739 60.4211 13.102 60.5383 12.9692 60.6985C12.6645 60.9797 12.4966 61.3782 12.5005 61.7923C12.4536 61.9876 12.4536 62.1907 12.5005 62.386C12.5669 62.5813 12.6841 62.7532 12.8442 62.886C13.1294 63.1868 13.5239 63.3587 13.938 63.3587C14.3521 63.3587 14.7466 63.1868 15.0318 62.886C15.192 62.7532 15.3092 62.5813 15.3756 62.386C15.4225 62.1907 15.4225 61.9876 15.3756 61.7923C15.3795 61.3782 15.2115 60.9798 14.9068 60.6985C14.8482 60.5696 14.7623 60.4524 14.6568 60.3547H14.6567Z"
      fill="#222465"
    />
    <path
      d="M14.063 7.10474C14.2583 7.15162 14.4614 7.15162 14.6567 7.10474C14.852 7.03834 15.0239 6.92115 15.1567 6.76099C15.4966 6.44849 15.6685 5.99927 15.6255 5.54219C15.6724 5.34688 15.6724 5.14375 15.6255 4.94844C15.5591 4.75313 15.4419 4.58125 15.2817 4.44844C14.8442 3.99922 14.1723 3.8625 13.5942 4.10469C13.2153 4.26875 12.9145 4.56953 12.7505 4.94844C12.6177 5.12032 12.5317 5.32735 12.5005 5.54219C12.5161 6.39766 13.2075 7.08909 14.063 7.10469V7.10474Z"
      fill="#222465"
    />
    <path
      d="M56.2515 11.7918C56.2515 11.3777 56.0874 10.9793 55.7945 10.6863C55.5015 10.3933 55.1031 10.2292 54.689 10.2292H10.939C10.0757 10.2292 9.37646 10.9285 9.37646 11.7917V55.5417C9.37646 55.9558 9.54053 56.3542 9.8335 56.6472C10.1265 56.9402 10.5249 57.1043 10.939 57.1043H54.689C55.1031 57.1043 55.5015 56.9402 55.7945 56.6472C56.0875 56.3543 56.2515 55.9558 56.2515 55.5417L56.2515 11.7918ZM53.1265 53.9798H12.5015V13.3548H53.1265V53.9798Z"
      fill="#222465"
    />
    <path
      d="M64.063 0.854767H57.813C56.9497 0.854767 56.2505 1.55399 56.2505 2.41727V8.66727C56.2505 9.08133 56.4145 9.47977 56.7075 9.77277C57.0005 10.0658 57.3989 10.2298 57.813 10.2298H64.063C64.4771 10.2298 64.8755 10.0657 65.1685 9.77277C65.4615 9.4798 65.6255 9.08136 65.6255 8.66727V2.41727C65.6255 2.00321 65.4615 1.60477 65.1685 1.31177C64.8755 1.01877 64.4771 0.854736 64.063 0.854736L64.063 0.854767ZM62.5005 7.10477H59.3755V3.97977H62.5005V7.10477Z"
      fill="#222465"
    />
    <path
      d="M64.063 57.1048H57.813C56.9497 57.1048 56.2505 57.804 56.2505 58.6673V64.9173C56.2505 65.3313 56.4145 65.7298 56.7075 66.0228C57.0005 66.3158 57.3989 66.4798 57.813 66.4798H64.063C64.4771 66.4798 64.8755 66.3157 65.1685 66.0228C65.4615 65.7298 65.6255 65.3314 65.6255 64.9173V58.6673C65.6255 58.2532 65.4615 57.8548 65.1685 57.5618C64.8755 57.2688 64.4771 57.1047 64.063 57.1047L64.063 57.1048ZM62.5005 63.3548H59.3755V60.2298H62.5005V63.3548Z"
      fill="#222465"
    />
    <path
      d="M59.8442 16.0107C60.1254 16.3154 60.5239 16.4834 60.938 16.4795C61.1333 16.5263 61.3364 16.5263 61.5317 16.4795C61.9067 16.3115 62.2075 16.0107 62.3755 15.6357C62.5435 15.2568 62.5435 14.8271 62.3755 14.4482C62.3091 14.2529 62.1919 14.081 62.0317 13.9482C61.5942 13.499 60.9223 13.3623 60.3442 13.6044C59.9653 13.7685 59.6645 14.0693 59.5005 14.4482C59.3325 14.8271 59.3325 15.2568 59.5005 15.6357C59.5825 15.7841 59.7036 15.913 59.8442 16.0107H59.8442Z"
      fill="#222465"
    />
    <path
      d="M59.5015 21.7607C59.5679 21.956 59.6851 22.1279 59.8452 22.2607C59.978 22.4209 60.1499 22.538 60.3452 22.6044C60.5405 22.6513 60.7437 22.6513 60.939 22.6044C61.1343 22.6474 61.3374 22.6474 61.5327 22.6044C61.9077 22.4365 62.2085 22.1357 62.3765 21.7607C62.5444 21.3818 62.5444 20.9521 62.3765 20.5732C62.3101 20.3779 62.1929 20.206 62.0327 20.0732C61.5952 19.624 60.9233 19.4873 60.3452 19.7294C59.9663 19.8935 59.6655 20.1943 59.5015 20.5732C59.3335 20.9521 59.3335 21.3818 59.5015 21.7607Z"
      fill="#222465"
    />
    <path
      d="M59.8442 28.5106C59.977 28.6707 60.1489 28.7879 60.3442 28.8543C60.7231 29.0223 61.1528 29.0223 61.5317 28.8543C61.727 28.7879 61.8989 28.6707 62.0317 28.5106C62.3364 28.2293 62.5044 27.8309 62.5005 27.4167C62.5474 27.2214 62.5474 27.0183 62.5005 26.823C62.4341 26.6277 62.3169 26.4558 62.1567 26.323C61.5474 25.7292 60.5786 25.7292 59.9692 26.323C59.8091 26.4558 59.6919 26.6277 59.6255 26.823C59.5786 27.0183 59.5786 27.2214 59.6255 27.4167C59.5513 27.7957 59.6294 28.1902 59.8442 28.5106Z"
      fill="#222465"
    />
    <path
      d="M59.5014 34.2607C59.5678 34.456 59.685 34.6279 59.8451 34.7607C59.978 34.9208 60.1498 35.038 60.3451 35.1044C60.7241 35.2724 61.1537 35.2724 61.5326 35.1044C61.9076 34.9364 62.2084 34.6357 62.3764 34.2607C62.4233 34.0654 62.4233 33.8622 62.3764 33.6669C62.4194 33.4716 62.4194 33.2685 62.3764 33.0732C62.31 32.8779 62.1928 32.706 62.0326 32.5732C61.8998 32.413 61.728 32.2958 61.5326 32.2294C60.9506 31.995 60.2865 32.1318 59.8451 32.5732C59.5405 32.8544 59.3725 33.2529 59.3764 33.667C59.3686 33.874 59.4115 34.0771 59.5014 34.2607V34.2607Z"
      fill="#222465"
    />
    <path
      d="M59.8442 41.0107C59.977 41.1709 60.1489 41.2881 60.3442 41.3545C60.5395 41.4014 60.7427 41.4014 60.938 41.3545C61.7935 41.3389 62.4849 40.6475 62.5005 39.792C62.5435 39.5967 62.5435 39.3936 62.5005 39.1982C62.4341 39.0029 62.3169 38.8311 62.1567 38.6982C62.0239 38.5381 61.852 38.4209 61.6567 38.3545C61.2778 38.1982 60.8481 38.1982 60.4692 38.3545C60.2739 38.4209 60.102 38.5381 59.9692 38.6982C59.6685 38.9834 59.4966 39.3779 59.4966 39.792C59.4966 40.2062 59.6685 40.6006 59.9692 40.8858L59.8442 41.0107Z"
      fill="#222465"
    />
    <path
      d="M59.8442 47.2608C59.977 47.4209 60.1489 47.5381 60.3442 47.6045C60.7231 47.7725 61.1528 47.7725 61.5317 47.6045C61.727 47.5381 61.8989 47.4209 62.0317 47.2608C62.3364 46.9795 62.5044 46.5811 62.5005 46.1669C62.5435 45.9716 62.5435 45.7685 62.5005 45.5732C62.4341 45.3779 62.3169 45.206 62.1567 45.0732C62.0239 44.913 61.852 44.7959 61.6567 44.7294C61.0786 44.4873 60.4067 44.624 59.9692 45.0732C59.6685 45.3584 59.4966 45.7529 59.4966 46.167C59.4966 46.5811 59.6685 46.9756 59.9692 47.2608L59.8442 47.2608Z"
      fill="#222465"
    />
    <path
      d="M59.8442 53.5107C59.977 53.6709 60.1489 53.7881 60.3442 53.8545C60.5395 53.9014 60.7427 53.9014 60.938 53.8545C61.7935 53.8389 62.4849 53.1475 62.5005 52.292C62.5435 52.0967 62.5435 51.8936 62.5005 51.6982C62.4341 51.5029 62.3169 51.3311 62.1567 51.1982C61.5474 50.6045 60.5786 50.6045 59.9692 51.1982C59.6685 51.4834 59.4966 51.8779 59.4966 52.292C59.4966 52.7061 59.6685 53.1006 59.9692 53.3858L59.8442 53.5107Z"
      fill="#222465"
    />
    <path
      d="M50.4692 60.6978C50.1645 60.979 49.9966 61.3774 50.0005 61.7916C49.9536 61.9869 49.9536 62.19 50.0005 62.3853C50.0669 62.5806 50.1841 62.7525 50.3442 62.8853C50.477 63.0455 50.6489 63.1626 50.8442 63.2291C51.0395 63.2759 51.2427 63.2759 51.438 63.2291C51.6333 63.272 51.8364 63.272 52.0317 63.2291C52.4067 63.0611 52.7075 62.7603 52.8755 62.3853C52.9224 62.19 52.9224 61.9869 52.8755 61.7916C52.8794 61.3775 52.7114 60.9791 52.4067 60.6978C51.8403 60.2485 51.0356 60.2485 50.4692 60.6978Z"
      fill="#222465"
    />
    <path
      d="M44.7192 60.3548C44.5239 60.4212 44.352 60.5384 44.2192 60.6986C43.9145 60.9798 43.7466 61.3782 43.7505 61.7923C43.7036 61.9877 43.7036 62.1908 43.7505 62.3861C43.8169 62.5814 43.9341 62.7533 44.0942 62.8861C44.227 63.0463 44.3989 63.1634 44.5942 63.2298C44.7895 63.2767 44.9927 63.2767 45.188 63.2298C45.602 63.2338 46.0005 63.0658 46.2818 62.7611C46.442 62.6283 46.5591 62.4564 46.6255 62.2611C46.6724 62.0658 46.6724 61.8627 46.6255 61.6673C46.6294 61.2533 46.4615 60.8549 46.1568 60.5736C45.7388 60.2728 45.2076 60.1907 44.7193 60.3548H44.7192Z"
      fill="#222465"
    />
    <path
      d="M39.6567 60.3547C39.0786 60.1125 38.4067 60.2492 37.9692 60.6984C37.6645 60.9797 37.4966 61.3781 37.5005 61.7922C37.4536 61.9875 37.4536 62.1907 37.5005 62.386C37.5669 62.5813 37.6841 62.7532 37.8442 62.886C37.977 63.0461 38.1489 63.1633 38.3442 63.2297C38.5395 63.2766 38.7427 63.2766 38.938 63.2297C39.352 63.2336 39.7505 63.0657 40.0318 62.761C40.1919 62.6282 40.3091 62.4563 40.3755 62.261C40.4224 62.0657 40.4224 61.8625 40.3755 61.6672C40.3794 61.2532 40.2115 60.8547 39.9068 60.5734C39.8365 60.4875 39.7505 60.4133 39.6568 60.3547H39.6567Z"
      fill="#222465"
    />
    <path
      d="M32.2192 60.3548C32.0239 60.4212 31.852 60.5384 31.7192 60.6985C31.4145 60.9798 31.2466 61.3782 31.2505 61.7923C31.2036 61.9876 31.2036 62.1908 31.2505 62.3861C31.3169 62.5814 31.4341 62.7533 31.5942 62.8861C31.8755 63.1908 32.2739 63.3587 32.688 63.3548C32.8834 63.4017 33.0865 63.4017 33.2818 63.3548C33.6568 63.1868 33.9576 62.8861 34.1255 62.5111C34.1724 62.3158 34.1724 62.1126 34.1255 61.9173C34.1294 61.5033 33.9615 61.1048 33.6568 60.8235C33.2935 60.4251 32.7466 60.2454 32.2193 60.3548H32.2192Z"
      fill="#222465"
    />
    <path
      d="M25.9692 60.3548C25.7739 60.4212 25.602 60.5384 25.4692 60.6986C25.1645 60.9798 24.9966 61.3782 25.0005 61.7923C24.9536 61.9877 24.9536 62.1908 25.0005 62.3861C25.0669 62.5814 25.1841 62.7533 25.3442 62.8861C25.477 63.0463 25.6489 63.1634 25.8442 63.2298C26.0395 63.2767 26.2427 63.2767 26.438 63.2298C26.852 63.2338 27.2505 63.0658 27.5318 62.7611C27.692 62.6283 27.8091 62.4564 27.8755 62.2611C27.9224 62.0658 27.9224 61.8627 27.8755 61.6673C27.8794 61.2533 27.7115 60.8549 27.4068 60.5736C26.9888 60.2728 26.4576 60.1907 25.9693 60.3548H25.9692Z"
      fill="#222465"
    />
    <path
      d="M20.9067 60.3547C20.3286 60.1125 19.6567 60.2492 19.2192 60.6984C18.9145 60.9797 18.7466 61.3781 18.7505 61.7922C18.7036 61.9875 18.7036 62.1907 18.7505 62.386C18.8169 62.5813 18.9341 62.7532 19.0942 62.886C19.227 63.0461 19.3989 63.1633 19.5942 63.2297C19.7895 63.2766 19.9927 63.2766 20.188 63.2297C20.602 63.2336 21.0005 63.0657 21.2818 62.761C21.4419 62.6282 21.5591 62.4563 21.6255 62.261C21.6724 62.0657 21.6724 61.8625 21.6255 61.6672C21.6294 61.2532 21.4615 60.8547 21.1568 60.5734C21.0865 60.4875 21.0005 60.4133 20.9068 60.3547H20.9067Z"
      fill="#222465"
    />
    <path
      d="M50.9692 6.97876C51.3481 7.14673 51.7778 7.14673 52.1567 6.97876C52.5317 6.81079 52.8325 6.51001 53.0005 6.13501C53.1685 5.7561 53.1685 5.32642 53.0005 4.94751C52.9341 4.7522 52.8169 4.58032 52.6567 4.44751C52.5239 4.28735 52.352 4.17017 52.1567 4.10376C51.7778 3.94751 51.3481 3.94751 50.9692 4.10376C50.5903 4.26782 50.2895 4.5686 50.1255 4.94751C50.0786 5.14282 50.0786 5.34595 50.1255 5.54126C50.1216 5.95532 50.2895 6.35376 50.5942 6.63506C50.6919 6.77568 50.8208 6.89287 50.9692 6.97881V6.97876Z"
      fill="#222465"
    />
    <path
      d="M44.2183 6.63555C44.3511 6.79571 44.523 6.91289 44.7183 6.9793C45.0972 7.14727 45.5269 7.14727 45.9058 6.9793C46.1011 6.91289 46.273 6.79571 46.4058 6.63555C46.7105 6.3543 46.8784 5.95586 46.8745 5.54175C46.9214 5.34644 46.9214 5.14331 46.8745 4.948C46.8081 4.75269 46.6909 4.58081 46.5308 4.448C46.398 4.28784 46.2261 4.17066 46.0308 4.10425C45.6519 3.948 45.2222 3.948 44.8433 4.10425C44.4644 4.26831 44.1636 4.56909 43.9995 4.948C43.8315 5.32691 43.8315 5.75659 43.9995 6.1355C44.0308 6.31909 44.105 6.49097 44.2183 6.6355V6.63555Z"
      fill="#222465"
    />
    <path
      d="M39.0624 7.10454C39.2578 7.15142 39.4609 7.15142 39.6562 7.10454C39.8515 7.03814 40.0234 6.92095 40.1562 6.76079C40.496 6.44829 40.6679 5.99907 40.6249 5.54199C40.6718 5.34668 40.6718 5.14355 40.6249 4.94824C40.5585 4.75293 40.4414 4.58105 40.2812 4.44824C39.6718 3.85449 38.7031 3.85449 38.0937 4.44824C37.6679 4.87793 37.5273 5.51464 37.7343 6.08494C37.9414 6.65135 38.4609 7.04978 39.0624 7.10444L39.0624 7.10454Z"
      fill="#222465"
    />
    <path
      d="M32.2192 6.97857C32.5981 7.14654 33.0278 7.14654 33.4067 6.97857C33.602 6.91216 33.7739 6.79498 33.9067 6.63482C34.2114 6.35357 34.3794 5.95513 34.3755 5.54102C34.4224 5.34571 34.4224 5.14258 34.3755 4.94727C34.3091 4.75196 34.1919 4.58008 34.0317 4.44727C33.8989 4.28711 33.727 4.16993 33.5317 4.10352C33.1528 3.94727 32.7231 3.94727 32.3442 4.10352C31.9653 4.26758 31.6645 4.56836 31.5005 4.94727C31.4536 5.14258 31.4536 5.34571 31.5005 5.54102C31.4888 5.95508 31.6606 6.35743 31.9692 6.63482C32.0317 6.76373 32.1138 6.88091 32.2192 6.97857Z"
      fill="#222465"
    />
    <path
      d="M25.9692 6.97876C26.3481 7.14673 26.7778 7.14673 27.1567 6.97876C27.5317 6.81079 27.8325 6.51001 28.0005 6.13501C28.1685 5.7561 28.1685 5.32642 28.0005 4.94751C27.9341 4.7522 27.8169 4.58032 27.6567 4.44751C27.5239 4.28735 27.352 4.17017 27.1567 4.10376C26.7778 3.94751 26.3481 3.94751 25.9692 4.10376C25.5903 4.26782 25.2895 4.5686 25.1255 4.94751C25.0786 5.14282 25.0786 5.34595 25.1255 5.54126C25.1216 5.95532 25.2895 6.35376 25.5942 6.63506C25.6919 6.77568 25.8208 6.89287 25.9692 6.97881V6.97876Z"
      fill="#222465"
    />
    <path
      d="M19.2182 6.6357C19.4995 6.94039 19.8979 7.10836 20.312 7.10445C20.5073 7.15133 20.7105 7.15133 20.9058 7.10445C21.2808 6.93648 21.5815 6.6357 21.7495 6.2607C21.9175 5.88179 21.9175 5.45211 21.7495 5.0732C21.6831 4.87789 21.5659 4.70601 21.4058 4.5732C21.273 4.41304 21.1011 4.29586 20.9058 4.22945C20.3276 3.98726 19.6558 4.12398 19.2183 4.5732C19.0581 4.70601 18.9409 4.87789 18.8745 5.0732C18.7065 5.45211 18.7065 5.88179 18.8745 6.2607C18.9565 6.40914 19.0776 6.53804 19.2183 6.6357H19.2182Z"
      fill="#222465"
    />
    <path
      d="M20.7639 43.9981L43.1434 21.6186L39.2109 17.6861C38.5582 17.0334 39.0209 15.9167 39.9448 15.9167H49.2751C49.8479 15.9167 50.312 16.3808 50.312 16.9536V26.2839C50.312 27.2079 49.1953 27.6705 48.5426 27.0178L44.6101 23.0853L22.2306 45.4648L26.1631 49.3973C26.8158 50.05 26.3531 51.1667 25.4292 51.1667H16.0989C15.526 51.1667 15.062 50.7027 15.062 50.1299V40.7995C15.062 39.8756 16.1787 39.413 16.8314 40.0656L20.7639 43.9981Z"
      fill="#222465"
    />
  </svg>
);

export default CustomProfileIcon;
