import styled, { css } from 'styled-components';

export const DisclaimerWrapper = styled.div<{
  $isMobile?: boolean;
}>`
  ${({ $isMobile }) => disclaimerStyles($isMobile)};
`;

const disclaimerStyles = ($isMobile?: boolean) => css`
  padding-left: ${$isMobile ? '24px' : '0'};
  padding-right: ${$isMobile ? '24px' : '0'};
`;