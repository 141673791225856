import { styled } from 'styled-components';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const Wrapper = styled.div`
  ${grid.threeColumn};
  padding-bottom: ${spacing.xl};

  ${breakpoint.mobile} {
    padding-bottom: ${spacing.s};
  }
`;

export const CardsSection = styled.div`
  border-top: ${({ theme }) => theme.border.default};
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};
  padding-top: ${spacing.xl};

  ${breakpoint.mobile} {
    gap: ${spacing['2xs']};
    padding-top: ${spacing.s};
  }
`;
