import { styled } from 'styled-components';
import ScreenTitle from 'components/screen-title';
import { spacing } from 'styles/helpers';
import { TOP_ACTIONS_HEIGHT_MOBILE } from 'styles/theme';

export const MobileWrapper = styled.div`
  margin-top: ${TOP_ACTIONS_HEIGHT_MOBILE};
  padding: ${spacing.s} ${spacing.m};
`;

export const Title = styled(ScreenTitle)`
  margin: ${spacing.s} 0 ${spacing.xl};
`;
