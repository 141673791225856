import { StyleSheet } from '@react-pdf/renderer';
import { spacing } from 'styles/helpers';

const FOOTER_HEIGHT_PDF = 43;
const FOOTER_LOGO_WIDTH_PDF = 97;
const FOOTER_BACKGROUND_COLOR_PDF = '#FBFBFB';
const FOOTER_TEXT_COLOR_PDF = '#5C6970';

const styles = StyleSheet.create({
  footer: {
    bottom: 0,
    color: FOOTER_TEXT_COLOR_PDF,
    fontFamily: 'Avenir Next Cyr W00 Regular',
    fontSize: 6,
    position: 'absolute',
    width: '100%',
  },
  footerTop: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: spacing['3xs'],
    padding: `0 ${spacing.m}`,
  },
  footerBottom: {
    alignItems: 'center',
    backgroundColor: FOOTER_BACKGROUND_COLOR_PDF,
    flexDirection: 'row',
    height: FOOTER_HEIGHT_PDF,
    justifyContent: 'space-between',
    padding: `0 ${spacing.m}`,
  },
  footerText: {
    flex: 1,
    marginRight: '35px',
  },
  footerLogo: {
    width: FOOTER_LOGO_WIDTH_PDF,
  },
});

export default styles;
