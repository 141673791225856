import { InputDetailsData, ValueUnitPair } from 'shared/types';

export const sortInputDetails = (
  sortOrder: Array<string>,
  data?: InputDetailsData
) => {
  if (!data) {
    return;
  }

  const sortedArray: (string | number | ValueUnitPair | undefined)[][] = [];

  sortOrder.forEach((key) => {
    if (Object.hasOwn(data, key)) {
      sortedArray.push([key, data[key as keyof InputDetailsData]]);
    }
  });

  const sortedInputDetailsData = Object.fromEntries(sortedArray);

  return sortedInputDetailsData;
};
