import * as Yup from 'yup';

export const requiredFieldsValidationSchema = Yup.object().shape({
  humidity: Yup.string().required(),
  temperature: Yup.string().required(),
});

export const validationSchema = Yup.object().shape({
  buildingFunction: Yup.string(),
  areaToProtect: Yup.number()
    .typeError('environment.errors.areaToProtect')
    .moreThan(0, 'environment.errors.areaToProtect'),
  wastage: Yup.number()
    .typeError('environment.errors.wastagePercentage')
    .min(0, 'environment.errors.wastagePercentage')
    .max(50, 'environment.errors.wastagePercentage')
    .default(10),
});

export const combinedSchema =
  requiredFieldsValidationSchema.concat(validationSchema);
