import { useTranslation } from 'react-i18next';
import { View, Text } from '@react-pdf/renderer';
import Markdown from 'markdown-to-jsx';
import { markdownComponents } from './constants';
import styles from './styles';

interface DescriptionPdfProps {
  description: string;
  benefits: string;
}

const DescriptionPdf = ({ description, benefits }: DescriptionPdfProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.descriptionWrapper}>
      <Markdown
        options={{
          overrides: markdownComponents,
          forceInline: true,
          wrapper: Text,
          forceWrapper: true,
        }}
      >
        {description ?? ''}
      </Markdown>
      <Text style={styles.detailsTitle}>{t('productDetails.details')}</Text>
      <Markdown options={{ overrides: markdownComponents }}>
        {benefits ?? ''}
      </Markdown>
    </View>
  );
};

export default DescriptionPdf;
