import ProductDescription from 'features/product-details/components/product-description';
import { TFunction } from 'i18next';
import {
  CalculationResultData,
  InputDetailsData,
  ProductDetailsData,
} from 'shared/types';
import { ButtonVariant } from '../../components/button/types';
import ContactSupportCtaButton from '../../components/contact-support-cta-button';
import RequestSpecificationsCtaButton from '../../components/request-specifications-cta-button';
import CalculationDetails from './components/calculation-details';
import DownloadPdf from './components/download-pdf';
import InputDetails from './components/input-details';
import InstallationDetails from './components/installation-details';
import { CalculationDetailsWrapper } from './styles';
import { ProductDetailsSection } from './types';

export const getProductDetailsTabs = (
  t: TFunction,
  calculationResult?: CalculationResultData
) => [
  ...(calculationResult
    ? [
        {
          value: ProductDetailsSection.calculationResult,
          label: t('productDetails.calculationResult'),
        },
      ]
    : []),
  {
    value: ProductDetailsSection.inputDetails,
    label: t('productDetails.inputDetails'),
  },
  {
    value: ProductDetailsSection.description,
    label: t('productDetails.description'),
  },
  {
    value: ProductDetailsSection.installationDetails,
    label: t('productDetails.installationDetails'),
  },
];

export const getTabContent = (
  section: ProductDetailsSection,
  onContactSupportModalOpen: () => void,
  onRequestModalOpen: () => void,
  productDetails?: ProductDetailsData,
  calculationResult?: CalculationResultData,
  inputDetails?: InputDetailsData
) => {
  switch (section) {
    case ProductDetailsSection.calculationResult:
      return (
        <CalculationDetailsWrapper>
          <CalculationDetails calculationResult={calculationResult} />
          <DownloadPdf
            calculationResult={calculationResult}
            productDetails={productDetails}
            inputDetails={inputDetails}
          />

          <ContactSupportCtaButton
            onClick={() => {
              onContactSupportModalOpen();
            }}
            variant={ButtonVariant.outlined}
          />
          <RequestSpecificationsCtaButton
            onClick={() => {
              onRequestModalOpen();
            }}
            variant={ButtonVariant.outlined}
          />
        </CalculationDetailsWrapper>
      );
    case ProductDetailsSection.description:
      return <ProductDescription productDetails={productDetails} />;
    case ProductDetailsSection.installationDetails:
      return (
        <InstallationDetails
          productDetails={productDetails}
          testReportNo={calculationResult?.testReportNo}
        />
      );
    case ProductDetailsSection.inputDetails:
      return <InputDetails inputDetails={inputDetails} />;
  }
};
