import { spacing } from 'styles/helpers';
import { theme, typography } from 'styles/theme';
import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Avenir Next Regular',
  fonts: [
    { src: require('fonts/AvenirNextCyrRegular.woff') },
    { src: require('fonts/AvenirNextCyrRegular.woff2') },
  ],
});

const DISCLAIMER_TEXT_FONT_PDF = 'Avenir Next Regular';

const styles = StyleSheet.create({
  disclaimerWrapper : {
    padding: `${spacing.l} ${spacing.m} 0 ${spacing.m} `
  },
  disclaimerParagraph: {
    color:  theme.colors.disclaimer,
    marginBottom: spacing['2xs'],
    fontFamily: DISCLAIMER_TEXT_FONT_PDF,
    fontSize: 10,
    fontWeight: typography.body5.fontWeight,
  }
});

export default styles;