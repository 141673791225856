const SquareTubeIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.9685 55.129C43.7692 55.0431 43.5505 55.0001 43.3317 55.0001H11.6677C11.2224 55.0001 10.8005 55.1759 10.488 55.4884C10.1755 55.8009 9.99976 56.2228 9.99976 56.6681V88.3321C10.0037 89.254 10.7458 89.9962 11.6678 90.0001H43.3318C43.7771 90.0001 44.1989 89.8243 44.5115 89.5118C44.824 89.1993 44.9997 88.7774 44.9997 88.3321V56.6681C44.9958 55.9923 44.5896 55.3869 43.9685 55.129L43.9685 55.129ZM39.3083 58.3321L13.3313 84.3091V58.3321H39.3083ZM15.6913 86.6681L41.6683 60.6911V86.6681H15.6913Z"
      fill="#222465"
    />
    <path
      d="M98.9687 0.12891C98.7695 0.042973 98.5507 0 98.332 0H46.668C46.2227 0.0039062 45.8008 0.17969 45.4883 0.48828L0.48828 45.4883C0.17969 45.8008 0.00391 46.2227 0 46.668V98.332C0 98.7773 0.17578 99.1992 0.48828 99.5117C0.80078 99.8242 1.22266 100 1.66798 100H53.332C53.7773 99.9961 54.1992 99.8203 54.5117 99.5117L99.5117 54.5117C99.8203 54.1992 99.9961 53.7773 100 53.332V1.66798C99.9961 0.9922 99.5898 0.38678 98.9688 0.12888L98.9687 0.12891ZM96.6679 52.6449L54.9999 94.3089V47.3559L96.6679 5.69191V52.6449ZM47.3559 3.33291H94.3089L52.6449 45.0009H5.6919L47.3559 3.33291ZM3.3329 48.3329H51.6689V96.6689H3.3329V48.3329Z"
      fill="#222465"
    />
  </svg>
);

export default SquareTubeIcon;
