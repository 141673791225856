import { useTranslation } from 'react-i18next';
import ErrorIcon from 'assets/error-icon';
import { Body3 } from 'components/typography';
import { ErrorWrapper } from './styles';

interface ErrorMessageProps {
  error: string;
  showIcon?: boolean;
}

const ErrorMessage = ({ error, showIcon = true }: ErrorMessageProps) => {
  const { t } = useTranslation();

  return (
    <ErrorWrapper>
      {showIcon && <ErrorIcon />}
      <Body3>{t(error)}</Body3>
    </ErrorWrapper>
  );
};

export default ErrorMessage;
