import styled from 'styled-components';
import InfoIcon from 'assets/info-icon';
import QuestionMarkIcon from 'assets/question-mark-icon';

export const TitleWrapper = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const CtaButtonWrapper = styled.div`
  height: 40px;
  margin-left: auto;
`;