import { styled } from 'styled-components';
import InfoIcon from 'assets/info-icon';
import { radii, spacing } from 'styles/helpers';
import { iconSizing } from 'styles/mixins';
import { theme } from 'styles/theme';

const TABLE_CELL_HEIGHT = '44px';

export const muiTableStyles = {
  border: theme.border.default,
  borderCollapse: 'unset',
  borderRadius: radii.s,
};

export const muiTableHeadStyles = {
  backgroundColor: theme.colors.backgroundLight,

  '& .MuiTableCell-head': {
    color: theme.colors.primary,
  },
};

export const muiTableRowStyles = {
  '&:last-child td, &:last-child th': { border: 0 },
};

export const muiTableCellStyles = {
  borderBottom: theme.border.default,
  height: TABLE_CELL_HEIGHT,
  padding: `${spacing['3xs']} ${spacing.l}`,
  width: '50%',
};

export const TableTitle = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing['2xs']};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  ${iconSizing.xs}
`;
