import { API_BASE_URL } from 'shared/constants';
import useMutation from 'shared/hooks/useMutation';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { ConcreteInputDetailsData } from 'shared/types';
import { ConcreteInputDetailsRequest } from '../types';

const useGetConcreteInputDetails = () => {
  const [structuralElements] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteStructuralElements
  );
  const [fireRating] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteFireRating
  );

  const orientationId = structuralElements?.orientation;
  const currentAxisDistance = structuralElements?.currentAxis;
  const requiredAxisDistance = structuralElements?.requiredAxis;
  const width = structuralElements?.width;
  const depth = structuralElements?.depth;
  const wastage = structuralElements?.wastage;

  const request: ConcreteInputDetailsRequest = {
    orientationId,
    fireRatingId: fireRating,
    currentAxisDistance: Number(currentAxisDistance) || 0,
    requiredAxisDistance: Number(requiredAxisDistance),
    width: Number(width),
    depth: Number(depth),
    wastage: Number(wastage) || 0
  };

  const { mutate, isLoading, data } = useMutation<
    ConcreteInputDetailsRequest,
    ConcreteInputDetailsData
  >(`${API_BASE_URL}v1/products/concrete/calculation-input-details`, request);

  return {
    inputDetails: data,
    getConcreteInputDetails: mutate,
    isLoading,
  };
};

export default useGetConcreteInputDetails;
