const BeamIcon = () => (
  <svg
    width="46"
    height="47"
    viewBox="0 0 46 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 20.4823C3.91145 18.1247 17.5467 3.35768 20.458 1H45.5067V24.1513C42.56 26.5089 29.4446 43.589 26.498 45.9803H1V20.4823ZM25.5623 20.4823C27.7804 18.6799 40.7223 4.11993 42.8713 2.38667H20.9426L3.2516 20.4823H25.5623ZM44.12 3.14899L26.498 21.487V44.178L44.12 23.4933V3.14899ZM25.1113 21.869H2.38667V44.5936H25.1113V21.869Z"
      fill="#222465"
      stroke="#222465"
      strokeWidth="0.25"
    />
  </svg>
);

export default BeamIcon;
