import { useTranslation } from 'react-i18next';
import { EnvironmentHumidity } from 'features/environment/types';
import EnvironmentCardsSection from '../environment-cards-section';
import { HUMIDITY_ICONS } from './constants';

const HumidityCards = () => {
  const { t } = useTranslation();

  const humidityValues = Object.values(EnvironmentHumidity);

  return (
    <EnvironmentCardsSection
      label={t('environment.humidity')}
      sectionValues={humidityValues}
      name="humidity"
      icons={HUMIDITY_ICONS}
    />
  );
};

export default HumidityCards;
