import { SvgProps } from './types';

const ErrorIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_350_29200"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="19"
    >
      <rect y="0.582031" width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_350_29200)">
      <path
        d="M9 13.332C9.2125 13.332 9.39075 13.26 9.53475 13.116C9.67825 12.9725 9.75 12.7945 9.75 12.582C9.75 12.3695 9.67825 12.1913 9.53475 12.0473C9.39075 11.9038 9.2125 11.832 9 11.832C8.7875 11.832 8.6095 11.9038 8.466 12.0473C8.322 12.1913 8.25 12.3695 8.25 12.582C8.25 12.7945 8.322 12.9725 8.466 13.116C8.6095 13.26 8.7875 13.332 9 13.332ZM8.25 10.332H9.75V5.83203H8.25V10.332ZM9 17.082C7.9625 17.082 6.9875 16.885 6.075 16.491C5.1625 16.0975 4.36875 15.5633 3.69375 14.8883C3.01875 14.2133 2.4845 13.4195 2.091 12.507C1.697 11.5945 1.5 10.6195 1.5 9.58203C1.5 8.54453 1.697 7.56953 2.091 6.65703C2.4845 5.74453 3.01875 4.95078 3.69375 4.27578C4.36875 3.60078 5.1625 3.06628 6.075 2.67228C6.9875 2.27878 7.9625 2.08203 9 2.08203C10.0375 2.08203 11.0125 2.27878 11.925 2.67228C12.8375 3.06628 13.6313 3.60078 14.3063 4.27578C14.9813 4.95078 15.5155 5.74453 15.909 6.65703C16.303 7.56953 16.5 8.54453 16.5 9.58203C16.5 10.6195 16.303 11.5945 15.909 12.507C15.5155 13.4195 14.9813 14.2133 14.3063 14.8883C13.6313 15.5633 12.8375 16.0975 11.925 16.491C11.0125 16.885 10.0375 17.082 9 17.082ZM9 15.582C10.675 15.582 12.0937 15.0008 13.2562 13.8383C14.4187 12.6758 15 11.257 15 9.58203C15 7.90703 14.4187 6.48828 13.2562 5.32578C12.0937 4.16328 10.675 3.58203 9 3.58203C7.325 3.58203 5.90625 4.16328 4.74375 5.32578C3.58125 6.48828 3 7.90703 3 9.58203C3 11.257 3.58125 12.6758 4.74375 13.8383C5.90625 15.0008 7.325 15.582 9 15.582Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ErrorIcon;
