import { styled } from 'styled-components';
import { breakpoint, sizing, spacing } from 'styles/helpers';

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  ${breakpoint.mobile} {
    gap: ${spacing.m};
    grid-template-columns: 1fr;
    padding: ${spacing['4xl']} ${spacing.m};
  }
`;

export const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${sizing(11)};

  ${breakpoint.mobile} {
    font-size: ${sizing(9)};
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.m};
`;

export const StyledImg = styled.img`
  width: 100%;
`;
