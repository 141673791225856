import { ChangeEvent, useState } from 'react';
import { Input as InputMui, InputAdornment } from '@mui/material';
import { useFormikContext } from 'formik';
import parse from 'html-react-parser';
import InputHelperText from 'components/input-helper-text';
import InputLabel from 'components/input-label';
import NumberInputButtons from './number-input-buttons';
import { InfoWrapper, inputStyles } from './styles';

export interface InputProps {
  optional?: boolean;
  error?: boolean;
  label?: string;
  helperText?: string;
  small?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  numberInput?: boolean;
  value?: string | number;
  name: string;
  decimal?: boolean;
  showLabel?: boolean;
  maxLength?: number;
  tooltipText?: string;
  id?: string;
  explanation?: string;
  displayArrowButtons?: boolean;
  maxValue?: number;
}

const Input = ({
  optional,
  error = false,
  label,
  helperText,
  small = false,
  placeholder,
  onChange,
  numberInput = false,
  value,
  decimal,
  showLabel = true,
  maxLength,
  tooltipText,
  id,
  name,
  explanation,
  displayArrowButtons,
  maxValue,
}: InputProps) => {
  const [inputValue, setInputValue] = useState(value);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numRegex = decimal ? /^[0-9]{0,5}(\.[0-9]{0,2})?$/ : /^[0-9]+$/;

    if (
      !numberInput ||
      e.target.value === '' ||
      numRegex.test(e.target.value)
    ) {
      if (maxValue && Number(e.target.value) >= maxValue) {
        setInputValue( Number(maxValue) );
        onChange?.(maxValue.toString());
      } else {
        setInputValue(e.target.value);
        onChange?.(e.target.value);
      }
    }
  };

  const handleUpBtnClick = () => {
    if (maxValue && Number(value) < maxValue) {
      setFieldValue(name, Number(value) + 1);
    }
  };

  const handleDownBtnClick = () => {
    if (Number(value) > 0) {
      setFieldValue(name, Number(value) - 1);
    }
  };

  return (
    <div>
      {label && showLabel && (
        <InputLabel
          id={`${id}-label`}
          label={label}
          optional={optional}
          tooltipText={tooltipText}
        />
      )}
      <InputMui
        aria-label={label}
        id={id}
        fullWidth
        value={value ?? inputValue}
        onChange={handleChange}
        error={error}
        placeholder={placeholder ? `${parse(placeholder)}` : ''}
        type={'text'}
        sx={inputStyles(small)}
        inputProps={{ maxLength }}
        endAdornment={
          numberInput &&
          displayArrowButtons && (
            <InputAdornment position="end">
              <NumberInputButtons
                onUpBtnClick={handleUpBtnClick}
                onDownBtnClick={handleDownBtnClick}
                disableTop={!!maxValue && Number(value) >= maxValue}
                disableBottom={Number(value) <= 0}
              />
            </InputAdornment>
          )
        }
      />
      {helperText && <InputHelperText error={error} helperText={helperText} />}

      <InfoWrapper>{parse(explanation ?? '')}</InfoWrapper>
    </div>
  );
};

export default Input;
