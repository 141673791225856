import { StyleSheet } from '@react-pdf/renderer';
import { theme } from 'styles/theme';

const IMAGE_HEIGHT_PDF = 160;

const styles = StyleSheet.create({
  imageContainer: {
    border: theme.border.default,
    height: IMAGE_HEIGHT_PDF,
  },
  image: {
    objectFit: 'scale-down',
  },
});

export default styles;
