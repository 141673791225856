import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Disclaimer from 'components/disclaimer-section';
import ProductDetails from 'features/product-details';
import { CONCRETE_ROUTES } from 'shared/constants';
import useGetSessionInputDetails from 'shared/hooks/useGetSessionInputDetails';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { ConcreteInputDetailsData } from 'shared/types';
import { sortInputDetails } from 'shared/utils/sortInputDetails';
import useGetConcreteCalculations from '../api/useGetConcreteCalculations';
import useGetConcreteInputDetails from '../api/useGetConcreteInputDetails';
import { concreteInputDetailsSortOrder } from './constants';
import { DisclaimerWrapper } from '../../steel/product-details/styles';
import useIsMobile from '../../../shared/hooks/useIsMobile';

const ConcreteProductDetails = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const isMobile = useIsMobile();
  const isProductKnown = useIsProductKnown();
  const {
    calculationResult,
    getConcreteCalculations,
    isLoading: isCalculationResultLoading,
  } = useGetConcreteCalculations(productId);

  const {
    inputDetails,
    getConcreteInputDetails,
    isLoading: isInputDetailsLoading,
  } = useGetConcreteInputDetails();

  const backRoute = isProductKnown
    ? CONCRETE_ROUTES.fireRating
    : CONCRETE_ROUTES.results;

  useEffect(() => {
    getConcreteCalculations();
    getConcreteInputDetails();
  }, []);

  const sessionInputDetails = useGetSessionInputDetails(
    SESSION_STORAGE_KEYS.concreteEnvironment
  );

  const concreteInputDetails = { ...inputDetails, ...sessionInputDetails };

  const sortedInputDetails = sortInputDetails(
    concreteInputDetailsSortOrder,
    concreteInputDetails as ConcreteInputDetailsData
  );

  const isProductDataLoading =
    isCalculationResultLoading || isInputDetailsLoading;

  return (
    <>
      <ProductDetails
        backRoute={backRoute}
        inputDetails={sortedInputDetails}
        calculationResult={calculationResult}
        isCalculationResultLoading={isProductDataLoading}
      />
      {!isCalculationResultLoading && <DisclaimerWrapper $isMobile={isMobile}>
        <Disclaimer text={t('productDetails.legal')} />
      </DisclaimerWrapper>}
    </>
  );
};
export default ConcreteProductDetails;
