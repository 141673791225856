import styled from 'styled-components';
import ScreenTitle from 'components/screen-title';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const StructureSelectWrapper = styled.div`
  ${breakpoint.mobile} {
    padding: ${spacing.s} ${spacing.m};
  }
`;

export const CardsWrapper = styled.div`
  ${grid.threeColumn}
  margin: ${spacing.s} 0;
`;

export const StyledScreenTitle = styled(ScreenTitle)`
  margin-top: ${spacing.xl};

  ${breakpoint.mobile} {
    margin-top: ${spacing.s};
  }
`;
