import { View, Image } from '@react-pdf/renderer';
import { ImageRendition } from 'shared/types';
import getProductImage from 'shared/utils/getProductImage';
import styles from './styles';

interface ImagePdfProps {
  imageId: string;
}

const ImagePdf = ({ imageId }: ImagePdfProps) => (
  <View style={styles.imageContainer}>
    <Image
      src={getProductImage(imageId, ImageRendition.Original)}
      style={styles.image}
    />
  </View>
);

export default ImagePdf;
