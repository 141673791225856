import { useTranslation } from 'react-i18next';
import { useFormikContext, FormikValues } from 'formik';
import FormikSelectCard from 'components/select-card/formik-select-card';
import { Heading7 } from 'components/typography';
import { SvgIconSet } from 'shared/types';
import { CardsSection, Wrapper } from './styles';

type EnvironmentCardsSectionProps = {
  sectionValues: string[];
  name: string;
  icons: SvgIconSet;
  label: string;
  isLastElement?: boolean;
};

const EnvironmentCardsSection = ({
  sectionValues,
  name,
  icons,
  label,
}: EnvironmentCardsSectionProps) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormikValues>();

  return (
    <CardsSection>
      <Heading7>{label}</Heading7>
      <Wrapper>
        {sectionValues.map((item: string) => (
          <FormikSelectCard
            key={item}
            title={t(`environment.${item}`) ?? ''}
            selected={item === values[name]}
            name={name}
            value={item}
            iconSvg={icons[item]}
          />
        ))}
      </Wrapper>
    </CardsSection>
  );
};

export default EnvironmentCardsSection;
