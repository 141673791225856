import Markdown from 'markdown-to-jsx';
import { styled } from 'styled-components';
import { Heading7 } from 'components/typography';
import { breakpoint, spacing } from 'styles/helpers';

export const Description = styled(Markdown)`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-top: ${spacing.xl};

  ${breakpoint.mobile} {
    margin-top: 0;
  }

  ul {
    list-style-position: inside;
  }

  strong {
    font-family: ${({ theme }) => theme.fontFamily.medium};
  }
`;

export const DescriptionParagraph = styled.p`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.textSecondary};
  white-space: pre-wrap;

  &:not(:first-child) {
    margin-top: ${spacing.m};
  }
`;

export const DetailsWrapper = styled.div`
  margin: ${spacing.l} 0;
`;

export const DetailsTitle = styled(Heading7)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const DetailsList = styled(Markdown)`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.textSecondary};
  list-style-position: inside;
  margin-top: ${spacing['2xs']};
  white-space: pre-wrap;

  li {
    padding-left: ${spacing['2xs']};
  }

  table {
    border: ${({ theme }) => theme.border.default};
    border-collapse: collapse;
    margin: ${spacing['2xs']} 0;
  }

  th,
  td {
    border: ${({ theme }) => theme.border.default};
    padding: ${spacing['3xs']};
    text-align: center;
  }
`;
