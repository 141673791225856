import { SvgProps } from './types';

const RestartIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3901_7845)">
      <mask
        id="mask0_3901_7845"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_3901_7845)">
        <path
          d="M11 20.95C8.98333 20.7 7.31267 19.8207 5.988 18.312C4.66267 16.804 4 15.0333 4 13C4 11.9 4.21667 10.8457 4.65 9.837C5.08333 8.829 5.7 7.95 6.5 7.2L7.925 8.625C7.29167 9.19167 6.81267 9.85 6.488 10.6C6.16267 11.35 6 12.15 6 13C6 14.4667 6.46667 15.7623 7.4 16.887C8.33333 18.0123 9.53333 18.7 11 18.95V20.95ZM13 20.95V18.95C14.45 18.6833 15.6457 17.9917 16.587 16.875C17.529 15.7583 18 14.4667 18 13C18 11.3333 17.4167 9.91667 16.25 8.75C15.0833 7.58333 13.6667 7 12 7H11.925L13.025 8.1L11.625 9.5L8.125 6L11.625 2.5L13.025 3.9L11.925 5H12C14.2333 5 16.125 5.775 17.675 7.325C19.225 8.875 20 10.7667 20 13C20 15.0167 19.3377 16.7793 18.013 18.288C16.6877 19.796 15.0167 20.6833 13 20.95Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3901_7845">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RestartIcon;
