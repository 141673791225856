const CProfileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 100">
    <path
      fill="#222465"
      d="M86.9,1.3c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3  c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0H53.7c0,0-0.1,0-0.1,0  c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0L0.7,33.5c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2  c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2v63.3c0,0.9,0.6,1.7,1.5,1.7h31.9  c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0l52.2-33.3c0.1,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2  c0.1-0.2,0.2-0.4,0.3-0.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2V54.7c0,0,0-0.1,0-0.1c0-0.2-0.1-0.4-0.1-0.6c0,0,0-0.1,0-0.1  c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0H63.9  c0,0,0,0-0.1,0c-0.2,0-0.4,0.1-0.6,0.2c0,0,0,0-0.1,0l0.6,2V27.8l22.5-14.3c0.1,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2  c0.1-0.2,0.2-0.4,0.3-0.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2V1.7c0,0,0-0.1,0-0.1C87,1.5,87,1.4,86.9,1.3z M34.8,42.5V36  L84.1,4.5V11L34.8,42.5z M54,3.3H80l-47,30H7L54,3.3z M31.9,96.7h-29v-60h29v7h-8.7c-1,0-0.6,0-1.5,0H10.2c-0.8,0-1.5,0.4-1.5,1.3  l0,43.3c0,0.9,0.6,1.3,1.5,1.3h11.6c0.8,0-0.4,0,1.5,0h8.7V96.7z M62.3,56.3H80l-47,30H15.3L62.3,56.3z M84.1,64L34.8,95.5V89  l49.3-31.5V64z M60.9,53.1L12,84.5c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.2-0.2,0.4-0.3,0.5  c0,0.1-0.1,0.2-0.1,0.3c0,0.1,2.3-2.1,0.6-1.1h-0.6h0.3v-38h8.7c1.9,0,0.6,0,1.5,0h11.6c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1  c0,0,0.1,0,0.1,0l26.8-16.8L60.9,53.1z"
    />
  </svg>
);

export default CProfileIcon;
