import { useState } from 'react';

const useSessionStorage = (key: string) => {
  const [storageValue, setStorageValue] = useState(() => {
    const value = window.sessionStorage.getItem(key);

    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        return parsedValue;
      } catch (err) {
        return value;
      }
    }

    return undefined;
  });

  const setValue = (newValue: string | { [key: string]: string }) => {
    window.sessionStorage.setItem(
      key,
      typeof newValue === 'string' ? newValue : JSON.stringify(newValue)
    );
    setStorageValue(newValue);
  };

  return [storageValue, setValue];
};
export default useSessionStorage;
