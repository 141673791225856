import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from 'components/back-button';
import { ButtonVariant } from 'components/button/types';
import { Heading6 } from 'components/typography';
import useIsMobile from 'shared/hooks/useIsMobile';
import {
  IconBackButton,
  ContentLeft,
  StyledArrowIcon,
  Wrapper,
} from './styles';

interface TopActionsProps {
  title?: string;
  onBackClick: () => void;
  rightAction?: ReactNode;
}

const TopActions = ({ title, onBackClick, rightAction }: TopActionsProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      {isMobile ? (
        <>
          <ContentLeft>
            <IconBackButton aria-label={t('common.back')} onClick={onBackClick}>
              <StyledArrowIcon />
            </IconBackButton>
            <Heading6>{title}</Heading6>
          </ContentLeft>
          {rightAction}
        </>
      ) : (
        <>
          <BackButton variant={ButtonVariant.default} onClick={onBackClick} />
          {rightAction}
        </>
      )}
    </Wrapper>
  );
};

export default TopActions;
