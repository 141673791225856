import { styled } from 'styled-components';
import { breakpoint, spacing } from 'styles/helpers';

export const ErrorWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.error};
  display: flex;
  gap: ${spacing['3xs']};
  margin-top: ${spacing['2xs']};

  ${breakpoint.mobile} {
    margin: 0;
  }
`;
