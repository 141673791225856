import useQuery from 'shared/hooks/useQuery';
import { SteelCrossSectionalShape } from '../types';

const useGetSteelCrossSectionalShapes = () => {
  const { data, error, isLoading, isError } = useQuery<
    SteelCrossSectionalShape[]
  >('steel-cross-sectional-shapes', 'v1/steel-elements/cross-sectional-shapes');

  return {
    steelCrossSectionalShapes: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelCrossSectionalShapes;
