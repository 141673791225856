import { useTranslation } from 'react-i18next';
import { ProductDetailsData } from 'shared/types';
import { markdownComponents } from './constants';
import {
  DetailsWrapper,
  DetailsTitle,
  Description,
  DetailsList,
} from './styles';

interface ProductDescriptionProps {
  productDetails?: ProductDetailsData;
}

const ProductDescription = ({ productDetails }: ProductDescriptionProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Description
        options={{
          overrides: markdownComponents,
        }}
      >
        {productDetails?.description ?? ''}
      </Description>
      <DetailsWrapper>
        <DetailsTitle>{t('productDetails.details')}</DetailsTitle>
        <DetailsList>{productDetails?.benefits ?? ''}</DetailsList>
      </DetailsWrapper>
    </>
  );
};

export default ProductDescription;
