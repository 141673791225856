import { useTranslation } from 'react-i18next';
import Table from 'components/table';
import { ProductDetailsData } from 'shared/types';
import mapInstallationDetails from 'shared/utils/mapInstallationDetails';

interface InstallationDetailsProps {
  productDetails?: ProductDetailsData;
  testReportNo?: string;
}

const InstallationDetails = ({
  productDetails,
  testReportNo,
}: InstallationDetailsProps) => {
  const { t } = useTranslation();
  const installationDetails = mapInstallationDetails(
    t,
    testReportNo,
    productDetails?.installationDetails
  );

  return (
    <Table
      rowsData={installationDetails}
      title={t('productDetails.installationDetails')}
    />
  );
};

export default InstallationDetails;
