import { FireRating } from 'shared/types';
import FireIconLevel1 from 'assets/fire-icons/Flame_level_1.svg';
import FireIconLevel2 from 'assets/fire-icons/Flame_level_2.svg';
import FireIconLevel3 from 'assets/fire-icons/Flame_level_3.svg';
import FireIconLevel4 from 'assets/fire-icons/Flame_level_4.svg';
import FireIconLevel5 from 'assets/fire-icons/Flame_level_5.svg';
import FireIconLevel6 from 'assets/fire-icons/Flame_level_6.svg';

export const getMainOptions = (fireRatings: FireRating[]) =>
  fireRatings.filter((fireRating) => fireRating.isMainOption);

export const getAdditionalOptions = (fireRatings: FireRating[]) =>
  fireRatings.filter((fireRating) => !fireRating.isMainOption);


export const fireIconToDisplay = (minutes: number) => {
  switch (minutes) {
    case 15:
    case 30:
      return FireIconLevel1;
    case 45:
    case 60:
      return FireIconLevel2;
    case 75:
    case 90:
      return FireIconLevel3;
    case 120:
    case 150:
      return FireIconLevel4;
    case 180:
    case 210:
      return FireIconLevel5;
    case 240:
      return FireIconLevel6;
    default:
      return FireIconLevel1;
  }
};