import { Font, StyleSheet } from '@react-pdf/renderer';
import { sizing, spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

Font.register({
  family: 'Avenir Next Cyr W00 Regular',
  fonts: [
    { src: require('fonts/AvenirNextCyrRegular.woff') },
    { src: require('fonts/AvenirNextCyrRegular.woff2') },
  ],
});

Font.register({
  family: 'Avenir Next Cyr W00 Medium',
  fonts: [
    { src: require('fonts/AvenirNextCyrMedium.woff') },
    { src: require('fonts/AvenirNextCyrMedium.woff2') },
  ],
});

Font.register({
  family: 'Avenir Next Cyr W00 Demi',
  fonts: [
    { src: require('fonts/AvenirNextCyrDemi.woff') },
    { src: require('fonts/AvenirNextCyrDemi.woff2') },
  ],
});

Font.register({
  family: 'Avenir Next Cyr W00 Bold',
  fonts: [
    { src: require('fonts/AvenirNextCyrBold.woff') },
    { src: require('fonts/AvenirNextCyrBold.woff2') },
  ],
});

const styles = StyleSheet.create({
  page: {
    color: theme.colors.textSecondary,
    fontFamily: 'Avenir Next Cyr W00 Regular',
    paddingBottom: `${spacing['4xl']}`,
  },
  productName: {
    color: theme.colors.primary,
    fontFamily: 'Avenir Next Cyr W00 Demi',
    fontSize: sizing(5),
    margin: `${spacing['2xs']} ${spacing.m}`,
  },
  wrapper: {
    flexDirection: 'row',
    padding: `0 ${spacing.m}`,
  },
  contentLeft: {
    flex: 1,
    marginRight: 10,
  },
  contentRight: {
    flex: 1,
    marginLeft: 10,
  },
});

export default styles;
