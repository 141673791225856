import { useTranslation } from 'react-i18next';
import { UseQueryOptions, useQuery as useReactQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from 'shared/constants';
import { selectIsQueryError } from 'store/calculator/selectors/calculator';
import { setIsQueryError } from 'store/calculator/slices/calculator';
import { useAppSelector } from 'store/hooks';

const useQuery = <T>(
  key: string,
  url: string,
  params: (string | number | undefined)[] = [],
  config: UseQueryOptions<T> = {}
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isQueryError = useAppSelector(selectIsQueryError);

  return useReactQuery<T>(
    [key, ...params],
    async () => {
      if (params.some((param) => param === undefined)) {
        throw new Error(t('query.missingParam') ?? '');
      }

      const res = await fetch(`${API_BASE_URL}${url}`);

      if (!res.ok) {
        throw new Error(t('query.requestFailed') ?? '');
      }

      return res.json();
    },
    {
      ...config,
      onError: () => dispatch(setIsQueryError(true)),
      onSuccess: () => {
        if (isQueryError) {
          dispatch(setIsQueryError(false));
        }
      },
    }
  );
};

export default useQuery;
