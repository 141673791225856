import { styled } from 'styled-components';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const Wrapper = styled.div`
  ${grid.threeColumn};
  border-bottom: ${({ theme }) => theme.border.default};
  padding-bottom: ${spacing['2xl']};

  ${breakpoint.mobile} {
    margin-top: ${spacing.s};
    padding-bottom: ${spacing.m};
  }
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${spacing.s};
`;
