import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

const useHasMobileFooter = () => {
  const location = useLocation();
  const [hasMobileFooter, setHasMobileFooter] = useState(false);

  useEffect(() => {
    location.pathname !== ROUTES.home &&
    !location.pathname.includes(ROUTES.results) &&
    !location.pathname.includes(ROUTES.productDetails)
      ? setHasMobileFooter(true)
      : setHasMobileFooter(false);
  }, [location.pathname]);

  return hasMobileFooter;
};

export default useHasMobileFooter;
