import styled from 'styled-components';
import ArrowIcon from 'assets/arrow-icon';
import ScreenTitle from 'components/screen-title';
import { spacing, breakpoint } from 'styles/helpers';
import { grid, iconSizing, rotate } from 'styles/mixins';

export const CardsWrapper = styled.div`
  ${grid.threeColumn};
  margin: ${spacing.s} 0;
`;

export const Title = styled(ScreenTitle)`
  margin: ${spacing.xl} 0 ${spacing.s};

  ${breakpoint.mobile} {
    margin: ${spacing.s} 0;
  }
`;

export const ExpandButton = styled.button`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  gap: ${spacing['2xs']};
  padding: ${spacing['2xs']};

  ${breakpoint.mobile} {
    justify-content: center;
    width: 100%;
  }
`;

export const ExpandButtonIcon = styled(ArrowIcon)<{
  optionsExpanded: boolean;
}>`
  ${iconSizing.xs}
  ${({ optionsExpanded }) => rotate(optionsExpanded ? 180 : 0)}
`;
