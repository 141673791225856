import CloseIcon from 'assets/close-icon';
import { Body3 } from 'components/typography';
import { styled } from 'styled-components';
import { spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
  padding: ${spacing.s} ${spacing.xs};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 6px;
  top: 6px;
`;

export const Title = styled(Body3)`
  color: ${({ theme }) => theme.colors.black};
`;

export const Text = styled(Body3)`
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const muiPaperStyles = {
  border: theme.border.default,
  boxShadow: '0 4px 108px rgb(0 0 0 / 5%)',
};
