import { SvgProps } from './types';

const RequestIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.41667 21.75C3.82083 21.75 3.31058 21.538 2.88592 21.1141C2.46197 20.6894 2.25 20.1792 2.25 19.5833V4.41667C2.25 3.82083 2.46197 3.31058 2.88592 2.88592C3.31058 2.46197 3.82083 2.25 4.41667 2.25H19.5833C20.1792 2.25 20.6894 2.46197 21.1141 2.88592C21.538 3.31058 21.75 3.82083 21.75 4.41667V19.5833C21.75 20.1792 21.538 20.6894 21.1141 21.1141C20.6894 21.538 20.1792 21.75 19.5833 21.75H4.41667ZM4.41667 19.5833H19.5833V16.3333H16.3333C15.7917 17.0194 15.1464 17.5521 14.3974 17.9313C13.6478 18.3104 12.8486 18.5 12 18.5C11.1514 18.5 10.3526 18.3104 9.60367 17.9313C8.854 17.5521 8.20833 17.0194 7.66667 16.3333H4.41667V19.5833ZM12 16.3333C12.6861 16.3333 13.309 16.1347 13.8688 15.7375C14.4285 15.3403 14.8167 14.8167 15.0333 14.1667H19.5833V4.41667H4.41667V14.1667H8.96667C9.18333 14.8167 9.57153 15.3403 10.1312 15.7375C10.691 16.1347 11.3139 16.3333 12 16.3333ZM12 14.1667L7.66667 9.83333L9.18333 8.2625L10.9167 9.99583V5.5H13.0833V9.99583L14.8167 8.2625L16.3333 9.83333L12 14.1667ZM4.41667 19.5833H7.66667C8.20833 19.5833 8.854 19.5833 9.60367 19.5833C10.3526 19.5833 11.1514 19.5833 12 19.5833C12.8486 19.5833 13.6478 19.5833 14.3974 19.5833C15.1464 19.5833 15.7917 19.5833 16.3333 19.5833H19.5833H4.41667Z"
      fill="currentColor"
    />
  </svg>
);

export default RequestIcon;
