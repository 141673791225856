import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CloseIcon from 'assets/close-icon';
import { Body4 } from 'components/typography';
import { breakpoint, spacing } from 'styles/helpers';
import { iconSizing } from 'styles/mixins';
import {
  CONTENT_MAX_WIDTH,
  CONTENT_MIN_WIDTH,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
} from 'styles/theme';

const LOGO_HEIGHT_MOBILE = '40px';
const LANGUAGE_SELECT_WIDTH = '220px';

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ theme }) => theme.border.inactive};
  height: ${HEADER_HEIGHT};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  ${breakpoint.mobile} {
    height: ${HEADER_HEIGHT_MOBILE};
  }
`;

export const HeaderContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${CONTENT_MAX_WIDTH};
  min-width: ${CONTENT_MIN_WIDTH};
  padding: 0 ${spacing.m};
  position: relative;
`;

export const StyledLink = styled(Link)`
  display: inline-block;
`;

export const StyledImg = styled.img`
  display: block;

  ${breakpoint.mobile} {
    height: ${LOGO_HEIGHT_MOBILE};
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.colors.textPrimary};
  ${iconSizing.default};
`;

export const SelectWrapper = styled.div`
  width: ${LANGUAGE_SELECT_WIDTH};
`;

export const CountryText = styled(Body4)`
  bottom: 0;
  color: ${({ theme }) => theme.colors.textDisabled};
  line-height: 18px;
  position: absolute;
  right: ${spacing.m};
`;
