import styled from 'styled-components';
import { breakpoint, spacing } from 'styles/helpers';

export const CountrySelectWrapper = styled.div`
  ${breakpoint.mobile} {
    padding: ${spacing.xl} ${spacing.m};
  }
`;

export const CountryGrid = styled.div`
  display: grid;
  gap: ${spacing['3xl']} ${spacing.m};
  grid-template-columns: repeat(5, 1fr);
  margin: ${spacing.xl} 0;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(2, 1fr);
    margin: ${spacing.s} 0 0;
  }
`;
