import { useField } from 'formik';
import useFormErrorTranslation from 'shared/hooks/useFormErrorTranslation';
import Select, { SelectProps } from '.';

type FormikSelectProps = {
  name: string;
} & Omit<SelectProps, 'onChange'>;

const FormikSelect = ({
  name,
  label,
  items,
  emptyValue,
  optional,
  tooltipText,
  translationKey,
}: FormikSelectProps) => {
  const [field, meta] = useField(name);
  const { onChange, value } = field;
  const { touched, error } = meta;
  const errorTranslation = useFormErrorTranslation(touched, error);

  return (
    <Select
      optional={optional}
      label={label}
      items={items}
      emptyValue={emptyValue}
      error={touched && !!error}
      helperText={errorTranslation}
      onChange={onChange(name)}
      value={value ?? ''}
      id={name}
      tooltipText={tooltipText}
      disabled={items.length === 1}
      translationKey={translationKey}
    />
  );
};

export default FormikSelect;
