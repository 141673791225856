import { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Select as SelectMui,
} from '@mui/material';
import InputHelperText from 'components/input-helper-text';
import InputLabel from 'components/input-label';
import {
  StyledArrowIcon,
  menuItemStyles,
  menuListStyles,
  menuStyles,
  selectStyles,
} from './styles';
import { SelectItem } from './types';

export interface SelectProps {
  optional?: boolean;
  error?: boolean;
  custom?: boolean;
  label?: string;
  helperText?: string;
  emptyValue?: string;
  items: SelectItem[];
  onChange?: (value: string) => void;
  value?: string;
  id?: string;
  tooltipText?: string;
  disabled?: boolean;
  small?: boolean;
  showLabel?: boolean;
  translationKey?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

const Select = ({
  optional,
  error = false,
  label,
  helperText,
  emptyValue,
  items,
  onChange,
  value,
  id,
  tooltipText,
  disabled = false,
  small = false,
  showLabel = true,
  translationKey,
  onOpen,
  onClose,
}: SelectProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (e: SelectChangeEvent) => {
    const newValue = String(e.target.value);
    setSelectedValue(newValue);
    onChange?.(newValue);
  };

  const openSelectMenu = () => {
    setOpen(true);
    onOpen?.();
  };
  const closeSelectMenu = () => {
    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    const closeSelectMenu = () => setOpen(false);
    window.addEventListener('scroll', closeSelectMenu);

    return () => {
      window.removeEventListener('scroll', closeSelectMenu);
    };
  }, []);

  return (
    <FormControl fullWidth>
      {label && showLabel && (
        <InputLabel
          id={`${id}-label`}
          label={label}
          optional={optional}
          tooltipText={tooltipText}
        />
      )}
      <SelectMui
        labelId={`${id}-label`}
        id={id}
        disabled={disabled}
        error={error}
        open={open}
        onOpen={openSelectMenu}
        onClose={closeSelectMenu}
        onChange={handleChange}
        value={value ?? selectedValue}
        displayEmpty
        sx={selectStyles(small, value)}
        inputProps={{ 'aria-label': label }}
        IconComponent={() => (
          <StyledArrowIcon error={error} disabled={disabled} />
        )}
        MenuProps={{
          disableScrollLock: isDesktop,
          sx: menuStyles,
          MenuListProps: {
            sx: menuListStyles,
          },
        }}
      >
        {emptyValue && (
          <MenuItem
            sx={menuItemStyles(small)}
            selected={false}
            value=""
            disableRipple
          >
            {emptyValue}
          </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem
            sx={menuItemStyles(small)}
            key={item.id}
            selected={false}
            value={item.id}
            disableRipple
          >
            {t(`${translationKey}.${item.name}`, {
              defaultValue: item.value ?? item.name,
            })}
          </MenuItem>
        ))}
      </SelectMui>
      {helperText && <InputHelperText error={error} helperText={helperText} />}
    </FormControl>
  );
};

export default Select;
