import useQuery from 'shared/hooks/useQuery';
import { SteelCriticalTemperature } from '../types';

const useGetSteelCriticalTemperatures = () => {
  const { data, error, isLoading, isError } = useQuery<
    SteelCriticalTemperature[]
  >('steel-critical-temperatures', 'v1/steel-elements/critical-temperatures');

  return {
    criticalTemperatures: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelCriticalTemperatures;
