import { SvgProps } from './types';

const ArrowIcon = ({ className, color }: SvgProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.94 6.01758L8 9.07091L11.06 6.01758L12 6.95758L8 10.9576L4 6.95758L4.94 6.01758Z"
      fill={color ? color : "currentColor"}
    />
  </svg>
);

export default ArrowIcon;
