import { useTranslation } from 'react-i18next';
import { InputLabel as InputLabelMui } from '@mui/material';
import parse from 'html-react-parser';
import Tooltip from 'components/tooltip';
import { Label, OptionalText, StyledInfoIcon, labelStyles } from './styles';

interface InputLabelProps {
  label: string;
  optional?: boolean;
  id?: string;
  tooltipText?: string;
}

const InputLabel = ({ label, optional, id, tooltipText }: InputLabelProps) => {
  const { t } = useTranslation();

  return (
    <InputLabelMui shrink={false} sx={labelStyles} id={id}>
      <Label>{parse(label)}</Label>
      {optional && <OptionalText>{t('common.optional')}</OptionalText>}
      {tooltipText && (
        <Tooltip text={tooltipText} maxWidth={260}>
          <StyledInfoIcon />
        </Tooltip>
      )}
    </InputLabelMui>
  );
};

export default InputLabel;
