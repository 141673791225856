import {
  NeverBelowZeroIcon,
  OccasionallyBelowZeroIcon,
} from 'assets/environment';
import { EnvironmentTemperature } from 'features/environment/types';
import { SvgIconSet } from 'shared/types';

export const TEMPERATURE_ICONS: SvgIconSet = {
  [EnvironmentTemperature.NeverBelowZero]: <NeverBelowZeroIcon />,
  [EnvironmentTemperature.OccasionallyBelowZero]: (
    <OccasionallyBelowZeroIcon />
  ),
};
