import { useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import { Maybe, AnyObject, ObjectSchema } from 'yup';

const useIsFormFilled = <T extends Maybe<AnyObject>>(
  values: FormikValues,
  validationSchema: ObjectSchema<T>,
  isValidatonDisabled?: boolean
) => {
  if (isValidatonDisabled) return true;

  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    validationSchema
      .validate(values)
      .then(() => setIsFormFilled(true))
      .catch(() => setIsFormFilled(false));
  }, [values]);

  return isFormFilled;
};

export default useIsFormFilled;
