import {
  ConcreteIcon,
  SteelIcon,
  TimberIcon,
} from 'assets/loadbearing-structures';
import { LoadbearingStructure } from 'shared/types';

export const LOADBEARING_STRUCTURES_ICONS = {
  [LoadbearingStructure.steel]: <SteelIcon />,
  [LoadbearingStructure.concrete]: <ConcreteIcon />,
  [LoadbearingStructure.timber]: <TimberIcon />,
};
