import moment from 'moment';
import 'moment/min/locales';

export const getPdfFileName = (productName?: string) => {
  moment.locale(navigator.language);

  const formatedProductName = productName?.replace(/\s/g, '-');
  const date = moment();
  const time = date.format('HHmm');
  const formatedDate = date.format('L').replaceAll(/[^0-9]/g, '');
  const pdfName = `${formatedProductName}_${formatedDate}_${time}.pdf`;

  return pdfName;
};
