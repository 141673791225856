import useQuery from 'shared/hooks/useQuery';
import { ConcreteOrientation } from '../types';

const useGetConcreteOrientations = () => {
  const { data, error, isLoading, isError } = useQuery<ConcreteOrientation[]>(
    'concrete-orientations',
    'v1/concrete-elements/orientations'
  );

  return {
    concreteOrientations: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetConcreteOrientations;
