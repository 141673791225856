import Button from '../button';
import { ButtonVariant } from '../button/types';
import QuestionMarkIcon from '../../assets/question-mark-icon';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../shared/hooks/useIsMobile';

type ContactSupportCtaButtonProps = {
  variant?: ButtonVariant;
  onClick?: () => void;
};

const ContactSupportCtaButton = ({
  variant = ButtonVariant.grey,
  onClick,
}: ContactSupportCtaButtonProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Button
      content={t('modal.contactSupport')}
      variant={variant}
      startIcon={<QuestionMarkIcon />}
      onClick={onClick}
      fullWidth={isMobile}
    />
  );
};

export default ContactSupportCtaButton;
