import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import { ButtonVariant } from 'components/button/types';
import { StyledArrowIcon } from './styles';

interface ContinueButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

const ContinueButton = ({ disabled, onClick }: ContinueButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type="submit"
      variant={ButtonVariant.filled}
      content={t('common.continue')}
      endIcon={<StyledArrowIcon />}
    />
  );
};

export default ContinueButton;
