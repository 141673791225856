import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import useSessionStorage from './useSessionStorage';

const useRestartCalculator = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useSessionStorage(SESSION_STORAGE_KEYS.country);

  const restartCalculator = () => {
    navigate(ROUTES.structureSelect);
    sessionStorage.clear();
    setCountry(country);
  };

  return restartCalculator;
};

export default useRestartCalculator;
