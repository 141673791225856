import { styled } from 'styled-components';
import { theme } from 'styles/theme';

export const DisclaimerContentWrapper = styled.div`
  color: ${theme.colors.textDisclaimer};
  font-size: ${theme.typography.body6};
  grid-area: 2 / 2 / 3 / 3;
  
  & > * {
    margin-bottom: 8px;
  }

  & > ul {
    list-style-position: inside;
    padding-left: 8px;
  }
`;
