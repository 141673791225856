import { Country, Language } from 'shared/types';
import getLanguageAndCountryFromLocale from './getLanguageAndCountryFromLocale';

const isLanguageAvailable = (code: string, languages?: Language[]) =>
  languages?.some((language) => language.code === code);

const isCountryAvailable = (code: string, countries?: Country[]) =>
  countries?.some((country) => country.code === code);

export const isLocaleAvailable = (
  locale: string,
  languages?: Language[],
  countries?: Country[]
) => {
  const { languageCode, countryCode } = getLanguageAndCountryFromLocale(locale);

  if (countryCode) {
    return (
      isLanguageAvailable(languageCode, languages) &&
      isCountryAvailable(countryCode, countries)
    );
  }

  return isLanguageAvailable(locale, languages);
};
