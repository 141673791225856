import { styled } from 'styled-components';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const Wrapper = styled.div`
  ${grid.threeColumn};
  border-top: ${({ theme }) => theme.border.default};
  margin: ${spacing['2xl']} 0;
  padding-top: ${spacing['2xl']};

  ${breakpoint.mobile} {
    margin: ${spacing.m} 0;
    padding-top: ${spacing.m};
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};
`;
