import ArrowIcon from 'assets/arrow-icon';
import { theme } from '../../styles/theme';
import { NumberInputButtonsWrapper } from './styles';

interface NumberInputButtonsProps {
  disableTop?: boolean;
  disableBottom?: boolean;
  onUpBtnClick: () => void;
  onDownBtnClick: () => void;
}

const NumberInputButtons = ({
  onUpBtnClick,
  onDownBtnClick,
  disableTop = false,
  disableBottom = false,
}: NumberInputButtonsProps) => (
  <NumberInputButtonsWrapper>
    <button disabled={disableTop} type="button" onClick={onUpBtnClick}>
      <ArrowIcon
        color={
          disableTop
            ? theme.colors.buttonDisabled
            : theme.colors.numberInputArrow
        }
      />
    </button>
    <button disabled={disableBottom} type="button" onClick={onDownBtnClick}>
      <ArrowIcon
        color={
          disableBottom
            ? theme.colors.buttonDisabled
            : theme.colors.numberInputArrow
        }
      />
    </button>
  </NumberInputButtonsWrapper>
);

export default NumberInputButtons;
