import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectCalculator = (state: RootState) => state.calculator;

export const selectIsQueryError = createSelector(
  selectCalculator,
  (state) => state.isQueryError
);

export const selectIsLanguageChanging = createSelector(
  selectCalculator,
  (state) => state.isLanguageChanging
);
