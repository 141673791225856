import { Form } from 'formik';
import { styled } from 'styled-components';
import ScreenTitle from 'components/screen-title';
import { breakpoint, spacing } from 'styles/helpers';

export const Title = styled(ScreenTitle)`
  margin: ${spacing.xl} 0 ${spacing.s};

  ${breakpoint.mobile} {
    margin: ${spacing.s} 0;
  }
`;

export const StyledForm = styled(Form)`
  display: contents;
`;
