import { ReactNode } from 'react';
import useHasMobileFooter from 'shared/hooks/useHasMobileFooter';
import { StyledWrapper } from './styles';

interface MainWrapperProps {
  children: ReactNode;
}

const MainWrapper = ({ children }: MainWrapperProps) => {
  const hasMobileFooter = useHasMobileFooter();

  return (
    <StyledWrapper $hasMobileFooter={hasMobileFooter}>{children}</StyledWrapper>
  );
};

export default MainWrapper;
