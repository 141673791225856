import {
  TableBody,
  TableCell,
  TableHead,
  Table as TableMui,
  TableRow,
} from '@mui/material';
import parse from 'html-react-parser';
import Tooltip from 'components/tooltip';
import { Heading7, Heading9, Body3 } from 'components/typography';
import { NameValuePair } from 'shared/types';
import {
  muiTableStyles,
  muiTableHeadStyles,
  muiTableCellStyles,
  TableTitle,
  StyledInfoIcon,
  muiTableRowStyles,
} from './styles';

interface TableProps {
  rowsData: NameValuePair[];
  tooltipText?: string;
  title: string;
}

const Table = ({ rowsData, tooltipText, title }: TableProps) => (
  <TableMui sx={muiTableStyles}>
    <TableHead sx={muiTableHeadStyles}>
      <TableRow>
        <TableCell colSpan={2} sx={muiTableCellStyles}>
          <TableTitle>
            <Heading7>{title}</Heading7>
            {tooltipText && (
              <Tooltip text={tooltipText}>
                <StyledInfoIcon />
              </Tooltip>
            )}
          </TableTitle>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {rowsData.map((row) => (
        <TableRow key={row.name} sx={muiTableRowStyles}>
          <TableCell component="th" scope="row" sx={muiTableCellStyles}>
            <Heading9>{row.name}</Heading9>
          </TableCell>
          <TableCell align="left" sx={muiTableCellStyles}>
            <Body3>{parse(row.value)}</Body3>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </TableMui>
);

export default Table;
