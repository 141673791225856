import { Theme } from './theme';

const UNIT = 4;

const size = (amount: number) => amount * UNIT;

export const sizing = (amount: number) => size(amount) + 'px';

export const spacing = {
  '3xs': sizing(1),
  '2xs': sizing(2),
  xs: sizing(3),
  s: sizing(4),
  m: sizing(5),
  l: sizing(6),
  xl: sizing(8),
  '2xl': sizing(9),
  '3xl': sizing(10),
  '4xl': sizing(16),
};

export const radii = {
  xs: '3px',
  s: sizing(1),
};

const makeBreakpoint = (num: number) =>
  `@media screen and (max-width: ${num}px)`;

export const breakpoint = {
  mobile: ({ theme }: { theme: Theme }) =>
    makeBreakpoint(theme.BreakpointsPx.mobile),
  tablet: ({ theme }: { theme: Theme }) =>
    makeBreakpoint(theme.BreakpointsPx.tablet),
  desktop: ({ theme }: { theme: Theme }) =>
    makeBreakpoint(theme.BreakpointsPx.desktop),
};
