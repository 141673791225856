import {
  AngleLineIcon,
  CProfileIcon,
  CustomProfileIcon,
  HIProfileIcon,
  RectangularTubeIcon,
  SquareTubeIcon,
  TubeIcon,
} from 'assets/steel-profile-shapes';
import { SvgIconSet } from 'shared/types';

export const SHAPE_ICONS: SvgIconSet = {
  'H/I Profile': <HIProfileIcon />,
  'C Profile': <CProfileIcon />,
  Tube: <TubeIcon />,
  'Angle line': <AngleLineIcon />,
  'Square Tube': <SquareTubeIcon />,
  'Rectangular Tube': <RectangularTubeIcon />,
  'Custom profile': <CustomProfileIcon />,
};
