import useQuery from 'shared/hooks/useQuery';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { getProtectedSidesType } from '../protected-sides/logic';
import { ProtectedSidesItem } from '../types';

const useGetSteelProtectedSides = () => {
  const [structuralElements] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelStructuralElements
  );
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);

  const protectedSideType = getProtectedSidesType(product);

  const { data, error, isLoading, isError } = useQuery<ProtectedSidesItem[]>(
    'steel-protected-sides',
    `v1/steel-elements/profile-shapes/${structuralElements?.profileSubtype}/${protectedSideType}/profile-protected-sides`,
    [structuralElements]
  );

  return {
    steelProtectedSides: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelProtectedSides;
