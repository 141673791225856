import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/error-message';
import FormikSelectCard from 'components/select-card/formik-select-card';
import { Heading7 } from 'components/typography';
import { FireRating } from 'shared/types';
import { theme } from 'styles/theme';
import {
  getAdditionalOptions,
  getMainOptions,
  fireIconToDisplay,
} from './logic';
import { CardsWrapper, ExpandButton, ExpandButtonIcon, Title } from './styles';

interface FireRatingsProps {
  selectedFireRating: number;
  fireRatingValues: FireRating[];
  touched?: boolean;
  error: string;
}

const FireRatings = ({
  selectedFireRating,
  fireRatingValues,
  touched,
  error,
}: FireRatingsProps) => {
  const { t } = useTranslation();
  const mainOptions = getMainOptions(fireRatingValues);
  const additionalOptions = getAdditionalOptions(fireRatingValues);
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const [optionsToDisplay, setOptionsToDisplay] = useState(mainOptions);

  useEffect(() => {
    additionalOptions.find((option) => option.id === selectedFireRating) &&
      setOptionsExpanded(true);
  }, [selectedFireRating]);

  useEffect(() => {
    setOptionsToDisplay(optionsExpanded ? fireRatingValues : mainOptions);
  }, [optionsExpanded]);

  return (
    <>
      <Title title={t('fireRating.title')} displayContactSupport/>
      {touched && error && <ErrorMessage error={error} />}
      <CardsWrapper>
        {optionsToDisplay.map((option) => (
          <FormikSelectCard
            key={option.id}
            title={t('fireRating.time', { amount: option.value })}
            selected={option.id === selectedFireRating}
            name="fireRating"
            value={option.id}
            iconPng={fireIconToDisplay(option.value)}
            iconBackground={theme.colors.backgroundLight}
          />
        ))}
      </CardsWrapper>
      {!!additionalOptions?.length && (
        <ExpandButton
          type="button"
          onClick={() => setOptionsExpanded(!optionsExpanded)}
        >
          <Heading7>
            {optionsExpanded ? t('fireRating.less') : t('fireRating.more')}
          </Heading7>
          <ExpandButtonIcon optionsExpanded={optionsExpanded} />
        </ExpandButton>
      )}
    </>
  );
};

export default FireRatings;
