import { API_BASE_URL } from 'shared/constants';
import useLocale from 'shared/hooks/useLocale';
import useMutation from 'shared/hooks/useMutation';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { CalculationResultData } from 'shared/types';
import { SteelCalculationResultRequest } from '../types';

const useGetSteelCalculations = (productId?: string) => {
  const locale = useLocale();
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);
  const [inputParametersFromResults] = useSessionStorage(
    SESSION_STORAGE_KEYS.inputParametersFromResults
  );
  const [structuralElements] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelStructuralElements
  );
  const [protectedSides] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelProtectedSides
  );
  const [fireRating] = useSessionStorage(SESSION_STORAGE_KEYS.steelFireRating);
  const [environment] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelEnvironment
  );
  const productCategory = inputParametersFromResults?.category ?? product?.category;
  const orientationId = inputParametersFromResults?.orientation ?? structuralElements?.orientation;
  const fireRatingId = inputParametersFromResults?.fireRatingId ?? fireRating?.fireRating;
  const totalAreaToProtectM2 = inputParametersFromResults?.totalAreaToProtectM2 ?? environment?.areaToProtect;
  const criticalTemperatureId = inputParametersFromResults?.criticalTemperatureId ?? fireRating?.criticalTemperature;
  const criticalTemperatureC = inputParametersFromResults?.criticalTemperatureC ?? fireRating?.customCriticalTemperature;
  const sectionFactor = inputParametersFromResults?.sectionFactor ?? structuralElements?.sectionFactor;
  const crossSectionalShapeId = inputParametersFromResults?.crossSectionalShapeId ?? structuralElements?.sectionType;
  const profileSubTypeId = inputParametersFromResults?.profileSubTypeId ?? structuralElements?.profileSubtype;
  const protectedSideId = inputParametersFromResults?.protectedSideId ?? protectedSides;
  const wastage = inputParametersFromResults?.wastage ?? environment?.wastage;

  const request: SteelCalculationResultRequest = {
    orientationId,
    fireRatingId,
    totalAreaToProtectM2: Number(totalAreaToProtectM2),
    wastage: Number(wastage),
    criticalTemperatureId: Number(criticalTemperatureId),
    criticalTemperatureC: Number(criticalTemperatureC),
    sectionFactor: Number(sectionFactor),
    crossSectionalShapeId: Number(crossSectionalShapeId),
    profileSubTypeId: Number(profileSubTypeId),
    protectedSideId: protectedSideId,
  };

  const { mutate, isLoading, data } = useMutation<
    SteelCalculationResultRequest,
    CalculationResultData
  >(
    `${API_BASE_URL}v1/products/steel/${productCategory}/${locale}/${productId}/calculation-results`,
    request
  );

  return {
    calculationResult: data,
    getSteelCalculations: mutate,
    isLoading,
  };
};

export default useGetSteelCalculations;
