import IconButton from 'components/icon-button';
import {
  StyledCloseIcon,
  ModalHeaderWrapper,
  Title,
} from './styles';

type ModalComponentProps = {
  title?: string;
  handleClose: () => void;
};

const ModalHeader = ({ title, handleClose }: ModalComponentProps) => {
  return (
    <ModalHeaderWrapper>
      <Title>{title}</Title>
      <IconButton onClick={handleClose} icon={<StyledCloseIcon />} />
    </ModalHeaderWrapper>
  );
};
export default ModalHeader;
