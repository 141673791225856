import { TFunction } from 'i18next';

const mapInstallationDetails = (
  t: TFunction,
  testReportNo?: string,
  installationDetails?: { [key: string]: string }
) => {
  if (!installationDetails) {
    return [];
  }

  const mappedInstallationDetails = Object.keys(installationDetails).map(
    (detailKey) => ({
      name: detailKey,
      value: installationDetails[detailKey],
    })
  );

  return [
    ...(testReportNo
      ? [
          {
            name: t('productDetails.testReportNo'),
            value: testReportNo,
          },
        ]
      : []),
    ...mappedInstallationDetails,
  ];
};

export default mapInstallationDetails;
