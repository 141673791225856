import { ReactNode } from 'react';
import { StyledWrapper } from './styles';

interface IframeWrapperProps {
  children: ReactNode;
}

const IframeWrapper = ({ children }: IframeWrapperProps) => (
  <StyledWrapper>{children}</StyledWrapper>
);

export default IframeWrapper;
