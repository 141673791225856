import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import { ButtonVariant } from 'components/button/types';
import { Body4, Heading9 } from 'components/typography';
import { ImageRendition, ProductWithCalculationDetails } from 'shared/types';
import getProductImage from 'shared/utils/getProductImage';
import {
  Card,
  Tag,
  Container,
  Description,
  DetailsContainer,
  DetailsRow,
  ImageWrapper,
  ProductTitle,
  StyledImg,
  StyledIcon,
} from './styles';

interface ProductCardProps {
  product: ProductWithCalculationDetails;
  onClick: () => void;
}

const ProductCard = ({ product, onClick }: ProductCardProps) => {
  const { t } = useTranslation();
  const { productDetails, calculationResult } = product;

  return (
    <Card>
      <ImageWrapper>
        <StyledImg
          src={getProductImage(
            productDetails.previewImageId,
            ImageRendition.Preview
          )}
          alt={productDetails.name}
        />
      </ImageWrapper>
      <Container>
        <Tag>{t(`product.category.${productDetails.categoryType}`)}</Tag>
        <ProductTitle>{productDetails.name}</ProductTitle>
        <Description>{productDetails.shortDescription ?? ''}</Description>
        <DetailsContainer>
          {calculationResult.map((detail) => (
            <DetailsRow key={detail.name}>
              <Body4>{detail.name}</Body4>
              <Heading9>{detail.value}</Heading9>
            </DetailsRow>
          ))}
        </DetailsContainer>
        <Button
          variant={ButtonVariant.grey}
          content={t('results.viewResult')}
          startIcon={<StyledIcon />}
          fullWidth
          onClick={onClick}
        />
      </Container>
    </Card>
  );
};

export default ProductCard;
