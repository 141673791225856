const RectangularTubeIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66848 100H53.3325C53.8208 99.9727 54.2739 99.75 54.5981 99.3867C54.6215 99.3555 54.6567 99.3477 54.6801 99.3164L99.6801 37.6484C99.7075 37.5937 99.7348 37.539 99.7543 37.4844C99.8754 37.289 99.9457 37.0742 99.9653 36.8476C99.9809 36.789 99.9926 36.7265 100 36.6679V1.66794C100 1.62107 99.977 1.58591 99.977 1.54294C99.9653 1.42966 99.9418 1.31638 99.9067 1.21091C99.8832 1.10935 99.8481 1.01169 99.8051 0.917943C99.7543 0.828099 99.6957 0.746063 99.6332 0.664033C99.5629 0.57419 99.4848 0.488254 99.3989 0.414034C99.3676 0.38669 99.352 0.347627 99.3168 0.320284C99.2621 0.29294 99.2075 0.265596 99.1528 0.246065C98.9575 0.124975 98.7387 0.0546555 98.5122 0.0351255C98.4536 0.0195005 98.395 0.00778148 98.3325 -3.05176e-05H46.6685C46.6255 -3.05176e-05 46.5864 0.0195005 46.5435 0.0234075C46.4302 0.0351265 46.3169 0.0585635 46.2075 0.0937195C46.1099 0.117157 46.0122 0.152313 45.9224 0.195279C45.8286 0.246061 45.7466 0.304659 45.6646 0.367159C45.5747 0.437471 45.4888 0.515599 45.4146 0.601529C45.3872 0.632779 45.3481 0.648405 45.3208 0.683562L0.320802 62.3516C0.293459 62.4062 0.266115 62.4609 0.246584 62.5156C0.125494 62.7109 0.0551743 62.9258 0.0356443 63.1523C0.0200193 63.2109 0.00830028 63.2734 0.000488281 63.332V98.332C0.000488281 99.2539 0.746578 100 1.66849 100L1.66848 100ZM47.5125 3.33201H95.0555L52.4895 61.668H4.94648L47.5125 3.33201ZM96.6685 36.125L55.0005 93.223V63.875L96.6685 6.77701V36.125ZM51.6685 96.668H3.33248V65H51.6685V96.668Z"
      fill="#222465"
    />
    <path
      d="M6.75834 92.1249C6.85209 92.4296 7.02787 92.703 7.26615 92.9179C7.2974 92.9491 7.31693 92.9882 7.35209 93.0116C7.41849 93.0546 7.49272 93.0937 7.56693 93.1249C7.62552 93.1562 7.68802 93.1874 7.74662 93.2148C7.93412 93.289 8.13334 93.328 8.33256 93.3319H46.6686C47.5865 93.3319 48.3327 92.5859 48.3327 91.6678V69.9998C48.3327 69.078 47.5866 68.3318 46.6686 68.3318H8.33256C7.41459 68.3318 6.66846 69.0779 6.66846 69.9998V91.6678C6.66846 91.7108 6.68799 91.746 6.69189 91.7889C6.70361 91.9022 6.72705 92.0155 6.7583 92.1249L6.75834 92.1249ZM45.0003 89.9999H11.6133L24.9963 71.6679H45.0003V89.9999ZM20.8713 71.6679L10.0003 86.5589V71.6679H20.8713Z"
      fill="#222465"
    />
  </svg>
);

export default RectangularTubeIcon;
