import { useFormikContext } from 'formik';
import SelectCard, { SelectCardProps } from '.';

type FormikSelectCardProps = {
  name: string;
  value: string | number;
} & SelectCardProps;

const FormikSelectCard = ({
  name,
  title,
  displayArrow,
  selected,
  image,
  value,
  iconPng,
  iconSvg,
  iconBackground,
}: FormikSelectCardProps) => {
  const { setFieldValue } = useFormikContext();

  const handleOnClick = () => setFieldValue(name, value);

  return (
    <SelectCard
      iconPng={iconPng}
      iconSvg={iconSvg}
      image={image}
      title={title}
      selected={selected}
      displayArrow={displayArrow}
      onClick={handleOnClick}
      iconBackground={iconBackground}
    />
  );
};

export default FormikSelectCard;
