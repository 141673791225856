import { Body2 } from 'components/typography';
import FlagIcon from './flag-icon';
import { Card } from './styles';

interface CountryCardProps {
  title: string;
  onClick: () => void;
  iconSvgString?: string;
}

const CountryCard = ({ onClick, title, iconSvgString }: CountryCardProps) => (
  <Card onClick={onClick}>
    {iconSvgString && <FlagIcon svgString={iconSvgString} />}
    <Body2>{title}</Body2>
  </Card>
);

export default CountryCard;
