import { useTranslation } from 'react-i18next';
import { LoadbearingStructure, ProgressStep } from 'shared/types';

const useProgressSteps = (
  structure?: LoadbearingStructure,
  hasProtectedSides?: boolean
) => {
  const { t } = useTranslation();
  const initialSteps = [
    { value: ProgressStep.structure, label: t('steps.structure') },
    { value: ProgressStep.product, label: t('steps.product') },
  ];

  switch (structure) {
    case LoadbearingStructure.steel:
    case LoadbearingStructure.concrete:
      return [
        ...initialSteps,
        {
          value: ProgressStep.structuralElements,
          label: t('steps.structuralElements'),
        },
        ...(hasProtectedSides
          ? [
              {
                value: ProgressStep.protectedSides,
                label: t('steps.protectedSides'),
              },
            ]
          : []),
        { value: ProgressStep.environment, label: t('steps.environment') },
        { value: ProgressStep.fireRating, label: t('steps.fireRating') },
        { value: ProgressStep.result, label: t('steps.result') },
      ];
    case LoadbearingStructure.timber:
      return []; //TODO: timber progress steps?
    default:
      return initialSteps;
  }
};

export default useProgressSteps;
