import { useTranslation } from 'react-i18next';
import BackButton from 'components/back-button';
import { ButtonVariant } from 'components/button/types';
import ContinueButton from 'components/continue-button';
import useIsMobile from 'shared/hooks/useIsMobile';
import { SelectToContinueText, Wrapper } from './styles';

interface BottomActionsProps {
  hasBackButton?: boolean;
  onBackClick?: () => void;

  hasContinueBtn?: boolean;
  onContinueClick?: () => void;

  backVariant?: ButtonVariant;
  isContinueDisabled?: boolean;
}

const BottomActions = ({
  hasBackButton = true,
  onBackClick,

  hasContinueBtn,
  onContinueClick,

  backVariant,
  isContinueDisabled,
}: BottomActionsProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      {hasBackButton && (
        <BackButton variant={backVariant} onClick={onBackClick} />
      )}
      {hasContinueBtn ? (
        <ContinueButton
          disabled={isContinueDisabled}
          onClick={onContinueClick}
        />
      ) : (
        isMobile && (
          <SelectToContinueText>
            {t('common.selectToContinue')}
          </SelectToContinueText>
        )
      )}
    </Wrapper>
  );
};

export default BottomActions;
