import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = { isQueryError: false, isLanguageChanging: false };

const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    setIsQueryError: (state, { payload }: PayloadAction<boolean>) => {
      state.isQueryError = payload;
    },
    setIsLanguageChanging: (state, { payload }: PayloadAction<boolean>) => {
      state.isLanguageChanging = payload;
    },
  },
});

const { reducer } = calculatorSlice;
export default reducer;

export const { setIsQueryError, setIsLanguageChanging } =
  calculatorSlice.actions;
