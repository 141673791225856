import { useTranslation } from 'react-i18next';
import { DisclaimerContentWrapper } from './styles';

const PATH_TO_TRANSLATIONS = 'steel.fireRating.disclaimer';

const CriticalTemperaturesDisclaimer = () => {
  const { t } = useTranslation();
  const bulletList = t(`${PATH_TO_TRANSLATIONS}.paragraph2`).split('\n\n');

  return (
    <DisclaimerContentWrapper>
      <p>
        {t(`${PATH_TO_TRANSLATIONS}.paragraph1`)}
      </p>
      {bulletList.length > 0 && (
        <ul>
          {bulletList.map((item, index) => (
            <li key={'bulletpoint'+ index}>{item}</li>
          ))}
        </ul>
      )}
      <p>
        {t(`${PATH_TO_TRANSLATIONS}.paragraph3`)}
      </p>
    </DisclaimerContentWrapper>
  );
};

export default CriticalTemperaturesDisclaimer;
