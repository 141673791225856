import styled from 'styled-components';
import ArrowIcon from 'assets/arrow-icon';
import { sizing, spacing } from 'styles/helpers';
import { iconSizing } from 'styles/mixins';
import { theme } from 'styles/theme';

const MAX_MENU_HEIGHT = '260px';

export const selectStyles = (small: boolean, value?: string) => {
  return {
    color: value ? theme.colors.black : theme.colors.textDisabled,
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.s,
    height: small ? sizing(8) : sizing(12),
    width: '100%',

    '& .MuiSelect-select.MuiOutlinedInput-input': {
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      height: '100%',
      padding: spacing.s,
      width: '100%',

      '&.Mui-disabled': {
        color: theme.colors.textDisabled,
        WebkitTextFillColor: 'unset',
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: theme.border.inactive,
    },

    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: theme.border.error,
      },
    },

    '&.Mui-focused, &:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: theme.border.primary,
      },
    },

    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: theme.border.default,
      },
    },
  };
};

export const menuStyles = {
  '& .MuiPaper-root': {
    border: theme.border.light,
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: '0 4px 8px rgba(139 143 152 / 25%)',
    marginTop: spacing['2xs'],
  },
};

export const menuListStyles = {
  '&.MuiList-root': {
    maxHeight: MAX_MENU_HEIGHT,
    overflowX: 'auto',
    padding: 0,
  },
};

export const menuItemStyles = (small: boolean) => ({
  fontFamily: theme.fontFamily.medium,
  fontSize: theme.fontSize.s,

  '&.MuiMenuItem-root': {
    borderBottom: theme.border.light,
    color: theme.colors.black,
    height: small ? sizing(9) : sizing(12),
    minHeight: small ? sizing(9) : sizing(12),
    padding: spacing.s,
  },

  '&.Mui-selected': {
    backgroundColor: theme.colors.backgroundLight,
    color: theme.colors.primary,
  },
});

export const StyledArrowIcon = styled(ArrowIcon)<{
  error: boolean;
  disabled: boolean;
}>`
  ${iconSizing.default};
  color: ${({ theme, error, disabled }) =>
    error
      ? theme.colors.error
      : disabled
      ? theme.colors.textDisabled
      : theme.colors.primary};
  position: absolute;
  right: 12px;
  z-index: -1;
`;
