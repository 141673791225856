import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useGetCountries from 'api/useGetCountries';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import useLocalStorage from './useLocalStorage';

const useGetDefaultCountry = () => {
  const { i18n } = useTranslation();
  const { countries } = useGetCountries();
  const { getValueFromLocalStorage, saveValueToLocalStorage } =
    useLocalStorage();

  const defaultCountry = useMemo(() => {
    const splitLocale = i18n.language.split('-');
    const countryCodeInLang = splitLocale[splitLocale.length - 1];

    if (countryCodeInLang && countries) {
      const defaultCountryInURL = countries.find((country) => country.code === countryCodeInLang);

      defaultCountryInURL &&
        !getValueFromLocalStorage(SESSION_STORAGE_KEYS.country) &&
        saveValueToLocalStorage(SESSION_STORAGE_KEYS.country, {
          code: defaultCountryInURL.code,
          name: defaultCountryInURL.name,
        });

      return defaultCountryInURL;
    }
  }, [countries, i18n.language]);

  return defaultCountry;
};

export default useGetDefaultCountry;
