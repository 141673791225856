import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import { ButtonVariant } from 'components/button/types';
import { StyledArrowIcon } from './styles';

interface BackButtonProps {
  onClick?: () => void;
  variant?: ButtonVariant;
}

const BackButton = ({ onClick, variant }: BackButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      variant={variant}
      content={t('common.back')}
      startIcon={<StyledArrowIcon small={variant === ButtonVariant.default} />}
    />
  );
};

export default BackButton;
