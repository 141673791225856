import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation as useReactMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { selectIsQueryError } from 'store/calculator/selectors/calculator';
import { setIsQueryError } from 'store/calculator/slices/calculator';
import { useAppSelector } from 'store/hooks';

const useMutation = <T, D>(apiUrl: string, request: T) => {
  const { t } = useTranslation();
  const [data, setData] = useState<D>();
  const dispatch = useDispatch();
  const isQueryError = useAppSelector(selectIsQueryError);

  const postApi = async () => {
    const res = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    if (!res.ok) {
      throw new Error(t('query.requestFailed') ?? '');
    }

    const data = res.status !== 204 ? await res.json() : null;

    return data;
  };

  const { mutate, isLoading } = useReactMutation(postApi, {
    onSuccess: (data) => {
      if (isQueryError) {
        dispatch(setIsQueryError(false));
      }
      setData(data);
    },
    onError: () => {
      dispatch(setIsQueryError(true));
    },
  });

  return { mutate, isLoading, data };
};

export default useMutation;
