import { FC } from 'react';
import { DisclaimerContentWrapper } from './styles';

interface Props {
  disclaimer: string;
}

const ProtectedSidesDisclaimer: FC<Props> = ({disclaimer}) => {
  return <DisclaimerContentWrapper>{disclaimer}</DisclaimerContentWrapper>;
};

export default ProtectedSidesDisclaimer;
