import { styled } from 'styled-components';
import { breakpoint, radii, spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

const IMAGE_HEIGHT = '334px';
const IMAGE_HEIGHT_MOBILE = '211px';
const THUMB_DIMENSIONS = '68px';
const PAGINATION_BULLET_DIMENSIONS = '6px';

export const SwiperWrapper = styled.div`
  .swiper {
    border: ${theme.border.default};
    height: ${IMAGE_HEIGHT};

    ${breakpoint.mobile} {
      border-radius: ${radii.s};
      height: ${IMAGE_HEIGHT_MOBILE};
    }
  }

  .swiper-slide {
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .swiper-custom-pagination {
    display: flex;
    justify-content: center;
    margin: ${spacing['2xs']} 0;

    .swiper-pagination-bullet {
      background-color: ${theme.colors.inactive};
      height: ${PAGINATION_BULLET_DIMENSIONS};
      margin: 0 3px;
      opacity: 1;
      width: ${PAGINATION_BULLET_DIMENSIONS};
    }

    .swiper-pagination-bullet-active {
      background-color: ${theme.colors.hover};
    }
  }
`;

export const ThumbsWrapper = styled.div`
  .swiper {
    margin-top: ${spacing.s};
  }

  .swiper-slide {
    cursor: pointer;
    height: ${THUMB_DIMENSIONS};
    width: ${THUMB_DIMENSIONS};

    &:not(:last-child) {
      margin-right: ${spacing.xs};
    }

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .swiper-slide-thumb-active {
    border: ${theme.border.primary};
  }
`;
