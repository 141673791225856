import Button from '../button';
import { ButtonVariant } from '../button/types';
import { useTranslation } from 'react-i18next';
import RequestIcon from '../../assets/request-icon';
import useIsMobile from '../../shared/hooks/useIsMobile';

type RequestSpecificationsCtaButtonProps = {
  variant?: ButtonVariant;
  onClick?: () => void;
}

const RequestSpecificationsCtaButton = ({variant, onClick}: RequestSpecificationsCtaButtonProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return ( <Button
    content={t('modal.requestSpecification')}
    variant={variant}
    startIcon={<RequestIcon />}
    onClick={onClick}
    fullWidth={isMobile}
  />);
};

export default RequestSpecificationsCtaButton;
