import { StyleSheet } from '@react-pdf/renderer';
import { sizing, spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

const styles = StyleSheet.create({
  descriptionWrapper: {
    fontSize: 10,
    marginTop: spacing.xs,
  },
  text: {
    marginTop: spacing.xs,
  },
  strongText: {
    fontFamily: 'Avenir Next Cyr W00 Medium',
  },
  detailsTitle: {
    color: theme.colors.primary,
    fontFamily: 'Avenir Next Cyr W00 Medium',
    marginTop: spacing.s,
  },
  table: {
    borderColor: theme.colors.border,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    marginTop: spacing['3xs'],
  },
  list: {
    marginTop: spacing['3xs'],
  },
  tableRow: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: theme.colors.border,
    flexDirection: 'row',
  },
  tableCell: {
    borderColor: theme.colors.border,
    borderRightWidth: 1,
    flex: 1,
    fontSize: sizing(2),
    padding: 2,
    textAlign: 'center',
  },
});

export default styles;
