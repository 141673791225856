import { styled } from 'styled-components';
import ArrowIcon from 'assets/arrow-icon';
import { breakpoint, sizing, spacing } from 'styles/helpers';
import { iconSizing, rotate } from 'styles/mixins';
import { CONTENT_MIN_WIDTH, TOP_ACTIONS_HEIGHT_MOBILE } from 'styles/theme';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: ${CONTENT_MIN_WIDTH};
  width: 100%;

  ${breakpoint.mobile} {
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.textDisabled}`};
    height: ${TOP_ACTIONS_HEIGHT_MOBILE};
    padding-right: ${spacing.m};
    position: fixed;
    z-index: 100;
  }
`;

export const ContentLeft = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing['2xs']};
`;

export const IconBackButton = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  height: ${sizing(6)};
  padding-left: ${spacing.xs};
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  ${rotate(90)};
  ${iconSizing.default};
`;
