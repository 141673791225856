import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import { ThemeProvider } from 'styled-components';
import i18n from 'i18n/config';
import useBaseUrl from 'shared/hooks/useBaseUrl';
import setInitialLanguageAttribute from 'shared/utils/setInitialLanguageAttribute';
import { store } from 'store';
import GlobalCSS from 'styles/global';
import { theme } from 'styles/theme';

setInitialLanguageAttribute();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Router = () => {
  const { baseURL, isLoading } = useBaseUrl();

  return (
    <BrowserRouter basename={baseURL} key={baseURL}>
      <App isLoading={isLoading} />
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <GlobalCSS />
            <Router />
          </ThemeProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
