import styled from 'styled-components';
import ScreenTitle from 'components/screen-title';
import { spacing, breakpoint } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const CardsWrapper = styled.div`
  ${grid.threeColumn};
  margin: ${spacing.s} 0;
`;

export const Title = styled(ScreenTitle)`
  margin: ${spacing.xl} 0 ${spacing.s};

  ${breakpoint.mobile} {
    margin: ${spacing.s} 0;
  }
`;

export const Wrapper = styled.div`
  ${breakpoint.mobile} {
    padding: ${spacing.s} ${spacing.m};
  }
`;