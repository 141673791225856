const useLocalStorage = () => {
  const saveValueToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getValueFromLocalStorage = (key: string) => {
    const value = localStorage.getItem(key);
    try {
      const  parsedValue = value && JSON.parse(value);
      return parsedValue ? parsedValue : null;
    } catch(error) {
      return null;
    }
  };

  const removeStoredValueByKey = (key: string) => {
    localStorage.removeItem(key);
  };

  return { saveValueToLocalStorage, getValueFromLocalStorage, removeStoredValueByKey };
};

export default useLocalStorage;
