import styled from 'styled-components';
import ArrowIcon from 'assets/arrow-icon';
import InfoIcon from 'assets/info-icon';
import { Body2 } from 'components/typography';
import { spacing, radii, breakpoint } from 'styles/helpers';
import { iconSizing, rotate } from 'styles/mixins';

const LARGE_CARD_DIMENSIONS = '102px';
const DEFAULT_CARD_DIMENSIONS = '82px';

export const Card = styled.button<{
  $large?: boolean;
  selected?: boolean;
}>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.highlight : theme.colors.background};
  border: ${({ theme, selected }) =>
    selected ? `1.5px solid ${theme.colors.primary}` : theme.border.inactive};
  border-radius: ${radii.s};
  color: ${({ theme, selected }) =>
    selected ? `${theme.colors.primary}` : `${theme.colors.black}`};
  display: flex;
  height: ${({ $large }) =>
    $large ? LARGE_CARD_DIMENSIONS : DEFAULT_CARD_DIMENSIONS};
  text-align: start;
  width: 100%;

  ${breakpoint.mobile} {
    height: ${DEFAULT_CARD_DIMENSIONS};
  }
`;

export const ImageContainer = styled.div<{ $iconBackground?: string }>`
  aspect-ratio: 1/1;
  background-color: ${({ theme, $iconBackground }) =>
    $iconBackground ?? theme.colors.inactive};
  border-radius: ${radii.xs} 0 0 ${radii.xs};
  height: 100%;
`;

export const IconWrapper = styled.div<{ $iconSvg?: boolean }>`
  align-items: center;
  border-radius: ${radii.xs} 0 0 ${radii.xs};
  display: flex;
  height: 100%;
  justify-content: center;
  padding: ${({ $iconSvg }) =>
    $iconSvg ? `${spacing.s} ${spacing.xs}` : spacing.m};

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const StyledImg = styled.img`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${radii.xs} 0 0 ${radii.xs};
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const StyledIconImg = styled.img`
  object-fit: scale-down;
  width: 100%;
`;

export const LargeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['2xs']};
  height: 100%;
  justify-content: center;
  padding: 26px ${spacing.l};

  ${breakpoint.mobile} {
    gap: 0;
    padding: ${spacing['2xs']} ${spacing.s};
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: ${spacing.xl} ${spacing.l};
  width: 100%;
`;

export const LargeCardTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing.s};

  ${breakpoint.mobile} {
    gap: ${spacing['2xs']};
  }
`;

export const CardTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing['3xs']};

  ${breakpoint.mobile} {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  ${rotate(270)};
  ${iconSizing.s};
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const MoreInfoButton = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 18px;
`;

export const Text = styled(Body2)`
  color: ${({ theme }) => theme.colors.textSecondary};
`;
