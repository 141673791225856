import { styled } from 'styled-components';
import ScreenTitle from 'components/screen-title';
import { spacing, breakpoint } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const Title = styled(ScreenTitle)`
  margin-top: ${spacing.xl};

  ${breakpoint.mobile} {
    margin-top: ${spacing.s};
  }
`;

export const CardsWrapper = styled.div`
  ${grid.threeColumn};
  margin: ${spacing.s} 0 ${spacing['2xl']};

  ${breakpoint.mobile} {
    margin: ${spacing.s} 0;
  }
`;
