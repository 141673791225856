import * as Yup from 'yup';
import useGetConcreteOrientations from '../api/useGetConcreteOrientations';
import { ConcreteOrientationName } from '../types';

const MIN_DIMENSIONS_PRODUCT = 67500;

const useValidation = () => {
  const { concreteOrientations } = useGetConcreteOrientations();
  const beamOrientationId = concreteOrientations?.find(
    (orientation) => orientation.name === ConcreteOrientationName.Beam
  )?.id;
  const columnOrientationId = concreteOrientations?.find(
    (orientation) => orientation.name === ConcreteOrientationName.Column
  )?.id;

  const requiredFieldsValidationSchema = Yup.object().shape({
    orientation: Yup.string().required(),
    depth: Yup.number().when('orientation', {
      is: (value: number) =>
        Number(value) === beamOrientationId ||
        Number(value) === columnOrientationId,
      then: (schema) => schema.required(),
    }),
    width: Yup.number().when('orientation', {
      is: (value: number) =>
        Number(value) === beamOrientationId ||
        Number(value) === columnOrientationId,
      then: (schema) => schema.required(),
    }),
    requiredAxis: Yup.number().required(),
  });

  const validationSchema = requiredFieldsValidationSchema.shape({
    width: Yup.number()
      .min(150, 'concrete.errors.width')
      .test({
        name: 'dimensions-product-validation',
        message: 'concrete.errors.dimensionsProduct',
        test: (value, width) => {
          const depthValue = width.parent.depth;

          if (depthValue === 0 || !value) {
            return true;
          }

          const result = value * depthValue;

          return result >= MIN_DIMENSIONS_PRODUCT;
        },
      }),
    depth: Yup.number()
      .moreThan(0, 'concrete.errors.depth')
      .test({
        name: 'dimensions-product-validation',
        message: 'concrete.errors.dimensionsProduct',
        test: (value, depth) => {
          const widthValue = depth.parent.width;

          if (widthValue < 150 || !value) {
            return true;
          }

          const result = value * widthValue;

          return result >= MIN_DIMENSIONS_PRODUCT;
        },
      }),
    requiredAxis: Yup.number()
      .moreThan(0, 'concrete.errors.requiredAxis')
      .test({
        name: 'axis-comparison-validation',
        message: 'concrete.errors.axisComparison',
        test: (value, requiredAxis) => {
          const currentAxisValue = requiredAxis.parent.currentAxis ?? 0;
          if (value) {
            return currentAxisValue < value;
          }

          return true;
        },
      }),
  });

  return { validationSchema, requiredFieldsValidationSchema };
};

export default useValidation;
