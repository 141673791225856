import styled from 'styled-components';
import InfoIcon from 'assets/info-icon';
import { Body3, Heading7 } from 'components/typography';
import { breakpoint, spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

export const OptionalText = styled(Body3)`
  color: ${({ theme }) => theme.colors.textDisabled};
`;

export const Label = styled(Heading7)`
  ${breakpoint.mobile} {
    white-space: normal;
  }
`;

export const labelStyles = {
  alignItems: 'center',
  color: theme.colors.black,
  display: 'flex',
  gap: spacing['3xs'],
  marginBottom: spacing['2xs'],
  minHeight: '18px',
  overflow: 'visible',
  pointerEvents: 'auto',
  position: 'unset',
  transform: 'none',
  WebkitTransform: 'none',

  '&.Mui-focused': {
    color: theme.colors.black,
  },

  ['@media (max-width:768px)']: {
    flexWrap: 'wrap',
  },
};

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 18px;
`;
