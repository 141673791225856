export const steelInputsSortOrder = [
  'profileTypeName',
  'profileSubTypeName',
  'sectionFactor',
  'crossSectionalShapeName',
  'orientationName',
  'sidesToProtect',
  'buildingFunction',
  'humidity',
  'temperature',
  'fireRatingValue',
  'criticalTemperature',
];
