import { Tabs as TabsMui, Tab as TabMui, Box } from '@mui/material';
import { Body3 } from 'components/typography';
import { ProgressStep } from 'shared/types';
import { boxStyles, tabStyles, tabsStyles } from './styles';
import { StepItem } from './types';

interface StepsProps {
  items: StepItem[];
  active: ProgressStep;
}

const Steps = ({ items, active }: StepsProps) => {
  const activeStepIndex = items.findIndex((item) => item.value === active);

  return (
    <Box sx={boxStyles}>
      <TabsMui
        value={active}
        sx={tabsStyles}
        variant="scrollable"
        scrollButtons={false}
      >
        {items.map((item, index) => (
          <TabMui
            sx={tabStyles}
            key={item.value}
            disableRipple
            label={<Body3>{item.label}</Body3>}
            disabled={index > activeStepIndex}
            tabIndex={-1}
            value={item.value}
          />
        ))}
      </TabsMui>
    </Box>
  );
};

export default Steps;
