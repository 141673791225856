import useQuery from 'shared/hooks/useQuery';
import { SteelProfileShape } from '../types';

const useGetSteelProfileShapes = () => {
  const { data, error, isLoading, isError } = useQuery<SteelProfileShape[]>(
    'steel-profile-shapes',
    'v1/steel-elements/profile-shapes'
  );

  return {
    steelProfileShapes: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelProfileShapes;
