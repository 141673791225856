import useLocale from 'shared/hooks/useLocale';
import useQuery from 'shared/hooks/useQuery';
import { SteelProfileTypeSubtypeItem } from '../types';

const useGetSteelProfileTypesSubtypes = (profileShapeId?: number) => {
  const locale = useLocale();
  const params = [locale, profileShapeId];

  const { data, error, isLoading, isError } = useQuery<
    SteelProfileTypeSubtypeItem[]
  >(
    'steel-profile-types-subtypes',
    `v1/steel-elements/profile-shapes/${locale}/${profileShapeId}/profile-types-subtypes`,
    params,
    { enabled: !!profileShapeId }
  );

  return {
    steelProfileTypesSubtypes: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelProfileTypesSubtypes;
