import { useTranslation } from 'react-i18next';
import { View, Image, Text } from '@react-pdf/renderer';
import PromatLogo from 'assets/promat-logo.png';
import styles from './styles';

const HeaderPdf = () => {
  const { t } = useTranslation();

  return (
    <View fixed>
      <Text style={styles.docName}>{t('pdf.title')}</Text>
      <View style={styles.header}>
        <Image src={PromatLogo} style={styles.headerLogo} />
      </View>
    </View>
  );
};

export default HeaderPdf;
