import { ReactNode, useRef } from 'react';
import { Heading7 } from 'components/typography';
import { StyledButton, StyledLink } from './styles';
import { ButtonVariant } from './types';

interface ButtonProps {
  content: string;
  onClick?: () => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  ariaLabel?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: ButtonVariant;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  openNewTab?: boolean;
}

const Button = ({
  content,
  onClick,
  startIcon,
  endIcon,
  ariaLabel,
  disabled,
  fullWidth,
  variant = ButtonVariant.outlined,
  href,
  type = 'button',
  openNewTab,
}: ButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    onClick?.();
    buttonRef.current?.blur();
  };

  const buttonContent = (
    <>
      {startIcon}
      <Heading7>{content}</Heading7>
      {endIcon}
    </>
  );

  if (href) {
    return (
      <StyledLink
        $fullWidth={fullWidth}
        variant={variant}
        aria-label={ariaLabel}
        onClick={onClick}
        to={href}
        target={openNewTab ? '_blank' : '_self'}
        rel={openNewTab ? 'noopener noreferrer' : undefined}
      >
        {buttonContent}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      $fullWidth={fullWidth}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={handleClick}
      variant={variant}
      type={type}
      ref={buttonRef}
    >
      {buttonContent}
    </StyledButton>
  );
};

export default Button;
