import { SvgProps } from './types';

const QuestionMarkIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4076_19475"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4076_19475)">
      <path
        d="M12 22L11.75 19H11.5C9.13333 19 7.125 18.175 5.475 16.525C3.825 14.875 3 12.8667 3 10.5C3 8.13333 3.825 6.125 5.475 4.475C7.125 2.825 9.13333 2 11.5 2C12.6833 2 13.7873 2.22067 14.812 2.662C15.8373 3.104 16.7377 3.71233 17.513 4.487C18.2877 5.26233 18.8957 6.16233 19.337 7.187C19.779 8.21233 20 9.31667 20 10.5C20 13.0333 19.2417 15.304 17.725 17.312C16.2083 19.3207 14.3 20.8833 12 22ZM11.475 15.975C11.7583 15.975 12 15.875 12.2 15.675C12.4 15.475 12.5 15.2333 12.5 14.95C12.5 14.6667 12.4 14.425 12.2 14.225C12 14.025 11.7583 13.925 11.475 13.925C11.1917 13.925 10.95 14.025 10.75 14.225C10.55 14.425 10.45 14.6667 10.45 14.95C10.45 15.2333 10.55 15.475 10.75 15.675C10.95 15.875 11.1917 15.975 11.475 15.975ZM10.75 12.8H12.25C12.25 12.3 12.3 11.95 12.4 11.75C12.5 11.55 12.8167 11.1833 13.35 10.65C13.65 10.35 13.9 10.025 14.1 9.675C14.3 9.325 14.4 8.95 14.4 8.55C14.4 7.7 14.1127 7.06233 13.538 6.637C12.9627 6.21233 12.2833 6 11.5 6C10.7667 6 10.15 6.204 9.65 6.612C9.15 7.02067 8.8 7.51667 8.6 8.1L10 8.65C10.0833 8.36667 10.2417 8.08733 10.475 7.812C10.7083 7.53733 11.05 7.4 11.5 7.4C11.95 7.4 12.2877 7.525 12.513 7.775C12.7377 8.025 12.85 8.3 12.85 8.6C12.85 8.88333 12.7667 9.13733 12.6 9.362C12.4333 9.58733 12.2333 9.81667 12 10.05C11.4167 10.55 11.0627 10.9457 10.938 11.237C10.8127 11.529 10.75 12.05 10.75 12.8ZM14 17V18.35C15.1833 17.35 16.1457 16.1793 16.887 14.838C17.629 13.496 18 12.05 18 10.5C18 8.68333 17.371 7.14567 16.113 5.887C14.8543 4.629 13.3167 4 11.5 4C9.68333 4 8.146 4.629 6.888 5.887C5.62933 7.14567 5 8.68333 5 10.5C5 12.3167 5.62933 13.8543 6.888 15.113C8.146 16.371 9.68333 17 11.5 17H14Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default QuestionMarkIcon;
