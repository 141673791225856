import styled from 'styled-components';
import { Heading6 } from 'components/typography';
import { breakpoint, spacing } from 'styles/helpers';

export const HomeWrapper = styled.div`
  ${breakpoint.mobile} {
    padding: ${spacing.xl} ${spacing.m};
  }
`;

export const StyledSubheading = styled(Heading6)`
  color: ${({ theme }) => theme.colors.subheading};
  margin-top: ${spacing['2xs']};

  ${breakpoint.mobile} {
    margin: 0;
  }
`;

export const Container = styled.div`
  margin-top: ${spacing['4xl']};

  ${breakpoint.mobile} {
    margin-top: ${spacing.xl};
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  gap: ${spacing.s};
  grid-template-columns: repeat(2, 1fr);
  margin-top: ${spacing.s};

  ${breakpoint.mobile} {
    grid-template-columns: 1fr;
  }
`;
