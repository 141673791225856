import { ReactNode } from 'react';
import { Modal } from '@mui/material';
import { StyledModalWrapper } from './styles';
import useIsMobile from 'shared/hooks/useIsMobile';

type ModalComponentProps = {
  header: ReactNode;
  content: ReactNode;
  isOpen: boolean;
};

const ModalComponent = ({
  header, content, isOpen
}: ModalComponentProps) => {

  const isMobile = useIsMobile();

  return (
    <Modal open={isOpen}>
      <StyledModalWrapper $isMobileView={isMobile}>
        {header}
        {content}
      </StyledModalWrapper>
    </Modal>
  );
};
export default ModalComponent;
