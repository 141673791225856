import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetCountries from 'api/useGetCountries';
import useGetLanguages from 'api/useGetLanguages';
import { DEFAULT_LANGUAGE, ROUTES } from 'shared/constants';
import { isLocaleAvailable } from 'shared/utils/isLocaleAvailable';
import { selectIsQueryError } from 'store/calculator/selectors/calculator';
import { useAppSelector } from 'store/hooks';

const isRouteAvailable = (path: string) => Object.values(ROUTES).includes(path);

const useBaseUrl = () => {
  const { i18n } = useTranslation();
  const { countries } = useGetCountries();
  const { languages } = useGetLanguages();
  const isQueryError = useAppSelector(selectIsQueryError);
  const [isLoading, setIsLoading] = useState(true);
  const pathname = window.location.pathname;
  const firstPathEl = pathname.split('/')[1];
  const langInBrowser = window.navigator.language;
  const languageChangeEvent = new Event('onLanguageChange');

  const getDefaultBaseURL = () => {
    if (
      countries &&
      languages &&
      isLocaleAvailable(i18n.language, languages, countries) &&
      i18n.language !== DEFAULT_LANGUAGE
    ) {
      return '/' + i18n.language;
    }
    return '';
  };

  const [baseURL, setBaseURL] = useState(getDefaultBaseURL());

  useEffect(() => {
    if (isQueryError) {
      document.dispatchEvent(languageChangeEvent);
      setIsLoading(false);
    }
  }, [isQueryError]);

  useEffect(() => {
    let newLang = DEFAULT_LANGUAGE;
    let newBaseURL = '';

    if (!countries || !languages) {
      setIsLoading(true);
      return;
    }

    // language/locale is present in the url
    if (firstPathEl && isLocaleAvailable(firstPathEl, languages, countries)) {
      newLang = firstPathEl;
      newBaseURL = '/' + firstPathEl;
    } else if (
      // language/locale taken from browser
      langInBrowser !== DEFAULT_LANGUAGE &&
      isLocaleAvailable(langInBrowser, languages, countries) &&
      isRouteAvailable(pathname)
    ) {
      newLang = langInBrowser;
      newBaseURL = '/' + langInBrowser;
      window.history.replaceState('', '', `${newLang}${pathname}`);
      document.dispatchEvent(languageChangeEvent);
    }

    document.documentElement.lang = newLang;

    if (newLang === DEFAULT_LANGUAGE) {
      document.dispatchEvent(languageChangeEvent);
    }

    i18n.changeLanguage(newLang);
    setBaseURL(newBaseURL);
    setIsLoading(false);
  }, [firstPathEl, langInBrowser, countries, languages]);

  return {
    baseURL,
    isLoading,
  };
};

export default useBaseUrl;
