import { SelectItem } from 'components/select/types';
import { SteelProfileTypeSubtypeItem } from 'pages/steel/types';

export const getProfileTypeSelectOptions = (
  typesSubtypesData?: SteelProfileTypeSubtypeItem[]
): SelectItem[] | undefined =>
  typesSubtypesData?.map((item) => ({
    id: item.id,
    name: item.name,
  }));

export const getProfileSubtypeSelectOptions = (
  profileType: string,
  typesSubtypesData?: SteelProfileTypeSubtypeItem[]
): SelectItem[] | undefined =>
  typesSubtypesData?.find((item) => item.id === Number(profileType))?.subtypes;
