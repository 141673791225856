import { useTranslation } from 'react-i18next';
import Table from 'components/table';
import { CalculationResultData } from 'shared/types';
import mapCalculationResult from 'shared/utils/mapCalculationResult';

interface CalculationDetailsProps {
  calculationResult?: CalculationResultData;
}

const CalculationDetails = ({ calculationResult }: CalculationDetailsProps) => {
  const { t } = useTranslation();
  const calculationDetails = mapCalculationResult(t, calculationResult);

  return (
    <Table
      rowsData={calculationDetails}
      title={t('productDetails.calculationResult')}
      tooltipText={t('productDetails.calculationResultInfo') ?? ''}
    />
  );
};

export default CalculationDetails;
