export type EnvironmentValues = {
  buildingFunction: string;
  areaToProtect: string;
};

export enum EnvironmentHumidity {
  Dry = 'Dry',
  HumidConstructionDryService = 'HumidConstructionDryService',
  HighHumidity = 'HighHumidity',
}

export enum EnvironmentTemperature {
  NeverBelowZero = 'NeverBelowZero',
  OccasionallyBelowZero = 'OccasionallyBelowZero',
}
