import styled from 'styled-components';
import { theme } from 'styles/theme';

export const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

export const tooltipStyles = (maxWidth?: number) => {
  return {
    bgcolor: theme.colors.white,
    border: theme.border.default,
    boxShadow: '0 4px 108px rgba(0 0 0 / 5%)',
    color: theme.colors.textPrimary,
    maxWidth: maxWidth ?? '100%',
    textAlign: 'center',
  };
};

export const tooltipArrowStyles = {
  color: theme.colors.white,

  '&::before': {
    border: theme.border.default,
    marginTop: '-1px',
  },
};
