import { ReactNode } from 'react';
import { Wrapper } from './styles';

interface FormWrapperProps {
  children: ReactNode;
}

const FormWrapper = ({ children }: FormWrapperProps) => (
  <Wrapper>{children}</Wrapper>
);

export default FormWrapper;
