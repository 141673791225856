import { useTranslation } from 'react-i18next';
import { EnvironmentTemperature } from 'features/environment/types';
import EnvironmentCardsSection from '../environment-cards-section';
import { TEMPERATURE_ICONS } from './constants';

const TemperatureCards = () => {
  const { t } = useTranslation();

  const temperatureValues = Object.values(EnvironmentTemperature);

  return (
    <EnvironmentCardsSection
      label={t('environment.temperature')}
      sectionValues={temperatureValues}
      name="temperature"
      icons={TEMPERATURE_ICONS}
    />
  );
};

export default TemperatureCards;
