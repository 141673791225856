import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import FormikSelect from 'components/select/formik-select';
import { SelectItem } from 'components/select/types';
import {
  SteelProfileTypeSubtypeItem,
  SteelStructuralElementsValues,
} from 'pages/steel/types';
import {
  getProfileSubtypeSelectOptions,
  getProfileTypeSelectOptions,
} from './logic';

interface StandardProfileInputsProps {
  values: SteelStructuralElementsValues;
  steelProfileTypesSubtypes?: SteelProfileTypeSubtypeItem[];
}

const StandardProfileInputs = ({
  values,
  steelProfileTypesSubtypes,
}: StandardProfileInputsProps) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [profileSubtypes, setProfileSubtypes] = useState<SelectItem[]>();

  const profileTypes = getProfileTypeSelectOptions(steelProfileTypesSubtypes);

  useEffect(() => {
    if (
      profileTypes?.length === 1 &&
      values.profileType !== String(profileTypes[0].id)
    )
      setFieldValue('profileType', String(profileTypes[0].id));

    const profileSubtypes = getProfileSubtypeSelectOptions(
      values.profileType,
      steelProfileTypesSubtypes
    );

    setProfileSubtypes(profileSubtypes);
  }, [profileTypes, values.profileType]);

  return (
    <>
      {profileTypes && (
        <FormikSelect
          name="profileType"
          label={t('steel.shape.type') ?? ''}
          emptyValue={t('steel.shape.typeEmpty') ?? ''}
          items={profileTypes}
          translationKey={
            profileTypes.length === 1
              ? 'steel.shape.hollowShapesPlaceholders'
              : undefined
          }
        />
      )}
      {profileSubtypes && (
        <FormikSelect
          name="profileSubtype"
          label={t('steel.shape.subtype') ?? ''}
          emptyValue={t('steel.shape.subtypeEmpty') ?? ''}
          items={profileSubtypes}
        />
      )}
    </>
  );
};

export default StandardProfileInputs;
