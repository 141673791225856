import { Dialog } from '@mui/material';
import {
  CloseButton,
  ModalContentWrapper,
  StyledCloseIcon,
  Text,
  Title,
  muiPaperStyles,
} from './styles';

interface InfoModalProps {
  open: boolean;
  title: string;
  text: string;
  onClose: () => void;
}

const InfoModal = ({ open, title, text, onClose }: InfoModalProps) => (
  <Dialog
    open={open}
    hideBackdrop
    PaperProps={{
      sx: muiPaperStyles,
    }}
  >
    <CloseButton onClick={onClose}>
      <StyledCloseIcon />
    </CloseButton>
    <ModalContentWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </ModalContentWrapper>
  </Dialog>
);

export default InfoModal;
