import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Disclaimer from 'components/disclaimer-section';
import Loader from 'components/loader';
import ResultsGrid from 'features/results-grid';
import ResultsRestartButton from 'features/results-restart-button';
import TopActions from 'features/top-actions';
import { CONCRETE_ROUTES, HS_FORM_CONTACT_SUPPORT_FORM_ID, HS_FORM_PORTAL, HS_FORM_REGION } from 'shared/constants';
import useIsMobile from 'shared/hooks/useIsMobile';
import useRestartCalculator from 'shared/hooks/useRestartCalculator';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import {
  CalculationInputParamsData,
  ConcreteCalculationInputParamsData,
} from 'shared/types';
import useGetConcreteCalculationsList from '../api/useGetConcreteCalculationsList';
import { MobileWrapper, Title } from './styles';
import ModalHeader from '../../../components/modal/modal-header';
import HubspotForm from '../../../components/modal/modal-form';
import ModalComponent from '../../../components/modal';

const ConcreteResults = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const restartCalculator = useRestartCalculator();
  const [, setinputParametersFromResults] = useSessionStorage(
    SESSION_STORAGE_KEYS.inputParametersFromResults
  );
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const {
    productsWithCalculations,
    getConcreteCalculationsList,
    isLoading: isCalculationsListLoading,
  } = useGetConcreteCalculationsList();

  const navigateBack = () => navigate(CONCRETE_ROUTES.fireRating);

  const viewProductDetails = (inputParams: CalculationInputParamsData) => {
    const concreteInputParams =
      inputParams as ConcreteCalculationInputParamsData;
    setinputParametersFromResults({
      productId: concreteInputParams.productId,
      category: concreteInputParams.category,
      fireRatingId: concreteInputParams.fireRatingId,
      orientationId: concreteInputParams.orientationId,
      totalAreaToProtectM2: concreteInputParams.totalAreaToProtectM2,
      requiredAxisDistance: concreteInputParams.requiredAxisDistance,
      currentAxisDistance: concreteInputParams.currentAxisDistance,
    });
    navigate(
      `${CONCRETE_ROUTES.productDetails}/${concreteInputParams.productId}`
    );
  };

  useEffect(() => {
    getConcreteCalculationsList();
    setIsInitialLoading(false);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState({
    title: '',
    portalId: '',
    formId: '',
    region: '',
  });

  const onContactSupportModalOpen = () => {
    setModalData({
      title: t('modal.contactSupport'),
      portalId: HS_FORM_PORTAL,
      formId: HS_FORM_CONTACT_SUPPORT_FORM_ID,
      region: HS_FORM_REGION,
    });

    setIsModalOpen(true);
  };

  return isInitialLoading || isCalculationsListLoading ? (
    <Loader />
  ) : (
    <div>
      <ModalComponent
        header={
          <ModalHeader
            title={modalData.title}
            handleClose={() => {
              setIsModalOpen(false);
            }}
          />
        }
        isOpen={isModalOpen}
        content={
          <HubspotForm
            portalId={modalData.portalId}
            formId={modalData.formId}
            region={modalData.region}
            onFormSubmitted={() =>
              setModalData((prevState) => ({ ...prevState, title: '' }))
            }
            onDoneClick={() => {
              setIsModalOpen(false);
            }}
          />
        }
      />
      <TopActions
        title={t('results.title') ?? ''}
        onBackClick={navigateBack}
        rightAction={<ResultsRestartButton onClick={restartCalculator} />}
      />
      {isMobile ? (
        <MobileWrapper>
          <ResultsGrid
            products={productsWithCalculations}
            onViewProductDetails={viewProductDetails}
            onContactSupportModalOpen={onContactSupportModalOpen}
          />
          <Disclaimer text={t('results.legal')} />
        </MobileWrapper>
      ) : (
        <>
          <Title title={t('results.title')} />
          <ResultsGrid
            products={productsWithCalculations}
            onViewProductDetails={viewProductDetails}
            onContactSupportModalOpen={onContactSupportModalOpen}
          />
          <Disclaimer text={t('results.legal')} />
        </>
      )}
    </div>
  );
};

export default ConcreteResults;
