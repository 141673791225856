import { useState } from 'react';
import { Swiper as SwiperType } from 'swiper';
import { Pagination, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import useIsMobile from 'shared/hooks/useIsMobile';
import { ImageRendition } from 'shared/types';
import getProductImage from 'shared/utils/getProductImage';
import { SwiperWrapper, ThumbsWrapper } from './styles';

interface CarouselProps {
  items?: string[];
}

const Carousel = ({ items }: CarouselProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const isMobile = useIsMobile();

  return (
    <>
      <SwiperWrapper>
        <Swiper
          spaceBetween={10}
          modules={[Pagination, Thumbs]}
          pagination={
            isMobile && { clickable: true, el: '.swiper-custom-pagination' }
          }
          thumbs={{
            swiper:
              !isMobile && thumbsSwiper && !thumbsSwiper.destroyed
                ? thumbsSwiper
                : null,
          }}
        >
          {items &&
            items.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={getProductImage(item, ImageRendition.Original)} />
              </SwiperSlide>
            ))}
        </Swiper>
        {isMobile && <div className="swiper-custom-pagination" />}
      </SwiperWrapper>

      {!isMobile && (
        <ThumbsWrapper>
          <Swiper
            onSwiper={setThumbsSwiper}
            watchSlidesProgress
            slidesPerView="auto"
            modules={[Pagination, Thumbs]}
          >
            {items &&
              items.map((item, index) => (
                <SwiperSlide key={index}>
                  <img src={getProductImage(item, ImageRendition.Thumbnail)} />
                </SwiperSlide>
              ))}
          </Swiper>
        </ThumbsWrapper>
      )}
    </>
  );
};
export default Carousel;
