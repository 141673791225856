import { SvgProps } from './types';

const EmailIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.4801 20.2049C58.4492 20.1118 58.4051 20.0236 58.3491 19.943C58.2957 19.8708 58.2332 19.806 58.1631 19.75H58.1286C58.0523 19.6952 57.9688 19.6511 57.8805 19.619H57.8253C57.7295 19.5884 57.6296 19.5722 57.529 19.5708H17.7488C17.6482 19.5722 17.5483 19.5884 17.4524 19.619H17.3973C17.309 19.6511 17.2255 19.6952 17.1492 19.75H17.1147C17.0446 19.806 16.982 19.8708 16.9286 19.943C16.8727 20.0236 16.8286 20.1118 16.7977 20.2049C16.7721 20.2922 16.7582 20.3826 16.7563 20.4736V46.9662C16.7563 47.2404 16.8653 47.5033 17.0591 47.6972C17.253 47.8911 17.516 48 17.7901 48H57.4876C57.7618 48 58.0248 47.8911 58.2186 47.6972C58.4125 47.5033 58.5214 47.2404 58.5214 46.9662V20.4736C58.5197 20.3826 58.5058 20.2922 58.4801 20.2049ZM54.0761 21.5074L37.6182 32.4794L21.1672 21.5074H54.0761ZM18.7826 45.9324V22.4034L37.0462 34.5814C37.2157 34.694 37.4147 34.7541 37.6182 34.7541C37.8217 34.7541 38.0207 34.694 38.1902 34.5814L56.4539 22.4034V45.9324H18.7826Z"
      fill="#0BA1E2"
    />
    <path
      d="M13.5794 19.4399H5.79844C5.52426 19.4399 5.26131 19.5489 5.06744 19.7427C4.87357 19.9366 4.76465 20.1996 4.76465 20.4737C4.76465 20.7479 4.87357 21.0109 5.06744 21.2047C5.26131 21.3986 5.52426 21.5075 5.79844 21.5075H13.5794C13.8536 21.5075 14.1166 21.3986 14.3104 21.2047C14.5043 21.0109 14.6132 20.7479 14.6132 20.4737C14.6132 20.1996 14.5043 19.9366 14.3104 19.7427C14.1166 19.5489 13.8536 19.4399 13.5794 19.4399Z"
      fill="#CBDFEE"
    />
    <path
      d="M13.5794 28.2686H5.79844C5.52426 28.2686 5.26131 28.3775 5.06744 28.5713C4.87357 28.7652 4.76465 29.0282 4.76465 29.3023C4.76465 29.5765 4.87357 29.8395 5.06744 30.0333C5.26131 30.2272 5.52426 30.3361 5.79844 30.3361H13.5794C13.8536 30.3361 14.1166 30.2272 14.3104 30.0333C14.5043 29.8395 14.6132 29.5765 14.6132 29.3023C14.6132 29.0282 14.5043 28.7652 14.3104 28.5713C14.1166 28.3775 13.8536 28.2686 13.5794 28.2686Z"
      fill="#CBDFEE"
    />
    <path
      d="M13.5794 37.104H5.79844C5.52426 37.104 5.26131 37.2129 5.06744 37.4068C4.87357 37.6007 4.76465 37.8636 4.76465 38.1378C4.76465 38.412 4.87357 38.6749 5.06744 38.8688C5.26131 39.0627 5.52426 39.1716 5.79844 39.1716H13.5794C13.8536 39.1716 14.1166 39.0627 14.3104 38.8688C14.5043 38.6749 14.6132 38.412 14.6132 38.1378C14.6132 37.8636 14.5043 37.6007 14.3104 37.4068C14.1166 37.2129 13.8536 37.104 13.5794 37.104Z"
      fill="#CBDFEE"
    />
    <path
      d="M13.5794 45.9326H5.79844C5.52426 45.9326 5.26131 46.0415 5.06744 46.2354C4.87357 46.4293 4.76465 46.6922 4.76465 46.9664C4.76465 47.2406 4.87357 47.5035 5.06744 47.6974C5.26131 47.8913 5.52426 48.0002 5.79844 48.0002H13.5794C13.8536 48.0002 14.1166 47.8913 14.3104 47.6974C14.5043 47.5035 14.6132 47.2406 14.6132 46.9664C14.6132 46.6922 14.5043 46.4293 14.3104 46.2354C14.1166 46.0415 13.8536 45.9326 13.5794 45.9326Z"
      fill="#CBDFEE"
    />
  </svg>
);

export default EmailIcon;
