import { DescriptionParagraph } from './styles';

export const markdownComponents = {
  a: ({ children, href }: { children: string; href: string }) => (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  ),
  p: ({ children }: { children: string }) => (
    <DescriptionParagraph>{children}</DescriptionParagraph>
  ),
};
