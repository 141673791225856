import { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';
import styles from './styles';

interface Props {
  text: string;
}

const DisclaimerPdf: FC<Props> = ({ text }) => {
  const paragraphs = text.split('\n\n');

  return (
    <View style={styles.disclaimerWrapper}>
      {paragraphs.map((paragraph, index) => (
        <Text key={index} style={styles.disclaimerParagraph}>
          {paragraph}
        </Text>
      ))}
    </View>
  );
};

export default DisclaimerPdf;
