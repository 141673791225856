import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/error-message';
import FormikSelectCard from 'components/select-card/formik-select-card';
import {
  SteelOrientation,
  SteelStructuralElementsValues,
} from 'pages/steel/types';
import { ORIENTATION_ICONS } from './constants';
import { CardsWrapper, Title } from './styles';

interface OrientationsProps {
  values: SteelStructuralElementsValues;
  orientations: SteelOrientation[];
  error?: string;
  touched?: boolean;
}

const Orientations = ({
  values,
  orientations,
  error,
  touched,
}: OrientationsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Title title={t('steel.orientation.title')} />
      {touched && error && <ErrorMessage error={error} />}
      <CardsWrapper>
        {orientations.map((orientation) => (
          <FormikSelectCard
            name="orientation"
            key={orientation.id}
            title={t(`steel.orientation.${orientation.name}`)}
            selected={orientation.id === values.orientation}
            value={orientation.id}
            iconSvg={ORIENTATION_ICONS[orientation.name]}
          />
        ))}
      </CardsWrapper>
    </>
  );
};

export default Orientations;
