import { SyntheticEvent, useState } from 'react';
import { Tabs as TabsMui, Tab as TabMui } from '@mui/material';
import { Body2 } from 'components/typography';
import { TabItem } from 'shared/types';
import { TabLabel, tabStyles, fullWidthTabsStyles, tabsStyles } from './styles';

interface TabsProps {
  items: TabItem[];
  onChange?: (value: string) => void;
  fullWidth?: boolean;
}

const Tabs = ({ items, onChange, fullWidth = false }: TabsProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChange?.(items[newValue].value);
  };

  return (
    <TabsMui
      value={value}
      onChange={handleChange}
      sx={fullWidth ? fullWidthTabsStyles : tabsStyles}
      variant={fullWidth ? 'fullWidth' : 'standard'}
    >
      {items.map((item) => (
        <TabMui
          sx={tabStyles}
          key={item.value}
          disableRipple
          label={
            fullWidth ? (
              <TabLabel>{item.label}</TabLabel>
            ) : (
              <Body2>{item.label}</Body2>
            )
          }
          tabIndex={0}
        />
      ))}
    </TabsMui>
  );
};

export default Tabs;
