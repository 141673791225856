import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip as TooltipMui } from '@mui/material';
import parse from 'html-react-parser';
import { Body4 } from 'components/typography';
import { InnerWrapper, tooltipArrowStyles, tooltipStyles } from './styles';

interface TooltipProps {
  children: ReactNode;
  text: string;
  maxWidth?: number;
}

const Tooltip = ({ children, text, maxWidth }: TooltipProps) => {
  const { t } = useTranslation();

  return (
    <TooltipMui
      arrow
      title={<Body4>{parse(text)}</Body4>}
      placement="top"
      componentsProps={{
        arrow: {
          sx: tooltipArrowStyles,
        },
        tooltip: {
          sx: tooltipStyles(maxWidth),
        },
      }}
    >
      <InnerWrapper
        tabIndex={0}
        aria-label={t('common.moreInformation') ?? 'More information'}
      >
        {children}
      </InnerWrapper>
    </TooltipMui>
  );
};

export default Tooltip;
