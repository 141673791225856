import { SvgProps } from './types';

const DownloadIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4190_5102"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#868686" />
    </mask>
    <g mask="url(#mask0_4190_5102)">
      <mask
        id="mask1_4190_5102"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="22"
        height="22"
      >
        <rect x="1.5" y="1.5" width="21" height="21" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask1_4190_5102)">
        <path
          d="M12 15.5L7.625 11.125L8.85 9.85625L11.125 12.1312V5H12.875V12.1312L15.15 9.85625L16.375 11.125L12 15.5ZM6.75 19C6.26875 19 5.85692 18.8288 5.5145 18.4864C5.1715 18.1434 5 17.7312 5 17.25V14.625H6.75V17.25H17.25V14.625H19V17.25C19 17.7312 18.8288 18.1434 18.4864 18.4864C18.1434 18.8288 17.7312 19 17.25 19H6.75Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default DownloadIcon;
