import { CUSTOM_PROFILE } from 'pages/steel/constants';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import useSessionStorage from './useSessionStorage';

const useSteelHasProtectedSides = () => {
  const [structuralElements] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelStructuralElements
  );

  return structuralElements
    ? structuralElements.profileShape !== CUSTOM_PROFILE
    : true;
};

export default useSteelHasProtectedSides;
