import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ErrorMessage from 'components/error-message';
import FormikInput from 'components/input/formik-input';
import { ConcreteStructuralElementsValues } from 'pages/concrete/types';
import { InputsWrapper, Wrapper } from './styles';

const DimensionsInputs = () => {
  const { t } = useTranslation();
  const { errors, setFieldError } =
    useFormikContext<ConcreteStructuralElementsValues>();

  const isDimensionsProductError =
    errors.width === 'concrete.errors.dimensionsProduct' &&
    errors.depth === 'concrete.errors.dimensionsProduct';

  useEffect(() => {
    if (errors.width === 'concrete.errors.dimensionsProduct' && !errors.depth) {
      setFieldError('width', '');
    }

    if (errors.depth === 'concrete.errors.dimensionsProduct' && !errors.width) {
      setFieldError('depth', '');
    }
  }, [errors]);

  return (
    <Wrapper>
      <InputsWrapper>
        <FormikInput
          name="depth"
          label={t('concrete.structuralElements.depth') ?? ''}
          placeholder={
            t('concrete.structuralElements.depthAndWithPlaceholder') ?? ''
          }
          numberInput
          decimal
          showError={errors.depth !== 'concrete.errors.dimensionsProduct'}
        />
        <FormikInput
          name="width"
          label={t('concrete.structuralElements.width') ?? ''}
          placeholder={
            t('concrete.structuralElements.depthAndWithPlaceholder') ?? ''
          }
          numberInput
          decimal
          showError={errors.width !== 'concrete.errors.dimensionsProduct'}
        />
      </InputsWrapper>
      {isDimensionsProductError && errors.depth && (
        <ErrorMessage showIcon={false} error={errors.depth} />
      )}
    </Wrapper>
  );
};

export default DimensionsInputs;
