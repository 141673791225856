export const CONTENT_MIN_WIDTH = '320px';
export const CONTENT_MAX_WIDTH = '1210px';
export const HEADER_HEIGHT = '75px';
export const HEADER_HEIGHT_MOBILE = '72px';
export const FOOTER_HEIGHT = '64px';
export const TOP_ACTIONS_HEIGHT_MOBILE = '56px';

const fontFamily = {
  regular: 'Avenir Next Regular, Avenir Next Cyr W00 Regular',
  medium: 'Avenir Next Medium, Avenir Next Cyr W00 Medium',
  demi: 'Avenir Next Demi, Avenir Next Cyr W00 Demi',
  bold: 'Avenir Next Bold, Avenir Next Cyr W00 Bold',
};

const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

const fontSize = {
  xs: '12px',
  s: '14px',
  m: '16px',
  l: '18px',
  xl: '24px',
  '2xl': '32px',
  '3xl': '40px',
};

// TODO: edit typography?
export const typography = {
  h1: {
    fontFamily: fontFamily.bold,
    fontSize: fontSize['3xl'],
    fontWeight: fontWeight.bold,
    lineHeight: '46px',
  },
  h3: {
    fontFamily: fontFamily.demi,
    fontSize: fontSize['2xl'],
    fontWeight: fontWeight.semiBold,
    lineHeight: '42px',
  },
  h4: {
    fontFamily: fontFamily.medium,
    fontSize: fontSize.xl,
    fontWeight: fontWeight.semiBold,
    lineHeight: '32px',
  },
  h5: {
    fontFamily: fontFamily.medium,
    fontSize: fontSize.l,
    fontWeight: fontWeight.semiBold,
    lineHeight: '24px',
  },
  h6: {
    fontFamily: fontFamily.medium,
    fontSize: fontSize.m,
    fontWeight: fontWeight.semiBold,
    lineHeight: '18px',
  },
  h7: {
    fontFamily: fontFamily.medium,
    fontSize: fontSize.s,
    fontWeight: fontWeight.semiBold,
    lineHeight: '16px',
  },
  h9: {
    fontFamily: fontFamily.bold,
    fontSize: fontSize.xs,
    fontWeight: fontWeight.bold,
    lineHeight: '16px',
  },
  body2: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.m,
    fontWeight: fontWeight.regular,
    lineHeight: '21px',
  },
  body3: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.s,
    fontWeight: fontWeight.regular,
    lineHeight: '18px',
  },
  body4: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.xs,
    fontWeight: fontWeight.medium,
    lineHeight: '16px',
  },
  body5: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.s,
    fontWeight: fontWeight.regular,
    lineHeight: '24px',
  },
  body6: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.s,
    fontWeight: fontWeight.medium,
    lineHeight: '24px',
  }
};

export const typographyMobile = {
  ...typography,
  h1: {
    fontSize: '22px',
    fontWeight: fontWeight.bold,
    lineHeight: '30px',
  },
  h4: {
    fontSize: fontSize.m,
    fontWeight: fontWeight.semiBold,
    lineHeight: '32px',
  },
  h5: {
    fontSize: fontSize.m,
    fontWeight: fontWeight.semiBold,
    lineHeight: '24px',
  },
  body2: {
    fontSize: fontSize.s,
    fontWeight: fontWeight.regular,
    lineHeight: '21px',
  },
};

export enum BreakpointsPx {
  mobile = 768,
  tablet = 992,
  desktop = 1210,
}

const PALETTE = {
  white: '#fff',
  black: '#000',
  grey100: '#F9F9F9',
  grey200: '#ECECEC',
  grey400: '#BEBEBE',
  grey600: '#7C8F9F',
  grey800: '#9B9B9B',
  grey1000: '#5E5E5E',
  grey1200: '#4A4A4A',
  grey1400: '#2E3538',
  grey1600: '#C2D0D6',
  grey1800: '#5C6970',
  bluePrimary: '#0BA1E2',
  blueInactive: '#CBDFEE',
  blueHighlight: '#EAF6FE',
  blueHover: '#222465',
  blueBackgroundLight: '#F6F9FC',
  blueHeading: '#222469',
  blueSubheading: '#009FE3',
  redError: '#C32222',
};

const border = {
  default: `1px solid ${PALETTE.grey200}`,
  darkerGray: `1px solid ${PALETTE.grey1600}`,
  inactive: `1px solid ${PALETTE.blueInactive}`,
  primary: `1px solid ${PALETTE.bluePrimary}`,
  hover: `1px solid ${PALETTE.blueHover}`,
  light: `1px solid ${PALETTE.blueBackgroundLight}`,
  error: `1px solid ${PALETTE.redError}`,
};

export const theme = {
  colors: {
    black: PALETTE.black,
    white: PALETTE.white,
    heading: PALETTE.blueHeading,
    subheading: PALETTE.blueSubheading,
    textDefault: PALETTE.black,
    textPrimary: PALETTE.grey1000,
    textSecondary: PALETTE.grey1200,
    textDisabled: PALETTE.grey400,
    textDisclaimer: PALETTE.grey800,
    textModal: PALETTE.grey1400,
    primary: PALETTE.bluePrimary,
    inactive: PALETTE.blueInactive,
    error: PALETTE.redError,
    background: PALETTE.grey100,
    backgroundLight: PALETTE.blueBackgroundLight,
    border: PALETTE.grey200,
    highlight: PALETTE.blueHighlight,
    buttonDisabled: PALETTE.grey200,
    hover: PALETTE.blueHover,
    disclaimer: PALETTE.grey600,
    numberInputArrow: PALETTE.grey1800,
  },
  fontWeight,
  fontSize,
  fontFamily,
  typography,
  typographyMobile,
  border,
  BreakpointsPx,
};

export type Theme = typeof theme;
