import useLocale from 'shared/hooks/useLocale';
import useQuery from 'shared/hooks/useQuery';
import { ProductsCount } from 'shared/types';

const useGetProductsCount = () => {
  const locale = useLocale();

  const { data, error, isLoading, isError } = useQuery<ProductsCount[]>(
    'count',
    `v1/products/${locale}/count`,
    [locale]
  );

  return {
    productsCount: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetProductsCount;
