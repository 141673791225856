import useQuery from 'shared/hooks/useQuery';
import { SteelOrientation } from '../types';

const useGetSteelOrientations = () => {
  const { data, error, isLoading, isError } = useQuery<SteelOrientation[]>(
    'steel-orientations',
    'v1/steel-elements/orientations'
  );

  return {
    steelOrientations: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelOrientations;
