import { FormHelperText } from '@mui/material';
import parse from 'html-react-parser';
import { helperTextStyles } from './styles';

interface InputHelperTextProps {
  error?: boolean;
  helperText: string;
}

const InputHelperText = ({ error, helperText }: InputHelperTextProps) => (
  <FormHelperText error={error} sx={helperTextStyles}>
    {parse(helperText)}
  </FormHelperText>
);

export default InputHelperText;
