import { SelectItem } from 'components/select/types';
import { TFunction } from 'i18next';
import { Country, Language } from 'shared/types';
import getLanguageAndCountryFromLocale from 'shared/utils/getLanguageAndCountryFromLocale';
import { isLocaleAvailable } from 'shared/utils/isLocaleAvailable';

export const mapLanguages = (languages: Language[]): SelectItem[] =>
  languages.map((language) => ({
    id: language.code,
    name: language.name,
  }));

export const translateLangNamesToLocalLang = (
  t: TFunction,
  languages: Language[]
) => {
  return languages.map((language) => {
    const translatedName = t('languageSelect.localLanguage', {
      lng: language.code,
    });
    return { ...language, name: translatedName };
  });
};

export const updatePathOnLanguageChange = (
  language: string,
  languages: Language[],
  countries?: Country[]
) => {
  const pathname = window.location.pathname;
  const firstPathEl = pathname.split('/')[1];
  const hasLangInPath = isLocaleAvailable(firstPathEl, languages, countries);
  const { countryCode, hasCountryCode } =
    getLanguageAndCountryFromLocale(firstPathEl);

  let newPath = pathname;

  // if there is a language/locale in the URL
  if (hasLangInPath) {
    hasCountryCode && countryCode
      ? (newPath = pathname.replace(firstPathEl, `${language}-${countryCode}`)) // if there is a countryCode in the URL
      : (newPath = pathname.replace(firstPathEl, language));
  } else {
    newPath = '/' + language + pathname;
  }

  window.location.replace(newPath);
};
