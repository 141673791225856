import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/loader';
import SelectCard from 'components/select-card';
import Steps from 'components/steps';
import BottomActions from 'features/bottom-actions';
import { CONCRETE_ROUTES } from 'shared/constants';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { LoadbearingStructure, ProgressStep } from 'shared/types';
import { theme } from 'styles/theme';
import useGetConcreteFireRatings from '../api/useGetConcreteFireRatings';
import { CardsWrapper, Title, Wrapper } from './styles';
import { fireIconToDisplay } from 'features/fire-ratings/logic';

const ConcreteFireRating = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fireRatings, isLoading } = useGetConcreteFireRatings();
  const progressSteps = useProgressSteps(LoadbearingStructure.concrete);
  const [, setStoredValues] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteFireRating
  );
  const isProductKnown = useIsProductKnown();
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);

  const selectFireRating = (values: number) => {
    setStoredValues(values);
    navigate(
      isProductKnown
        ? `${CONCRETE_ROUTES.productDetails}/${product.id}`
        : CONCRETE_ROUTES.results
    );
  };

  const navigateBack = () => {
    removeFromSession(SESSION_STORAGE_KEYS.concreteFireRating);
    navigate(CONCRETE_ROUTES.environment);
  };
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Wrapper>
        <Steps items={progressSteps} active={ProgressStep.fireRating} />
        <Title title={t('fireRating.title')} displayContactSupport/>
        <CardsWrapper>
          {fireRatings &&
            fireRatings.map((item) => (
              <SelectCard
                key={item.id}
                title={t('fireRating.time', { amount: item.value })}
                iconPng={fireIconToDisplay(item.value)}
                onClick={() => selectFireRating(item.id)}
                displayArrow
                iconBackground={theme.colors.backgroundLight}
              />
            ))}
        </CardsWrapper>
      </Wrapper>
      <BottomActions onBackClick={navigateBack} />
    </>
  );
};

export default ConcreteFireRating;
