import useQuery from 'shared/hooks/useQuery';
import { FireRating } from 'shared/types';

const useGetSteelFireRatings = () => {
  const { data, error, isLoading, isError } = useQuery<FireRating[]>(
    'steel-fire-ratings',
    'v1/steel-elements/fire-ratings'
  );

  return {
    fireRatings: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelFireRatings;
