import styled from 'styled-components';
import { spacing, breakpoint } from 'styles/helpers';
import {
  CONTENT_MAX_WIDTH,
  CONTENT_MIN_WIDTH,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
} from 'styles/theme';

export const StyledWrapper = styled.main<{ $hasMobileFooter: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${HEADER_HEIGHT} auto 0;
  max-width: ${CONTENT_MAX_WIDTH};
  min-height: calc(100vh - ${HEADER_HEIGHT});
  min-width: ${CONTENT_MIN_WIDTH};
  padding: ${spacing['4xl']} ${spacing.m};

  ${breakpoint.mobile} {
    margin: ${({ $hasMobileFooter }) =>
      `${HEADER_HEIGHT_MOBILE} auto ${$hasMobileFooter ? FOOTER_HEIGHT : 0}`};
    min-height: auto;
    padding: 0;
  }
`;
