import {
  H1ABIcon,
  H2BLIcon,
  H2CLIcon,
  H3BLIcon,
  H3BTIcon,
  H3CLIcon,
  H3CTIcon,
  H4BIcon,
  H4CIcon,
  L1ABIcon,
  L3BBIcon,
  L3BLIcon,
  L3BRIcon,
  L3BTIcon,
  L3CBIcon,
  L3CLIcon,
  L2AIcon,
  L4BIcon,
  L4CIcon,
  R1ABIcon,
  R2ARIcon,
  R3ALIcon,
  R3ATIcon,
  R4AIcon,
  S1ABIcon,
  S2ARIcon,
  S3ATIcon,
  S4AIcon,
  U3ARIcon,
  U3BLIcon,
  U3BTIcon,
  U3CLIcon,
  U3CTIcon,
  U4BIcon,
  U4CIcon,
  C4BIcon,
  C4CIcon,
  L1ALIcon,
  R1ALIcon,
} from 'assets/protected-sides';

export const PROTECTED_SIDES_ICONS: { [key: string]: string } = {
  H1AB: H1ABIcon,
  H2CL: H2CLIcon,
  H2BL: H2BLIcon,
  H3CT: H3CTIcon,
  H3BT: H3BTIcon,
  H3CL: H3CLIcon,
  H3BL: H3BLIcon,
  H4C: H4CIcon,
  H4B: H4BIcon,
  U3CT: U3CTIcon,
  U3BT: U3BTIcon,
  U3CL: U3CLIcon,
  U3AR: U3ARIcon,
  U3BL: U3BLIcon,
  U4C: U4CIcon,
  U4B: U4BIcon,
  L1AB: L1ABIcon,
  L1AL: L1ALIcon,
  L3CB: L3CBIcon,
  L3BT: L3BTIcon,
  L3BB: L3BBIcon,
  L3CL: L3CLIcon,
  L2A: L2AIcon,
  L3BL: L3BLIcon,
  L3BR: L3BRIcon,
  L4C: L4CIcon,
  L4B: L4BIcon,
  R1AB: R1ABIcon,
  R1AL: R1ALIcon,
  R2AR: R2ARIcon,
  R3AT: R3ATIcon,
  R3AL: R3ALIcon,
  R4A: R4AIcon,
  S1AB: S1ABIcon,
  S2AR: S2ARIcon,
  S3AT: S3ATIcon,
  S4A: S4AIcon,
  C4C: C4CIcon,
  C4B: C4BIcon,
  'All H2': H2BLIcon,
  'H3-T': H3BTIcon,
  'H3-L': H3BLIcon,
  'All H4': H4BIcon,
  'U3-T': U3BTIcon,
  'U3-L': U3BLIcon,
  'All U4': U4BIcon,
  'All C4': C4BIcon,
  'L3-B': L3BBIcon,
  'L3-L': L3BLIcon,
  'All L4': L4BIcon,
};

export const combinedProtectedSidesMap: { [key: string]: string } = {
  H2BL: 'All H2',
  H3BT: 'H3-T',
  H3BL: 'H3-L',
  H4B: 'All H4',
  U3BT: 'U3-T',
  U3BL: 'U3-L',
  U4B: 'All U4',
  C4B: 'All C4',
  L3BB: 'L3-B',
  L3BL: 'L3-L',
  L4B: 'All L4',
};
