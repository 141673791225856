import { useTranslation } from 'react-i18next';
import RestartIcon from 'assets/restart-icon';
import Button from 'components/button';
import useIsMobile from 'shared/hooks/useIsMobile';

interface ResultsRestartButtonProps {
  onClick: () => void;
}

const ResultsRestartButton = ({ onClick }: ResultsRestartButtonProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Button
      startIcon={<RestartIcon />}
      onClick={onClick}
      content={isMobile ? t('results.restart') : t('results.restartCalculator')}
    />
  );
};

export default ResultsRestartButton;
