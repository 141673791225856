import { IdNamePair } from 'shared/types';

export type ConcreteStructuralElementsValues = {
  orientation: number;
  depth: number;
  width: number;
  currentAxis: number;
  requiredAxis: number;
};

export type ConcreteOrientation = IdNamePair;

export enum ConcreteOrientationName {
  Beam = 'Beam',
  Column = 'Column',
  Slab = 'Slab',
  Wall = 'Wall',
}

export type ConcreteCalculationResultRequest = {
  orientationId: number;
  fireRatingId: number;
  totalAreaToProtectM2?: number;
  wastage: number;
  currentAxisDistance?: number;
  requiredAxisDistance: number;
};

export type ConcreteCalculationsListRequest =
  ConcreteCalculationResultRequest & {
    environmentHumidity: string;
    environmentTemperature: string;
    productCategory?: string;
  };

export type ConcreteInputDetailsRequest = {
  orientationId: number;
  fireRatingId: number;
  wastage: number;
  currentAxisDistance: number;
  requiredAxisDistance: number;
  depth: number;
  width: number;
};
