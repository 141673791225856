import * as Yup from 'yup';
import { TEMPERATURE_OTHER } from './constants';

export const requiredFieldsValidationSchema = Yup.object().shape({
  fireRating: Yup.string().required('fireRating.error'),
  criticalTemperature: Yup.string().required(
    'steel.errors.criticalTemperature'
  ),
  customCriticalTemperature: Yup.number().when('criticalTemperature', {
    is: TEMPERATURE_OTHER.id,
    then: (schema) => schema.required('steel.errors.criticalTemperature'),
  }),
});

export const validationSchema = requiredFieldsValidationSchema.shape({
  customCriticalTemperature: Yup.number()
    .min(350, 'steel.errors.criticalTemperature')
    .max(750, 'steel.errors.criticalTemperature')
    .when('criticalTemperature', {
      is: TEMPERATURE_OTHER.id,
      then: (schema) => schema.required('steel.errors.criticalTemperature'),
    }),
});
