export const BUILDING_FUNCTIONS = [
  { id: 1, name: 'Residential' },
  { id: 2, name: 'Event / Meeting venue' },
  { id: 3, name: 'Healthcare' },
  { id: 4, name: 'Industry' },
  { id: 5, name: 'Office' },
  { id: 6, name: 'Hotel' },
  { id: 7, name: 'Education' },
  { id: 8, name: 'Sports facility' },
  { id: 9, name: 'Museum' },
  { id: 10, name: 'Storage' },
  { id: 11, name: 'Car park' },
  { id: 12, name: 'Other' },
];
