import styled from 'styled-components';
import { breakpoint } from 'styles/helpers';

//TODO: edit typography (these namings are from figma now)
export const Heading1 = styled.h1`
  ${({ theme }) => theme.typography.h1};
  color: ${({ theme }) => theme.colors.heading};

  ${breakpoint.mobile} {
    ${({ theme }) => theme.typographyMobile.h1}
  }
`;

export const Heading3 = styled.h3`
  ${({ theme }) => theme.typography.h3};
`;

export const Heading4 = styled.h4`
  ${({ theme }) => theme.typography.h4};
  color: ${({ theme }) => theme.colors.primary};

  ${breakpoint.mobile} {
    ${({ theme }) => theme.typographyMobile.h4}
  }
`;

export const Heading5 = styled.h5`
  ${({ theme }) => theme.typography.h5};

  ${breakpoint.mobile} {
    ${({ theme }) => theme.typographyMobile.h5}
  }
`;

export const Heading6 = styled.h6`
  ${({ theme }) => theme.typography.h6};
`;

export const Heading7 = styled.p`
  ${({ theme }) => theme.typography.h7};
`;

export const Heading9 = styled.p`
  ${({ theme }) => theme.typography.h9};
`;

export const Body2 = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.textDefault};

  ${breakpoint.mobile} {
    ${({ theme }) => theme.typographyMobile.body2}
  }
`;

export const Body3 = styled.p`
  ${({ theme }) => theme.typography.body3};
`;

export const Body4 = styled.p`
  ${({ theme }) => theme.typography.body4};
`;
