import * as Yup from 'yup';
import { CUSTOM_PROFILE } from '../constants';

export const requiredFieldsValidationSchema = Yup.object().shape({
  profileShape: Yup.string().required(),
  orientation: Yup.string().required(),
  profileType: Yup.string().when('profileShape', {
    is: (value: string) => value !== CUSTOM_PROFILE,
    then: (schema) => schema.required(),
  }),
  profileSubtype: Yup.string().when('profileShape', {
    is: (value: string) => value !== CUSTOM_PROFILE,
    then: (schema) => schema.required(),
  }),
  sectionFactor: Yup.number().when('profileShape', {
    is: CUSTOM_PROFILE,
    then: (schema) => schema.required('steel.errors.sectionFactor'),
  }),
  sectionType: Yup.string().when('profileShape', {
    is: CUSTOM_PROFILE,
    then: (schema) => schema.required(),
  }),
});

export const validationSchema = requiredFieldsValidationSchema.shape({
  sectionFactor: Yup.number()
    .min(0, 'steel.errors.sectionFactor')
    .max(500, 'steel.errors.sectionFactor')
    .when('profileShape', {
      is: CUSTOM_PROFILE,
      then: (schema) => schema.required('steel.errors.sectionFactor'),
    }),
});
