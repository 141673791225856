import { styled } from 'styled-components';
import { spacing, breakpoint } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const Wrapper = styled.div`
  ${grid.threeColumn};
  padding-bottom: ${spacing.xl};

  ${breakpoint.mobile} {
    padding-bottom: ${spacing.s};
  }
`;
