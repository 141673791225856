import { useTranslation } from 'react-i18next';
import PageNotFoundImg from 'assets/page-not-found-img.jpg';
import Button from 'components/button';
import { ButtonVariant } from 'components/button/types';
import { ROUTES } from 'shared/constants';
import { MainContent, Heading, StyledImg, Wrapper } from './styles';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <MainContent>
        <Heading>{t('pageNotFound.title')}</Heading>
        <Button
          variant={ButtonVariant.filled}
          content={t('pageNotFound.backToCalculator')}
          href={ROUTES.home}
        />
      </MainContent>
      <StyledImg src={PageNotFoundImg} alt="" />
    </Wrapper>
  );
};

export default PageNotFound;
