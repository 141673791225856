import { FC, ReactNode } from 'react';
import InfoIcon from 'assets/info-icon';
import {
  Container,
  DisclaimerTitleIconWrapper,
  DisclaimerTitleWrapper,
} from './styles';

interface Props {
  title: string;
  children: ReactNode;
}

const Disclaimer: FC<Props> = ({ title, children }) => {
  return (
    <Container>
      <DisclaimerTitleIconWrapper>
        <InfoIcon />
      </DisclaimerTitleIconWrapper>
      <DisclaimerTitleWrapper>{title}</DisclaimerTitleWrapper>
      {children}
    </Container>
  );
};

export default Disclaimer;
