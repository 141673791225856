import {
  SlabIcon,
  WallIcon,
  BeamIcon,
  ColumnIcon,
} from 'assets/concrete-structural-elements';

export const CONCRETE_ORIENTATION_ICONS: { [key: string]: string } = {
  Slab: SlabIcon,
  Wall: WallIcon,
  Beam: BeamIcon,
  Column: ColumnIcon,
};
