import { ReactNode } from 'react';
import { View, Text, Link } from '@react-pdf/renderer';
import styles from './styles';

export const markdownComponents = {
  p: ({ children }: { children: string }) => (
    <Text style={styles.text} hyphenationCallback={(word) => [word]}>
      {children}
    </Text>
  ),
  strong: ({ children }: { children: string }) => (
    <Text hyphenationCallback={(word) => [word]} style={styles.strongText}>
      {children}
    </Text>
  ),
  a: ({ children, href }: { children: string; href: string }) => (
    <Link src={href}>{children}</Link>
  ),
  ul: ({ children }: { children: ReactNode }) => (
    <View style={styles.list}>{children}</View>
  ),
  li: ({ children }: { children: string }) => (
    <Text hyphenationCallback={(word) => [word]}>• &nbsp;{children}</Text>
  ),
  table: ({ children }: { children: ReactNode }) => (
    <View style={styles.table}>{children}</View>
  ),
  thead: ({ children }: { children: ReactNode }) => <View>{children}</View>,
  tbody: ({ children }: { children: ReactNode }) => <View>{children}</View>,
  tr: ({ children }: { children: ReactNode }) => (
    <View style={styles.tableRow}>{children}</View>
  ),
  td: ({ children }: { children: string }) => (
    <Text style={styles.tableCell}>{children}</Text>
  ),
  th: ({ children }: { children: string }) => (
    <Text style={styles.tableCell}>{children}</Text>
  ),
};
