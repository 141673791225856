import { TFunction } from 'i18next';
import { ProductCategory, ProtectedSidesType } from 'shared/types';
import { ProtectedSidesItem } from '../types';
import { combinedProtectedSidesMap } from './constants';

export const filterProtectedSides = (protectedSides: ProtectedSidesItem[]) => {
  const possibleProtectedSides = [4, 3, 2, 1];
  const filteredProtectedSides = possibleProtectedSides.map((side) =>
    protectedSides.filter(
      (item: ProtectedSidesItem) => item.sidesToProtect === side
    )
  );

  return filteredProtectedSides.filter(
    (protectedSidesArr) => protectedSidesArr.length > 0
  );
};

export const getProtectedSidesLabelText = (sidesCount: number, t: TFunction) =>
  sidesCount > 1
    ? t('steel.protectedSides.label.sides', { count: sidesCount })
    : t('steel.protectedSides.label.side', { count: sidesCount });

export const getProtectedSidesTooltipText = (
  code: string,
  t: TFunction,
  productCategory: ProductCategory
) => {
  if (!productCategory && combinedProtectedSidesMap[code]) {
    return t(
      `steel.protectedSides.tooltipText.${combinedProtectedSidesMap[code]}`
    );
  }

  return t(`steel.protectedSides.tooltipText.${code}`);
};

export const getProtectedSidesType = (product: { [key: string]: string }) => {
  if (!product.category || product.category === ProductCategory.Boards) {
    return ProtectedSidesType.Box;
  }

  return ProtectedSidesType.Contour;
};
