import { TFunction } from 'i18next';
import { ROUTES, STEEL_ROUTES, CONCRETE_ROUTES } from 'shared/constants';
import { LoadbearingStructure, Product, ProductCategory } from 'shared/types';

const titleByCategory = {
  [ProductCategory.Boards]: 'Board',
  [ProductCategory.Sprays]: 'Spray',
  [ProductCategory.Paints]: 'Paint',
};

export const getProductCategoryCardTitle = (
  category: ProductCategory,
  t: TFunction
) => t(`product.category.singular.${titleByCategory[category]}`);

const routeByStructure = {
  [LoadbearingStructure.steel]: STEEL_ROUTES.structuralElements,
  [LoadbearingStructure.concrete]: CONCRETE_ROUTES.structuralElements,
  [LoadbearingStructure.timber]: ROUTES.timber,
};

export const getRouteByStructure = (
  selectedStructure: LoadbearingStructure | null
) => (selectedStructure ? routeByStructure[selectedStructure] : '');

export const getProductCategories = (products: Product[]) => {
  const categories: string[] = [];

  Object.values(ProductCategory).forEach((category) => {
    if (products?.some((product) => product.categoryType === category)) {
      categories.push(category);
    }
  });

  return categories;
};

export const getProductsByCategory = (
  products: Product[],
  selectedCategory: string
) => {
  const productsByCategory = products?.filter(
    (product) => product.categoryType === selectedCategory
  );

  return productsByCategory?.sort((a, b) => a.name?.localeCompare(b.name));
};
