import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Disclaimer from 'components/disclaimer-section';
import ProductDetails from 'features/product-details';
import { STEEL_ROUTES } from 'shared/constants';
import useGetSessionInputDetails from 'shared/hooks/useGetSessionInputDetails';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { SteelInputDetailsData } from 'shared/types';
import { sortInputDetails } from 'shared/utils/sortInputDetails';
import useGetSteelCalculations from '../api/useGetSteelCalculations';
import useGetSteelInputDetails from '../api/useGetSteelInputDetails';
import { steelInputsSortOrder } from './constants';
import { DisclaimerWrapper } from './styles';
import useIsMobile from '../../../shared/hooks/useIsMobile';

const SteelProductDetails = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const isMobile = useIsMobile();
  const isProductKnown = useIsProductKnown();
  const {
    calculationResult,
    getSteelCalculations,
    isLoading: isCalculationResultLoading,
  } = useGetSteelCalculations(productId);

  const {
    inputDetails,
    getSteelInputDetails,
    isLoading: isInputDetailsLoading,
  } = useGetSteelInputDetails();

  const backRoute = isProductKnown
    ? STEEL_ROUTES.fireRating
    : STEEL_ROUTES.results;

  useEffect(() => {
    getSteelCalculations();
    getSteelInputDetails();
  }, []);

  const sessionInputDetails = useGetSessionInputDetails(
    SESSION_STORAGE_KEYS.steelEnvironment
  );

  const steelInputDetails = { ...inputDetails, ...sessionInputDetails };

  const sortedInputDetails = sortInputDetails(
    steelInputsSortOrder,
    steelInputDetails as SteelInputDetailsData
  );

  const isProductDataLoading =
    isCalculationResultLoading || isInputDetailsLoading;

  return (
    <>
      <ProductDetails
        calculationResult={calculationResult}
        inputDetails={sortedInputDetails}
        isCalculationResultLoading={isProductDataLoading}
        backRoute={backRoute}
      />
      {!isCalculationResultLoading && <DisclaimerWrapper $isMobile={isMobile}>
        <Disclaimer text={t('productDetails.legal')} />
      </DisclaimerWrapper>}
    </>
  );
};
export default SteelProductDetails;
