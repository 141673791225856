import { StyleSheet } from '@react-pdf/renderer';
import { spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

const HEADER_LOGO_WIDTH_PDF = 98;

const styles = StyleSheet.create({
  docName: {
    alignSelf: 'center',
    color: theme.colors.primary,
    fontFamily: 'Avenir Next Cyr W00 Medium',
    fontSize: 10,
    marginTop: spacing['3xs'],
  },
  header: {
    borderBottom: theme.border.inactive,
    justifyContent: 'center',
    marginBottom: spacing['2xs'],
    padding: `${spacing.s} ${spacing.m}`,
  },
  headerLogo: {
    width: HEADER_LOGO_WIDTH_PDF,
  },
});

export default styles;
