import { css, styled } from 'styled-components';

export const StyledModalWrapper = styled.div<{
  $isMobileView?: boolean;
}>`
  background-color: white;
  border: 1px solid #e1e8eb;
  border-radius: 8px;
  left: 50%;
  padding: 24px 0 24px 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &:focus {
    outline: none;
  }

  ${({ $isMobileView }) => modalStyles($isMobileView)};
`;

const modalStyles = (isMobileView?: boolean) => css`
  border: none;
  border-radius: ${isMobileView ? '0' : '8px'};
  height: ${isMobileView ? '100%' : 'fit-content'};
  width: ${isMobileView ? '100%' : '530px'};
`;
