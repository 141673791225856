import { useTranslation } from 'react-i18next';
import { BUILDING_FUNCTIONS } from 'features/environment/components/environment-inputs/constants';
import { TFunction } from 'i18next';
import { InputDetailsData } from 'shared/types';
import useSessionStorage from './useSessionStorage';

const mapSessionInputDetailsValues = (
  t: TFunction,
  inputDetails: { [key: string]: string }
) => {
  Object.keys(inputDetails).forEach((key) => {
    if (!inputDetails[key]) {
      delete inputDetails[key];
      return;
    }

    if (key === 'buildingFunction') {
      inputDetails[key] = t(
        `environment.buildingFunctions.${inputDetails[key]}`
      );
      return;
    }

    inputDetails[key as keyof InputDetailsData] = t(
      `environment.${inputDetails[key]}`
    );
  });

  return inputDetails;
};

const useGetSessionInputDetails = (environmentStorageKey: string) => {
  const { t } = useTranslation();
  const [environment] = useSessionStorage(environmentStorageKey);

  const buildingFunction =
    BUILDING_FUNCTIONS[environment?.buildingFunction - 1]?.name;

  const inputDetails = {
    buildingFunction: buildingFunction,
    humidity: environment?.humidity,
    temperature: environment?.temperature,
  };

  const sessionInputDetails = mapSessionInputDetailsValues(t, inputDetails);

  return sessionInputDetails;
};

export default useGetSessionInputDetails;
