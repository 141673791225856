import { styled } from 'styled-components';
import { Body3 } from 'components/typography';
import { breakpoint, spacing } from 'styles/helpers';
import { CONTENT_MIN_WIDTH, FOOTER_HEIGHT } from 'styles/theme';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${breakpoint.mobile} {
    background-color: ${({ theme }) => theme.colors.white};
    bottom: 0;
    box-shadow: 0 -2px 14px rgb(7 41 56 / 10%);
    height: ${FOOTER_HEIGHT};
    min-width: ${CONTENT_MIN_WIDTH};
    padding: ${spacing.xs} ${spacing.m};
    position: fixed;
    z-index: 1;
  }
`;

export const SelectToContinueText = styled(Body3)`
  text-align: end;
  width: 100%;
`;
