const HighHumidityIcon = () => (
  <svg
    width="61"
    height="92"
    viewBox="0 0 61 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.7449 61.4691V61.5941H27.8246C31.5076 64.7655 33.4803 69.2538 33.4803 74.1357C33.4803 83.2704 25.9315 90.8134 16.8027 90.8134C7.66734 90.8134 0.125 83.3994 0.125 74.1357C0.125 69.2383 2.11025 64.7371 5.8157 61.564L5.8594 61.5266V61.4691V11.0677C5.8594 5.0013 10.7357 0.125 16.8021 0.125C22.8686 0.125 27.7449 5.0013 27.7449 11.0677V61.4691ZM9.26596 64.2994L9.26589 64.2993L9.26162 64.3026C6.16455 66.7267 4.41153 70.2313 4.41153 74.1355C4.41153 81.0013 9.93093 86.5261 16.8021 86.5261C23.5387 86.5261 29.1928 81.0053 29.1928 73.8643C29.1928 69.9548 27.4397 66.4554 24.3427 64.0314L24.3428 64.0313L24.3384 64.0282L23.4584 63.3989V39.1979V39.0729H23.3334H19.0678C17.8855 39.0729 16.9272 38.1145 16.9272 36.9323C16.9272 35.75 17.8855 34.7917 19.0678 34.7917H23.3334H23.4584V34.6667V29.3333V29.2083H23.3334H19.0678C17.8855 29.2083 16.9272 28.25 16.9272 27.0677C16.9272 25.8855 17.8855 24.9271 19.0678 24.9271H23.3334H23.4584V24.8021V19.3333V19.2083H23.3334H19.0678C17.8855 19.2083 16.9272 18.25 16.9272 17.0677C16.9272 15.8855 17.8855 14.9271 19.0678 14.9271H23.3334H23.4584V14.8021V11.0677C23.4584 7.39977 20.4701 4.41153 16.8022 4.41153C13.1342 4.41153 10.146 7.39977 10.146 11.0677V63.6701L9.26596 64.2994Z"
      fill="#222465"
      stroke="#CCDFEE"
      strokeWidth="0.25"
    />
    <path
      d="M15 44.0005C11.8 42.8005 9.33333 45.3334 7.5 47.0001V59.5001V63.0001L4 67.0001L2.5 72.0001L3 78.0001L7.5 85.5001L15 89.0001L25 86.5L25.5 44.0004C22.5 45.5005 18.2 45.2005 15 44.0005Z"
      stroke="#222465"
      strokeWidth="3"
    />
    <path
      d="M58.7953 68.4914C58.7953 80.0328 49.4391 89.389 37.8976 89.389C26.3562 89.389 17 80.0328 17 68.4914C17 66.2543 17.8757 63.1859 19.4488 59.5743C20.9997 56.0135 23.1376 52.1257 25.4611 48.3156C30.0753 40.7496 35.3277 33.6428 37.8976 30.269C40.4676 33.6428 45.72 40.7496 50.3341 48.3156C52.6577 52.1257 54.7956 56.0135 56.3465 59.5743C57.9196 63.1859 58.7953 66.2543 58.7953 68.4914Z"
      fill="#222465"
      stroke="#222465"
      strokeWidth="4"
    />
    <path
      d="M38.3472 52.7898C38.0065 52.2393 37.2206 52.2393 36.9065 52.7898L33.6578 58.081C33.317 58.6571 33.7099 59.3641 34.3914 59.3641H36.4082V70.759C36.4082 71.4138 36.9588 71.9643 37.6136 71.9643C38.2685 71.9643 38.819 71.4138 38.819 70.759V59.3652H40.8358C41.4907 59.3652 41.9102 58.6315 41.5695 58.082L38.3472 52.7898Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M28.2686 62.9664C27.9278 62.4158 27.142 62.4158 26.8278 62.9664L23.6056 68.231C23.2648 68.8071 23.6578 69.5142 24.3392 69.5142H26.3561V76.0896C26.3561 76.7445 26.9066 77.295 27.5614 77.295C28.2163 77.295 28.7668 76.7445 28.7668 76.0896L28.7658 69.5152H30.7826C31.4375 69.5152 31.857 68.7815 31.5163 68.2321L28.2686 62.9664Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M51.6211 67.9627L48.3989 62.698C48.0581 62.1475 47.2723 62.1475 46.9582 62.698L43.7359 67.9627C43.3952 68.5388 43.7881 69.2459 44.4696 69.2459H46.4864V75.8213C46.4864 76.4762 47.0369 77.0267 47.6918 77.0267C48.3467 77.0267 48.8972 76.4762 48.8972 75.8213V69.2469H50.914C51.5689 69.2469 51.9618 68.5388 51.621 67.9627L51.6211 67.9627Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default HighHumidityIcon;
