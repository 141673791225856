import { ReactNode } from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { IconButtonWrapper } from './styles';

type IconButtonProps = {
  onClick: () => void;
  icon: ReactNode;
};

const IconButton = ({ onClick, icon }: IconButtonProps) => {
  return (
    <IconButtonWrapper>
      <MuiIconButton onClick={onClick}>{icon}</MuiIconButton>
    </IconButtonWrapper>
  );
};

export default IconButton;
