import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import useSessionStorage from './useSessionStorage';

const useIsProductKnown = (): boolean => {
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);

  return product ? !!product.id : false;
};

export default useIsProductKnown;
