import styled from 'styled-components';
import ScreenTitle from 'components/screen-title';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const ProductSelectWrapper = styled.div`
  margin-bottom: ${spacing['4xl']};

  ${breakpoint.mobile} {
    margin-bottom: 0;
    padding: ${spacing.s} ${spacing.m};
  }
`;

export const HelpCardWrapper = styled.div`
  ${grid.threeColumn}
  margin-bottom: ${spacing.xl};

  ${breakpoint.mobile} {
    margin-bottom: ${spacing.s};
  }
`;

export const ProductCardsWrapper = styled.div`
  ${grid.threeColumn}
  margin-top: ${spacing.s};
`;

export const StyledScreenTitle = styled(ScreenTitle)`
  margin: ${spacing.xl} 0 ${spacing.s};

  ${breakpoint.mobile} {
    margin: ${spacing.s} 0;
  }
`;
