import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Disclaimer from 'components/disclaimer-section';
import Loader from 'components/loader';
import ResultsGrid from 'features/results-grid';
import ResultsRestartButton from 'features/results-restart-button';
import TopActions from 'features/top-actions';
import { HS_FORM_CONTACT_SUPPORT_FORM_ID, HS_FORM_PORTAL, HS_FORM_REGION, STEEL_ROUTES } from 'shared/constants';
import useIsMobile from 'shared/hooks/useIsMobile';
import useRestartCalculator from 'shared/hooks/useRestartCalculator';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import {
  CalculationInputParamsData,
  SteelCalculationInputParamsData,
} from 'shared/types';
import useGetSteelCalculationsList from '../api/useGetSteelCalculationsList';
import { MobileWrapper, Title } from './styles';
import ModalComponent from '../../../components/modal';
import ModalHeader from '../../../components/modal/modal-header';
import HubspotForm from '../../../components/modal/modal-form';

const SteelResults = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const restartCalculator = useRestartCalculator();

  const [, setInputParamsFromResults] = useSessionStorage(
    SESSION_STORAGE_KEYS.inputParametersFromResults
  );
  const {
    productsWithCalculations,
    getSteelCalculationsList,
    isLoading: isCalculationsListLoading,
  } = useGetSteelCalculationsList();

  const navigateBack = () => navigate(STEEL_ROUTES.fireRating);

  const viewProductDetails = (inputParams: CalculationInputParamsData) => {
    const steelInputParams = inputParams as SteelCalculationInputParamsData;
    setInputParamsFromResults({
      productId: steelInputParams.productId,
      category: steelInputParams.category,
      fireRatingId: steelInputParams.fireRatingId,
      orientationId: steelInputParams.orientationId,
      totalAreaToProtectM2: steelInputParams.totalAreaToProtectM2,
      criticalTemperatureId: steelInputParams.criticalTemperatureId,
      criticalTemperatureC: steelInputParams.criticalTemperatureC,
      crossSectionalShapeId: steelInputParams.crossSectionalShapeId,
      sectionFactor: steelInputParams.sectionFactor,
      profileSubTypeId: steelInputParams.profileSubTypeId,
      protectedSideId: steelInputParams.protectedSideId,
    });
    navigate(`${STEEL_ROUTES.productDetails}/${steelInputParams.productId}`);
  };

  useEffect(() => {
    getSteelCalculationsList();
    setIsInitialLoading(false);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState({
    title: '',
    portalId: '',
    formId: '',
    region: '',
  });

  const onContactSupportModalOpen = () => {
    setModalData({
      title: t('modal.contactSupport'),
      portalId: HS_FORM_PORTAL,
      formId: HS_FORM_CONTACT_SUPPORT_FORM_ID,
      region: HS_FORM_REGION,
    });

    setIsModalOpen(true);
  };

  return isInitialLoading || isCalculationsListLoading ? (
    <Loader />
  ) : (
    <div>
      <ModalComponent
        header={
          <ModalHeader
            title={modalData.title}
            handleClose={() => {
              setIsModalOpen(false);
            }}
          />
        }
        isOpen={isModalOpen}
        content={
          <HubspotForm
            portalId={modalData.portalId}
            formId={modalData.formId}
            region={modalData.region}
            onFormSubmitted={() =>
              setModalData((prevState) => ({ ...prevState, title: '' }))
            }
            onDoneClick={() => {
              setIsModalOpen(false);
            }}
          />
        }
      />


      <TopActions
        title={t('results.title') ?? ''}
        onBackClick={navigateBack}
        rightAction={<ResultsRestartButton onClick={restartCalculator} />}
      />
      {isMobile ? (
        <MobileWrapper>
          <ResultsGrid
            products={productsWithCalculations}
            onViewProductDetails={viewProductDetails}
            onContactSupportModalOpen={onContactSupportModalOpen}
          />
          <Disclaimer text={t('results.legal')} />
        </MobileWrapper>
      ) : (
        <>
          <Title title={t('results.title')} />
          <ResultsGrid
            products={productsWithCalculations}
            onViewProductDetails={viewProductDetails}
            onContactSupportModalOpen={onContactSupportModalOpen}
          />
          <Disclaimer text={t('results.legal')} />
        </>
      )}
    </div>
  );
};

export default SteelResults;
