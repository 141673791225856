const ConcreteIcon = () => (
  <svg
    width="120"
    height="84"
    viewBox="0 0 120 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.7197 53.6327H1.75845L1.75845 75.6133L45.7197 75.6133V53.6327ZM1.75845 51.8743C0.787285 51.8743 0 52.6616 0 53.6327V75.6133C0 76.5845 0.787284 77.3718 1.75845 77.3718H45.7197C46.6909 77.3718 47.4781 76.5845 47.4781 75.6133V53.6327C47.4781 52.6616 46.6909 51.8743 45.7197 51.8743H1.75845Z"
      fill="#222465"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.6109 51.7948C91.6839 51.642 91.7792 51.4993 91.8948 51.3717L113.75 27.256L71.0996 27.256L48.02 51.8742H91.4389C91.4449 51.8742 91.4508 51.8743 91.4567 51.8743H91.5389L91.6109 51.7948ZM46.3163 52.3123C46.1738 51.7684 46.2848 51.1541 46.7371 50.6716L69.8168 26.0533C70.1492 25.6987 70.6136 25.4976 71.0996 25.4976H113.849C114.62 25.4976 115.206 25.9593 115.465 26.5619C116.25 26.6967 116.937 27.3511 116.937 28.2981V51.18C116.937 51.6261 116.767 52.0556 116.463 52.3813L94.4819 75.8779C94.062 76.3267 93.514 76.4881 92.9989 76.4257C92.7055 76.9879 92.117 77.3717 91.4389 77.3717H47.4777C46.5065 77.3717 45.7192 76.5845 45.7192 75.6133V53.6327C45.7192 53.1067 45.9502 52.6346 46.3163 52.3123ZM48.0016 53.6327H47.4777L47.4777 75.6133H91.4389V53.6328H48.02C48.0138 53.6328 48.0077 53.6327 48.0016 53.6327ZM93.1978 52.6625L93.1977 74.6766L115.178 51.18V28.4074C115.17 28.4173 115.161 28.4271 115.152 28.4368L93.1978 52.6625Z"
      fill="#222465"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.26415 27.2708H45.7197C45.7627 27.2708 45.8052 27.2723 45.8474 27.2754C45.9208 27.0933 46.0247 26.9233 46.1562 26.7733L68.0826 1.75845H25.3978L2.26415 27.2708ZM24.0951 0.577251C24.4285 0.209655 24.9016 0 25.3978 0H68.217C69.02 0 69.6242 0.502598 69.8648 1.14421C70.5986 1.32165 71.2176 1.95538 71.2176 2.85575V26.5934C71.2176 27.0294 71.0556 27.4499 70.7631 27.7732L48.7824 52.0662C48.3682 52.524 47.821 52.6938 47.3039 52.6382C47.0196 53.2267 46.4171 53.6327 45.7197 53.6327H1.75845C0.787284 53.6327 0 52.8454 0 51.8742V29.0292C0 28.5453 0.195488 28.107 0.511805 27.7891C0.363464 27.2491 0.465928 26.6361 0.907374 26.1493L24.0951 0.577251ZM1.75845 29.0329L1.75845 51.8742L45.7197 51.8742V29.0889H2.21003C2.05125 29.0889 1.90031 29.0693 1.75845 29.0329ZM47.4785 28.0858V50.8864L69.4591 26.5934V3.0091L47.4785 28.0858Z"
      fill="#222465"
    />
    <path
      d="M6.17456 28.1355V36.8278L9.77139 40.4247"
      stroke="#222465"
      strokeWidth="1.19894"
    />
    <path
      d="M5.2749 41.3238L7.97252 38.6262"
      stroke="#222465"
      strokeWidth="1.19894"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.3315 61.5459V64.775V64.9862L84.1822 65.1355L80.8888 68.4289L83.3327 70.8728L83.482 71.0221V71.2333V73.9522H84.8407H85.0519L85.2012 74.1015L87.7505 76.6508L87.0295 77.3719L84.6295 74.9719H83.482V76.6717H82.4623V71.4445L78.0436 67.0257L77.2145 67.8549L76.4934 67.1338L77.6831 65.9442L78.0436 65.5836L78.4041 65.9442L80.1678 67.7078L83.3118 64.5638V61.5459H84.3315Z"
      fill="#222465"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.302 68.5796V65.3505V65.1393L26.1526 64.99L22.8593 61.6966L25.3032 59.2527L25.4525 59.1034V58.8922V56.1733H26.8112H27.0224L27.1717 56.0239L29.721 53.4747L28.9999 52.7536L26.6 55.1536H25.4525V53.4538H24.4328V58.681L20.014 63.0998L19.1849 62.2706L18.4639 62.9917L19.6535 64.1813L20.014 64.5418L20.3746 64.1813L22.1382 62.4177L25.2822 65.5617V68.5796H26.302Z"
      fill="#222465"
    />
    <path
      d="M47.4778 70.778L50.4085 67.7007L56.27 73.8553"
      stroke="#222465"
      strokeWidth="0.879225"
    />
    <path
      d="M27.201 4.24591L26.1197 5.0466L26.026 4.063L27.201 4.24591Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M37.5154 14.4787L36.6465 13.0428L37.4865 12.5694L38.4987 13.565L37.5154 14.4787Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-11-inside-1_303_437" fill="white">
      <path d="M23.8994 13.635L23.4202 12.797L24.8087 12.5537L25.2879 13.3918L23.8994 13.635Z" />
    </mask>
    <path
      d="M23.8994 13.635L23.4202 12.797L24.8087 12.5537L25.2879 13.3918L23.8994 13.635Z"
      fill="#222465"
    />
    <path
      d="M23.8994 13.635L22.8818 14.217L23.2906 14.9319L24.1017 14.7898L23.8994 13.635ZM23.4202 12.797L23.2178 11.6423L21.5741 11.9303L22.4025 13.3789L23.4202 12.797ZM24.8087 12.5537L25.8263 11.9717L25.4175 11.2568L24.6063 11.399L24.8087 12.5537ZM25.2879 13.3918L25.4902 14.5465L27.134 14.2585L26.3056 12.8098L25.2879 13.3918ZM24.9171 13.0531L24.4378 12.215L22.4025 13.3789L22.8818 14.217L24.9171 13.0531ZM23.6225 13.9517L25.011 13.7084L24.6063 11.399L23.2178 11.6423L23.6225 13.9517ZM23.791 13.1356L24.2703 13.9737L26.3056 12.8098L25.8263 11.9717L23.791 13.1356ZM25.0856 12.237L23.6971 12.4803L24.1017 14.7898L25.4902 14.5465L25.0856 12.237Z"
      fill="#0BA1E2"
      mask="url(#path-11-inside-1_303_437)"
    />
    <path
      d="M36.5526 30.662L36.811 31.9123L34.9141 30.5225L36.5526 30.662Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M27.8348 9.12454L27.767 9.15314L27.586 8.94379L27.6539 8.91519L27.8348 9.12454Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M11.879 35.4198L11.9216 36.635L11.121 35.8415L11.879 35.4198Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-16-inside-2_303_437" fill="white">
      <path d="M17.777 38.1895L16.8772 37.6038L18.8688 35.2985L19.7686 35.8841L17.777 38.1895Z" />
    </mask>
    <path
      d="M17.777 38.1895L16.8772 37.6038L18.8688 35.2985L19.7686 35.8841L17.777 38.1895Z"
      fill="#222465"
    />
    <path
      d="M17.777 38.1895L17.1375 39.172L17.9952 39.7302L18.6641 38.9558L17.777 38.1895ZM16.8772 37.6038L15.9901 36.8375L15.1121 37.8538L16.2378 38.5864L16.8772 37.6038ZM18.8688 35.2985L19.5083 34.316L18.6506 33.7578L17.9817 34.5321L18.8688 35.2985ZM19.7686 35.8841L20.6557 36.6505L21.5336 35.6342L20.408 34.9016L19.7686 35.8841ZM18.4165 37.2069L17.5167 36.6213L16.2378 38.5864L17.1375 39.172L18.4165 37.2069ZM17.7643 38.3702L19.7559 36.0649L17.9817 34.5321L15.9901 36.8375L17.7643 38.3702ZM18.2293 36.281L19.1291 36.8666L20.408 34.9016L19.5083 34.316L18.2293 36.281ZM18.8815 35.1178L16.8899 37.4231L18.6641 38.9558L20.6557 36.6505L18.8815 35.1178Z"
      fill="#0BA1E2"
      mask="url(#path-16-inside-2_303_437)"
    />
    <path
      d="M25.6682 42.4635L24.059 41.6565L24.1188 40.9188L25.4785 41.274L25.6682 42.4635Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-19-inside-3_303_437" fill="white">
      <path d="M18.8999 30.5792L18.4352 29.7666L19.7839 29.5303L20.2486 30.3429L18.8999 30.5792Z" />
    </mask>
    <path
      d="M18.8999 30.5792L18.4352 29.7666L19.7839 29.5303L20.2486 30.3429L18.8999 30.5792Z"
      fill="#222465"
    />
    <path
      d="M18.8999 30.5792L17.8823 31.1611L18.2911 31.876L19.1022 31.7339L18.8999 30.5792ZM18.4352 29.7666L18.2329 28.6119L16.5892 28.8999L17.4176 30.3485L18.4352 29.7666ZM19.7839 29.5303L20.8016 28.9483L20.3928 28.2334L19.5816 28.3756L19.7839 29.5303ZM20.2486 30.3429L20.4509 31.4976L22.0947 31.2096L21.2663 29.7609L20.2486 30.3429ZM19.9176 29.9972L19.4529 29.1846L17.4176 30.3485L17.8823 31.1611L19.9176 29.9972ZM18.6375 30.9213L19.9862 30.685L19.5816 28.3756L18.2329 28.6119L18.6375 30.9213ZM18.7663 30.1122L19.2309 30.9248L21.2663 29.7609L20.8016 28.9483L18.7663 30.1122ZM20.0463 29.1882L18.6976 29.4245L19.1022 31.7339L20.4509 31.4976L20.0463 29.1882Z"
      fill="#0BA1E2"
      mask="url(#path-19-inside-3_303_437)"
    />
    <mask id="path-21-inside-4_303_437" fill="white">
      <path d="M35.6384 46.0726L35.1737 45.26L36.5224 45.0237L36.9871 45.8363L35.6384 46.0726Z" />
    </mask>
    <path
      d="M35.6384 46.0726L35.1737 45.26L36.5224 45.0237L36.9871 45.8363L35.6384 46.0726Z"
      fill="#222465"
    />
    <path
      d="M35.6384 46.0726L34.6208 46.6545L35.0296 47.3694L35.8408 47.2273L35.6384 46.0726ZM35.1737 45.26L34.9714 44.1053L33.3277 44.3933L34.1561 45.8419L35.1737 45.26ZM36.5224 45.0237L37.5401 44.4417L37.1313 43.7268L36.3201 43.869L36.5224 45.0237ZM36.9871 45.8363L37.1895 46.991L38.8332 46.703L38.0048 45.2543L36.9871 45.8363ZM36.6561 45.4907L36.1914 44.678L34.1561 45.8419L34.6208 46.6545L36.6561 45.4907ZM35.3761 46.4147L36.7248 46.1784L36.3201 43.869L34.9714 44.1053L35.3761 46.4147ZM35.5048 45.6056L35.9695 46.4182L38.0048 45.2543L37.5401 44.4417L35.5048 45.6056ZM36.7848 44.6816L35.4361 44.9179L35.8408 47.2273L37.1895 46.991L36.7848 44.6816Z"
      fill="#0BA1E2"
      mask="url(#path-21-inside-4_303_437)"
    />
    <path
      d="M34.7917 38.0606L33.9644 38.687L32.5939 37.9723L32.9326 37.3853L34.7917 38.0606Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M45.352 10.5868L44.3124 10.4549L44.1346 9.20748L45.1742 9.3394L45.352 10.5868Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M43.3904 22.0602L41.7805 21.594L42.7111 21.2364L43.3904 22.0602Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-26-inside-5_303_437" fill="white">
      <path d="M47.0444 16.1866L46.5652 15.3485L47.9573 15.1046L48.4365 15.9426L47.0444 16.1866Z" />
    </mask>
    <path
      d="M47.0444 16.1866L46.5652 15.3485L47.9573 15.1046L48.4365 15.9426L47.0444 16.1866Z"
      fill="#222465"
    />
    <path
      d="M47.0444 16.1866L46.0268 16.7685L46.4356 17.4834L47.2468 17.3413L47.0444 16.1866ZM46.5652 15.3485L46.3629 14.1938L44.7191 14.4818L45.5475 15.9304L46.5652 15.3485ZM47.9573 15.1046L48.975 14.5226L48.5661 13.8077L47.755 13.9499L47.9573 15.1046ZM48.4365 15.9426L48.6389 17.0973L50.2826 16.8093L49.4542 15.3607L48.4365 15.9426ZM48.0621 15.6046L47.5828 14.7665L45.5475 15.9304L46.0268 16.7685L48.0621 15.6046ZM46.7675 16.5032L48.1596 16.2593L47.755 13.9499L46.3629 14.1938L46.7675 16.5032ZM46.9396 15.6865L47.4189 16.5246L49.4542 15.3607L48.975 14.5226L46.9396 15.6865ZM48.2342 14.7879L46.8421 15.0318L47.2468 17.3413L48.6389 17.0973L48.2342 14.7879Z"
      fill="#0BA1E2"
      mask="url(#path-26-inside-5_303_437)"
    />
    <path
      d="M23.4503 25.1596L22.4798 25.0365L22.3115 23.8632L23.282 23.9862L23.4503 25.1596Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M56.4421 7.29433L54.833 6.48731L54.8927 5.74965L56.2525 6.10479L56.4421 7.29433Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-30-inside-6_303_437" fill="white">
      <path d="M66.4121 10.9032L65.9474 10.0906L67.2961 9.85424L67.7608 10.6668L66.4121 10.9032Z" />
    </mask>
    <path
      d="M66.4121 10.9032L65.9474 10.0906L67.2961 9.85424L67.7608 10.6668L66.4121 10.9032Z"
      fill="#222465"
    />
    <path
      d="M66.4121 10.9032L65.3945 11.4851L65.8033 12.2L66.6144 12.0579L66.4121 10.9032ZM65.9474 10.0906L65.7451 8.93584L64.1014 9.22385L64.9298 10.6725L65.9474 10.0906ZM67.2961 9.85424L68.3138 9.27229L67.905 8.55739L67.0938 8.69953L67.2961 9.85424ZM67.7608 10.6668L67.9631 11.8216L69.6069 11.5335L68.7785 10.0849L67.7608 10.6668ZM67.4298 10.3212L66.9651 9.50861L64.9298 10.6725L65.3945 11.4851L67.4298 10.3212ZM66.1498 11.2453L67.4985 11.0089L67.0938 8.69953L65.7451 8.93584L66.1498 11.2453ZM66.2785 10.4362L66.7431 11.2488L68.7785 10.0849L68.3138 9.27229L66.2785 10.4362ZM67.5585 9.51214L66.2098 9.74846L66.6144 12.0579L67.9631 11.8216L67.5585 9.51214Z"
      fill="#0BA1E2"
      mask="url(#path-30-inside-6_303_437)"
    />
    <path
      d="M64.9378 19.3765L63.0233 20.7943L62.8573 19.0526L64.9378 19.3765Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M77.6403 32.7638L76.3455 30.6241L77.665 29.8803L79.1605 31.3512L77.6403 32.7638Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-34-inside-7_303_437" fill="white">
      <path d="M60.3289 31.4372L59.7184 30.3696L61.4873 30.0596L62.0978 31.1273L60.3289 31.4372Z" />
    </mask>
    <path
      d="M60.3289 31.4372L59.7184 30.3696L61.4873 30.0596L62.0978 31.1273L60.3289 31.4372Z"
      fill="#222465"
    />
    <path
      d="M60.3289 31.4372L59.3113 32.0192L59.7201 32.7341L60.5313 32.5919L60.3289 31.4372ZM59.7184 30.3696L59.5161 29.2149L57.8724 29.5029L58.7008 30.9515L59.7184 30.3696ZM61.4873 30.0596L62.5049 29.4777L62.0961 28.7628L61.2849 28.9049L61.4873 30.0596ZM62.0978 31.1273L62.3001 32.282L63.9439 31.994L63.1155 30.5453L62.0978 31.1273ZM61.3466 30.8553L60.7361 29.7876L58.7008 30.9515L59.3113 32.0192L61.3466 30.8553ZM59.9207 31.5243L61.6896 31.2143L61.2849 28.9049L59.5161 29.2149L59.9207 31.5243ZM60.4696 30.6416L61.0801 31.7092L63.1155 30.5453L62.5049 29.4777L60.4696 30.6416ZM61.8955 29.9726L60.1266 30.2825L60.5313 32.5919L62.3001 32.282L61.8955 29.9726Z"
      fill="#0BA1E2"
      mask="url(#path-34-inside-7_303_437)"
    />
    <path
      d="M65.6088 25.7533L65.2112 25.9208L64.7598 25.3987L65.1574 25.2312L65.6088 25.7533Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M45.1664 58.9217L45.2442 61.1367L43.7846 59.6904L45.1664 58.9217Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M54.185 59.9062L52.4213 61.9477L52.2667 61.847L54.0304 59.8055L54.185 59.9062Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M62.7899 68.4469L60.3633 67.2299L60.4636 65.9915L62.4825 66.5188L62.7899 68.4469Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-40-inside-8_303_437" fill="white">
      <path d="M75.2825 72.7604L74.6905 71.7252L76.4086 71.4241L77.0006 72.4593L75.2825 72.7604Z" />
    </mask>
    <path
      d="M75.2825 72.7604L74.6905 71.7252L76.4086 71.4241L77.0006 72.4593L75.2825 72.7604Z"
      fill="#222465"
    />
    <path
      d="M75.2825 72.7604L74.2648 73.3423L74.6736 74.0572L75.4848 73.9151L75.2825 72.7604ZM74.6905 71.7252L74.4882 70.5705L72.8444 70.8585L73.6728 72.3071L74.6905 71.7252ZM76.4086 71.4241L77.4263 70.8422L77.0175 70.1273L76.2063 70.2694L76.4086 71.4241ZM77.0006 72.4593L77.2029 73.6141L78.8467 73.326L78.0183 71.8774L77.0006 72.4593ZM76.3001 72.1785L75.7082 71.1432L73.6728 72.3071L74.2648 73.3423L76.3001 72.1785ZM74.8928 72.8799L76.611 72.5788L76.2063 70.2694L74.4882 70.5705L74.8928 72.8799ZM75.391 72.0061L75.983 73.0413L78.0183 71.8774L77.4263 70.8422L75.391 72.0061ZM76.7983 71.3046L75.0801 71.6057L75.4848 73.9151L77.2029 73.6141L76.7983 71.3046Z"
      fill="#0BA1E2"
      mask="url(#path-40-inside-8_303_437)"
    />
    <path
      d="M74.5383 62.5036L73.168 63.5412L71.1833 62.5062L71.7658 61.4966L74.5383 62.5036Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M85.6141 42.4692L82.6054 41.5979L84.3446 40.9296L85.6141 42.4692Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-44-inside-9_303_437" fill="white">
      <path d="M89.8133 34.6877L89.2028 33.6201L90.9762 33.3093L91.5868 34.377L89.8133 34.6877Z" />
    </mask>
    <path
      d="M89.8133 34.6877L89.2028 33.6201L90.9762 33.3093L91.5868 34.377L89.8133 34.6877Z"
      fill="#222465"
    />
    <path
      d="M89.8133 34.6877L88.7957 35.2696L89.2045 35.9845L90.0156 35.8424L89.8133 34.6877ZM89.2028 33.6201L89.0005 32.4653L87.3567 32.7534L88.1851 34.202L89.2028 33.6201ZM90.9762 33.3093L91.9939 32.7274L91.5851 32.0125L90.7739 32.1546L90.9762 33.3093ZM91.5868 34.377L91.7891 35.5317L93.4328 35.2437L92.6044 33.795L91.5868 34.377ZM90.831 34.1058L90.2205 33.0381L88.1851 34.202L88.7957 35.2696L90.831 34.1058ZM89.4051 34.7748L91.1786 34.464L90.7739 32.1546L89.0005 32.4653L89.4051 34.7748ZM89.9586 33.8913L90.5691 34.9589L92.6044 33.795L91.9939 32.7274L89.9586 33.8913ZM91.3844 33.2223L89.611 33.533L90.0156 35.8424L91.7891 35.5317L91.3844 33.2223Z"
      fill="#0BA1E2"
      mask="url(#path-44-inside-9_303_437)"
    />
    <path
      d="M59.9463 46.3042L58.3795 46.1056L58.1179 44.2811L59.6847 44.4797L59.9463 46.3042Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M21.0452 48.2073L19.964 49.008L19.8703 48.0244L21.0452 48.2073Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <path
      d="M31.3606 58.4399L30.4918 57.004L31.3317 56.5306L32.344 57.5262L31.3606 58.4399Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-49-inside-10_303_437" fill="white">
      <path d="M17.7437 57.5962L17.2644 56.7582L18.6529 56.5149L19.1322 57.3529L17.7437 57.5962Z" />
    </mask>
    <path
      d="M17.7437 57.5962L17.2644 56.7582L18.6529 56.5149L19.1322 57.3529L17.7437 57.5962Z"
      fill="#222465"
    />
    <path
      d="M17.7437 57.5962L16.726 58.1782L17.1348 58.8931L17.946 58.7509L17.7437 57.5962ZM17.2644 56.7582L17.0621 55.6034L15.4184 55.8915L16.2468 57.3401L17.2644 56.7582ZM18.6529 56.5149L19.6706 55.9329L19.2618 55.218L18.4506 55.3602L18.6529 56.5149ZM19.1322 57.3529L19.3345 58.5076L20.9782 58.2196L20.1498 56.771L19.1322 57.3529ZM18.7613 57.0143L18.2821 56.1762L16.2468 57.3401L16.726 58.1782L18.7613 57.0143ZM17.4667 57.9129L18.8552 57.6696L18.4506 55.3602L17.0621 55.6034L17.4667 57.9129ZM17.6352 57.0968L18.1145 57.9349L20.1498 56.771L19.6706 55.9329L17.6352 57.0968ZM18.9298 56.1982L17.5413 56.4415L17.946 58.7509L19.3345 58.5076L18.9298 56.1982Z"
      fill="#0BA1E2"
      mask="url(#path-49-inside-10_303_437)"
    />
    <mask id="path-51-inside-11_303_437" fill="white">
      <path d="M12.7451 74.5404L12.2804 73.7278L13.6291 73.4914L14.0938 74.3041L12.7451 74.5404Z" />
    </mask>
    <path
      d="M12.7451 74.5404L12.2804 73.7278L13.6291 73.4914L14.0938 74.3041L12.7451 74.5404Z"
      fill="#222465"
    />
    <path
      d="M12.7451 74.5404L11.7275 75.1223L12.1363 75.8372L12.9475 75.6951L12.7451 74.5404ZM12.2804 73.7278L12.0781 72.573L10.4344 72.8611L11.2628 74.3097L12.2804 73.7278ZM13.6291 73.4914L14.6468 72.9095L14.238 72.1946L13.4268 72.3367L13.6291 73.4914ZM14.0938 74.3041L14.2961 75.4588L15.9399 75.1707L15.1115 73.7221L14.0938 74.3041ZM13.7628 73.9584L13.2981 73.1458L11.2628 74.3097L11.7275 75.1223L13.7628 73.9584ZM12.4828 74.8825L13.8315 74.6461L13.4268 72.3367L12.0781 72.573L12.4828 74.8825ZM12.6115 74.0734L13.0762 74.886L15.1115 73.7221L14.6468 72.9095L12.6115 74.0734ZM13.8915 73.1493L12.5428 73.3857L12.9475 75.6951L14.2961 75.4588L13.8915 73.1493Z"
      fill="#0BA1E2"
      mask="url(#path-51-inside-11_303_437)"
    />
    <path
      d="M37.2358 66.0217L35.6259 65.5554L36.5565 65.1978L37.2358 66.0217Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-54-inside-12_303_437" fill="white">
      <path d="M40.8897 60.1477L40.4104 59.3097L41.8025 59.0657L42.2818 59.9038L40.8897 60.1477Z" />
    </mask>
    <path
      d="M40.8897 60.1477L40.4104 59.3097L41.8025 59.0657L42.2818 59.9038L40.8897 60.1477Z"
      fill="#222465"
    />
    <path
      d="M40.8897 60.1477L39.872 60.7297L40.2808 61.4446L41.092 61.3024L40.8897 60.1477ZM40.4104 59.3097L40.2081 58.155L38.5644 58.443L39.3927 59.8916L40.4104 59.3097ZM41.8025 59.0657L42.8202 58.4838L42.4114 57.7689L41.6002 57.911L41.8025 59.0657ZM42.2818 59.9038L42.4841 61.0585L44.1278 60.7705L43.2994 59.3219L42.2818 59.9038ZM41.9073 59.5658L41.4281 58.7277L39.3927 59.8916L39.872 60.7297L41.9073 59.5658ZM40.6127 60.4644L42.0048 60.2205L41.6002 57.911L40.2081 58.155L40.6127 60.4644ZM40.7849 59.6477L41.2641 60.4858L43.2994 59.3219L42.8202 58.4838L40.7849 59.6477ZM42.0794 58.7491L40.6873 58.993L41.092 61.3024L42.4841 61.0585L42.0794 58.7491Z"
      fill="#0BA1E2"
      mask="url(#path-54-inside-12_303_437)"
    />
    <path
      d="M17.2957 69.1208L16.3252 68.9977L16.157 67.8244L17.1275 67.9474L17.2957 69.1208Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-57-inside-13_303_437" fill="white">
      <path d="M113.581 31.2195L113.101 30.3814L114.49 30.1381L114.969 30.9762L113.581 31.2195Z" />
    </mask>
    <path
      d="M113.581 31.2195L113.101 30.3814L114.49 30.1381L114.969 30.9762L113.581 31.2195Z"
      fill="#222465"
    />
    <path
      d="M113.581 31.2195L112.563 31.8015L112.972 32.5164L113.783 32.3742L113.581 31.2195ZM113.101 30.3814L112.899 29.2267L111.255 29.5147L112.084 30.9634L113.101 30.3814ZM114.49 30.1381L115.507 29.5562L115.099 28.8413L114.287 28.9834L114.49 30.1381ZM114.969 30.9762L115.171 32.1309L116.815 31.8429L115.987 30.3943L114.969 30.9762ZM114.598 30.6376L114.119 29.7995L112.084 30.9634L112.563 31.8015L114.598 30.6376ZM113.304 31.5362L114.692 31.2929L114.287 28.9834L112.899 29.2267L113.304 31.5362ZM113.472 30.7201L113.951 31.5582L115.987 30.3943L115.507 29.5562L113.472 30.7201ZM114.767 29.8215L113.378 30.0648L113.783 32.3742L115.171 32.1309L114.767 29.8215Z"
      fill="#0BA1E2"
      mask="url(#path-57-inside-13_303_437)"
    />
    <path
      d="M101.56 53.0043L101.603 54.2195L100.802 53.426L101.56 53.0043Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
    <mask id="path-60-inside-14_303_437" fill="white">
      <path d="M107.459 55.7739L106.559 55.1883L108.551 52.883L109.451 53.4686L107.459 55.7739Z" />
    </mask>
    <path
      d="M107.459 55.7739L106.559 55.1883L108.551 52.883L109.451 53.4686L107.459 55.7739Z"
      fill="#222465"
    />
    <path
      d="M107.459 55.7739L106.82 56.7565L107.677 57.3146L108.346 56.5403L107.459 55.7739ZM106.559 55.1883L105.672 54.4219L104.794 55.4382L105.92 56.1708L106.559 55.1883ZM108.551 52.883L109.19 51.9005L108.333 51.3423L107.664 52.1166L108.551 52.883ZM109.451 53.4686L110.338 54.235L111.216 53.2187L110.09 52.4861L109.451 53.4686ZM108.099 54.7914L107.199 54.2058L105.92 56.1708L106.82 56.7565L108.099 54.7914ZM107.446 55.9547L109.438 53.6493L107.664 52.1166L105.672 54.4219L107.446 55.9547ZM107.911 53.8655L108.811 54.4511L110.09 52.4861L109.19 51.9005L107.911 53.8655ZM108.564 52.7022L106.572 55.0076L108.346 56.5403L110.338 54.235L108.564 52.7022Z"
      fill="#0BA1E2"
      mask="url(#path-60-inside-14_303_437)"
    />
    <mask id="path-62-inside-15_303_437" fill="white">
      <path d="M108.582 48.1637L108.117 47.351L109.466 47.1147L109.931 47.9273L108.582 48.1637Z" />
    </mask>
    <path
      d="M108.582 48.1637L108.117 47.351L109.466 47.1147L109.931 47.9273L108.582 48.1637Z"
      fill="#222465"
    />
    <path
      d="M108.582 48.1637L107.564 48.7456L107.973 49.4605L108.784 49.3184L108.582 48.1637ZM108.117 47.351L107.915 46.1963L106.271 46.4844L107.1 47.933L108.117 47.351ZM109.466 47.1147L110.484 46.5328L110.075 45.8179L109.264 45.96L109.466 47.1147ZM109.931 47.9273L110.133 49.0821L111.777 48.794L110.948 47.3454L109.931 47.9273ZM109.6 47.5817L109.135 46.7691L107.1 47.933L107.564 48.7456L109.6 47.5817ZM108.32 48.5058L109.668 48.2694L109.264 45.96L107.915 46.1963L108.32 48.5058ZM108.448 47.6967L108.913 48.5093L110.948 47.3454L110.484 46.5328L108.448 47.6967ZM109.728 46.7726L108.38 47.009L108.784 49.3184L110.133 49.0821L109.728 46.7726Z"
      fill="#0BA1E2"
      mask="url(#path-62-inside-15_303_437)"
    />
    <path
      d="M113.132 42.7438L112.162 42.6208L111.994 41.4474L112.964 41.5704L113.132 42.7438Z"
      fill="#222465"
      stroke="#0BA1E2"
      strokeWidth="1.1723"
    />
  </svg>
);

export default ConcreteIcon;
