const getLanguageAndCountryFromLocale = (locale: string) => {
  const isRegionalLocale = locale.startsWith('de-') || locale.startsWith('en-');
  const splitLanguage = locale.split('-');

  const hasCountryCode =
    splitLanguage.length > 1 &&
    splitLanguage[splitLanguage.length - 1].length === 2;

  if (hasCountryCode) {
    const [languageCode, countryCode] =
      splitLanguage.length === 3
        ? [splitLanguage.slice(0, 2).join('-'), splitLanguage[2]] : isRegionalLocale ? [locale, ''] 
        : splitLanguage;
        
    return { languageCode, countryCode, hasCountryCode };
  }
  return { languageCode: locale, hasCountryCode };
};

export default getLanguageAndCountryFromLocale;
