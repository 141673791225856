export const parseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return jsonString;
  }
};

export const parseObjectToJson = (object: { [key: string]: string | number | null | boolean }) => {
  try {
    return JSON.stringify(object);
  } catch (error) {
    return '';
  }
};
