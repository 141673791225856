import { useEffect, useState } from 'react';
import Loader from 'components/loader';
import useGetSteelCrossSectionalShapes from 'pages/steel/api/useGetSteelCrossSectionalShapes';
import useGetSteelProfileTypesSubtypes from 'pages/steel/api/useGetSteelProfileTypesSubtypes';
import { CUSTOM_PROFILE } from 'pages/steel/constants';
import { SteelStructuralElementsValues } from 'pages/steel/types';
import CustomProfileInputs from '../custom-profile-inputs';
import StandardProfileInputs from '../standard-profile-inputs';
import { LoaderWrapper, Wrapper } from './styles';

interface ProfileShapeInputsProps {
  values: SteelStructuralElementsValues;
}

const ProfileShapeInputs = ({ values }: ProfileShapeInputsProps) => {
  const [profileShape, setProfileShape] = useState<number>();
  const { steelCrossSectionalShapes, isLoading: crossSectionalShapesLoading } =
    useGetSteelCrossSectionalShapes();
  const { steelProfileTypesSubtypes, isLoading: profileTypesLoading } =
    useGetSteelProfileTypesSubtypes(profileShape);
  const customProfileSelected = values.profileShape === CUSTOM_PROFILE;
  const isLoading = customProfileSelected
    ? crossSectionalShapesLoading
    : profileTypesLoading;

  useEffect(() => {
    if (values.profileShape && !customProfileSelected) {
      setProfileShape(Number(values.profileShape));
    }
  }, [values.profileShape]);

  return isLoading ? (
    <LoaderWrapper>
      <Loader fullScreen={false} />
    </LoaderWrapper>
  ) : (
    <Wrapper>
      {customProfileSelected ? (
        <CustomProfileInputs
          steelCrossSectionalShapes={steelCrossSectionalShapes}
        />
      ) : (
        <StandardProfileInputs
          values={values}
          steelProfileTypesSubtypes={steelProfileTypesSubtypes}
        />
      )}
    </Wrapper>
  );
};
export default ProfileShapeInputs;
