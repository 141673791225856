import { useTranslation } from 'react-i18next';
import useGetCountries from 'api/useGetCountries';
import Select from 'components/select';
import { Language } from 'shared/types';
import { setIsLanguageChanging } from 'store/calculator/slices/calculator';
import { useAppDispatch } from 'store/hooks';
import {
  mapLanguages,
  translateLangNamesToLocalLang,
  updatePathOnLanguageChange,
} from './logic';

interface LanguageSelectProps {
  languages: Language[];
  selectedLanguage: string;
}

const LanguageSelect = ({
  languages,
  selectedLanguage,
}: LanguageSelectProps) => {
  const { t } = useTranslation();
  const { countries } = useGetCountries();
  const dispatch = useAppDispatch();
  const languagesWithTranslatedNames = translateLangNamesToLocalLang(
    t,
    languages
  );
  const mappedLanguages = mapLanguages(languagesWithTranslatedNames);

  const changeLanguage = (value: string) =>
    updatePathOnLanguageChange(value, languages, countries);

  return (
    <Select
      onOpen={() => dispatch(setIsLanguageChanging(true))}
      onClose={() => dispatch(setIsLanguageChanging(false))}
      items={mappedLanguages}
      small
      value={selectedLanguage}
      onChange={changeLanguage}
      showLabel={false}
      label={t('languageSelect.selectLanguage') ?? ''}
    />
  );
};

export default LanguageSelect;
