const TubeIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.0455 69.9995C45.2837 69.9995 44.6197 69.4839 44.4322 68.7456C42.7681 62.2886 37.7291 57.2416 31.2762 55.5616C30.3816 55.3311 29.8465 54.4249 30.077 53.5343C30.3074 52.6397 31.2176 52.1046 32.1082 52.3351C39.7371 54.3156 45.6942 60.2843 47.6632 67.9171C47.7882 68.4171 47.6788 68.9483 47.3624 69.3546C47.046 69.7608 46.5616 69.9991 46.046 69.9991L46.0455 69.9995Z"
      fill="#222465"
    />
    <path
      d="M52.4715 63.4796C51.7098 63.4796 51.0457 62.964 50.8582 62.2257C49.1941 55.7687 44.1551 50.7217 37.7022 49.0417C37.1241 48.8933 36.671 48.448 36.5108 47.8737C36.3506 47.3034 36.5108 46.6862 36.9288 46.2604C37.3467 45.8386 37.9561 45.6667 38.5343 45.8151C46.1671 47.7956 52.1203 53.7643 54.0893 61.3971C54.2143 61.8971 54.1049 62.4283 53.7885 62.8346C53.4721 63.2409 52.9877 63.4792 52.4721 63.4792L52.4715 63.4796Z"
      fill="#222465"
    />
    <path
      d="M58.897 56.9596C58.1353 56.9596 57.4712 56.444 57.2837 55.7057C55.6196 49.2487 50.5806 44.2017 44.1277 42.5217C43.5496 42.3733 43.0965 41.928 42.9363 41.3537C42.7762 40.7834 42.9363 40.1662 43.3543 39.7404C43.7723 39.3185 44.3816 39.1467 44.9598 39.2951C52.5926 41.2756 58.5458 47.2443 60.5148 54.8771C60.6398 55.3771 60.5304 55.9083 60.214 56.3146C59.8976 56.7209 59.4132 56.9591 58.8976 56.9591L58.897 56.9596Z"
      fill="#222465"
    />
    <path
      d="M65.3224 50.4405C64.5606 50.4405 63.8966 49.9249 63.7091 49.1866C62.045 42.7296 57.006 37.6826 50.5531 36.0026C49.9749 35.8542 49.5219 35.4089 49.3617 34.8346C49.2054 34.2643 49.3617 33.6471 49.7796 33.2213C50.1976 32.7994 50.8108 32.6276 51.3851 32.776C59.0179 34.7565 64.9711 40.7252 66.9401 48.358C67.0651 48.858 66.9557 49.3892 66.6393 49.7955C66.3229 50.2018 65.8386 50.44 65.3229 50.44L65.3224 50.4405Z"
      fill="#222465"
    />
    <path
      d="M71.7479 43.9205C70.9862 43.9205 70.326 43.4049 70.1346 42.6666C68.4705 36.2096 63.4315 31.1626 56.9786 29.4826C56.4005 29.3342 55.9474 28.8889 55.7872 28.3146C55.6309 27.7443 55.7872 27.1271 56.2052 26.7013C56.6231 26.2794 57.2364 26.1076 57.8107 26.256C65.4435 28.2365 71.3967 34.2052 73.3657 41.838C73.4907 42.338 73.3813 42.8692 73.0649 43.2755C72.7485 43.6818 72.2641 43.92 71.7485 43.92L71.7479 43.9205Z"
      fill="#222465"
    />
    <path
      d="M78.1742 37.4016C77.4164 37.4016 76.7523 36.886 76.5609 36.1477C74.8968 29.6907 69.8578 24.6437 63.4049 22.9637C62.8267 22.8152 62.3737 22.3699 62.2135 21.7957C62.0572 21.2254 62.2135 20.6082 62.6314 20.1824C63.0494 19.7605 63.6626 19.5886 64.2369 19.7371C71.8697 21.7176 77.8229 27.6863 79.7919 35.3191C79.9169 35.8191 79.8076 36.3503 79.4912 36.7566C79.1748 37.1629 78.6904 37.4011 78.1748 37.4011L78.1742 37.4016Z"
      fill="#222465"
    />
    <path
      d="M84.6004 30.8816C83.8426 30.8816 83.1785 30.3659 82.9871 29.6277C81.323 23.1707 76.284 18.1237 69.8311 16.4437C69.253 16.2952 68.7999 15.8499 68.6397 15.2796C68.4835 14.7053 68.6397 14.0882 69.0577 13.6624C69.4757 13.2405 70.0889 13.0686 70.6632 13.2171C78.296 15.2015 84.2492 21.1663 86.2182 28.7991C86.3432 29.2991 86.2338 29.8303 85.9174 30.2366C85.6049 30.6428 85.1166 30.8811 84.601 30.8811L84.6004 30.8816Z"
      fill="#222465"
    />
    <path
      d="M91.0258 24.3626C90.268 24.3626 89.6039 23.847 89.4125 23.1087C87.7484 16.6517 82.7094 11.6047 76.2565 9.92474C75.6784 9.7763 75.2253 9.33099 75.069 8.76064C74.9089 8.18642 75.0651 7.56924 75.4831 7.14344C75.9011 6.72157 76.5143 6.54969 77.0886 6.69813C84.7214 8.67863 90.6746 14.6473 92.6436 22.2801C92.7686 22.7801 92.6592 23.3113 92.3428 23.7176C92.0303 24.1239 91.542 24.3622 91.0264 24.3622L91.0258 24.3626Z"
      fill="#222465"
    />
    <path
      d="M21.6742 99.9676C27.4203 99.9793 32.9322 97.6981 36.9942 93.6317L93.6582 36.9677C102.103 28.5029 102.092 14.7997 93.6387 6.34666C85.1856 -2.10644 71.4827 -2.11814 63.0177 6.32713L6.35366 62.9911C0.150555 69.1864 -1.70494 78.5071 1.65056 86.6001C5.00606 94.6978 12.9086 99.9751 21.6736 99.9671L21.6742 99.9676ZM65.3772 8.68256C72.5334 1.52236 84.1432 1.52236 91.3032 8.68256C98.4632 15.8428 98.4634 27.4526 91.3032 34.6086L42.7642 83.1516C44.4478 75.886 42.2681 68.2646 36.9947 62.9916C31.7213 57.7182 24.0997 55.5385 16.8347 57.2221L65.3772 8.68256ZM8.70919 65.3506C13.9514 60.1045 21.8382 58.5381 28.6892 61.374C35.5408 64.2138 40.0052 70.8974 40.0052 78.312C40.0052 85.73 35.5404 92.414 28.6892 95.25C21.8376 98.0898 13.9512 96.5195 8.70919 91.2773C5.25609 87.8476 3.31469 83.1796 3.31469 78.3123C3.31469 73.4451 5.25609 68.7811 8.70919 65.3513V65.3506Z"
      fill="#222465"
    />
    <path
      d="M21.6739 93.3116C27.7403 93.3116 33.2089 89.6593 35.5289 84.0538C37.8531 78.4483 36.568 71.9948 32.2789 67.7058C27.9898 63.4167 21.5369 62.1316 15.9309 64.4558C10.3254 66.7761 6.6731 72.2449 6.6731 78.3108C6.68091 86.592 13.3919 93.3028 21.6731 93.3108L21.6739 93.3116ZM21.6739 66.6476C28.1153 66.6476 33.3379 71.8703 33.3379 78.3116C33.3379 84.7569 28.1152 89.9796 21.6739 89.9796C15.2286 89.9796 10.0059 84.7569 10.0059 78.3116C10.0137 71.8741 15.2325 66.6556 21.6739 66.6476Z"
      fill="#222465"
    />
  </svg>
);

export default TubeIcon;
