import { LoaderOverlay, SpinningLoader } from './styles';

interface LoaderProps {
  fullScreen?: boolean;
}

const Loader = ({ fullScreen = true }: LoaderProps) =>
  fullScreen ? (
    <LoaderOverlay>
      <SpinningLoader />
    </LoaderOverlay>
  ) : (
    <SpinningLoader />
  );

export default Loader;
