import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'shared/hooks/useIsMobile';
import Button from '../../button';
import { ButtonVariant } from '../../button/types';
import { getUrlParams } from './logic';
import {
  ButtonWrapper,
  HubspotFormSubmittedWrapper,
  HubspotFormWrapper,
  StyledEmailIcon,
} from './styles';

type HubSpotFormProps = {
  portalId: string;
  formId: string;
  region: string;
  onFormSubmitted: () => void;
  onDoneClick: () => void;
};

const HubspotForm = ({
  portalId,
  formId,
  region,
  onFormSubmitted,
  onDoneClick,
}: HubSpotFormProps) => {
  const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);

  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const url = getUrlParams();

  const transitionToNextModalView = () => {
    setWasSubmitted(true);
    onFormSubmitted();
  };

  const setPropertyField = (form: HTMLElement) => {
    const urlField = form.querySelector<HTMLInputElement>(
      '[name="etex_source_details__c"]'
    );
    if (urlField) {
      urlField.value = url;
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region,
          portalId,
          formId,
          target: '#hubspotForm',
          onFormSubmit: setPropertyField,
          onFormSubmitted: transitionToNextModalView,
        });
      }
    });
  }, []);

  return (
    <div>
      {wasSubmitted && (
        <HubspotFormSubmittedWrapper>
          <StyledEmailIcon />
        </HubspotFormSubmittedWrapper>
      )}

      <HubspotFormWrapper
        id="hubspotForm"
        $fullWidth={isMobile}
        onSubmit={(event: FormEvent<HTMLDivElement>) => event.stopPropagation()}
        data-testid="hubspotForm"
      />

      {wasSubmitted && (
        <HubspotFormSubmittedWrapper>
          <ButtonWrapper>
            <Button
              content={t('modal.done')}
              onClick={onDoneClick}
              variant={ButtonVariant.filled}
            />
          </ButtonWrapper>
        </HubspotFormSubmittedWrapper>
      )}
    </div>
  );
};

export default HubspotForm;
