import { ROUTES } from 'shared/constants';
import getLanguageAndCountryFromLocale from './getLanguageAndCountryFromLocale';

const setInitialLanguageAttribute = () => {
  const locale = window.location.pathname.split('/')[1];
  const { languageCode } = getLanguageAndCountryFromLocale(locale);

  document.documentElement.lang = languageCode;

  Object.values(ROUTES).forEach((route) => {
    const cleanRoute = route.replace('/', '');
    if (locale === cleanRoute) {
      document.documentElement.lang = navigator.language;
    }
  });
};

export default setInitialLanguageAttribute;
