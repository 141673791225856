import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import FormWrapper from 'components/form-wrapper';
import Loader from 'components/loader';
import Steps from 'components/steps';
import BottomActions from 'features/bottom-actions';
import FireRatings from 'features/fire-ratings';
import { STEEL_ROUTES } from 'shared/constants';
import useIsFormFilled from 'shared/hooks/useIsFormFilled';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import useSteelHasProtectedSides from 'shared/hooks/useSteelHasProtectedSides';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { LoadbearingStructure, ProgressStep } from 'shared/types';
import useGetSteelCriticalTemperatures from '../api/useGetSteelCriticalTemperatures';
import useGetSteelFireRatings from '../api/useGetSteelFireRatings';
import { SteelFireRatingValues } from '../types';
import CriticalTemperature from './components/critical-temperature';
import { TEMPERATURE_OTHER } from './constants';
import { StyledForm } from './styles';
import { requiredFieldsValidationSchema, validationSchema } from './validation';

const emptyValues = {
  fireRating: undefined,
  criticalTemperature: undefined,
  customCriticalTemperature: undefined,
};

const SteelFireRating = () => {
  const navigate = useNavigate();
  const { fireRatings, isLoading: isFireRatingsLoading } =
    useGetSteelFireRatings();
  const { criticalTemperatures, isLoading: isTemperaturesLoading } =
    useGetSteelCriticalTemperatures();
  const hasProtectedSides = useSteelHasProtectedSides();
  const progressSteps = useProgressSteps(
    LoadbearingStructure.steel,
    hasProtectedSides
  );
  const [storedValues, setStoredValues] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelFireRating
  );
  const isProductKnown = useIsProductKnown();
  const initialValues = storedValues ?? emptyValues;
  const isLoading = isFireRatingsLoading || isTemperaturesLoading;
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);

  const handleBackClick = () => {
    removeFromSession(SESSION_STORAGE_KEYS.steelFireRating);
    navigate(STEEL_ROUTES.environment);
  };

  const handleSubmit = (values: SteelFireRatingValues) => {
    setStoredValues(values);
    navigate(
      isProductKnown
        ? `${STEEL_ROUTES.productDetails}/${product.id}`
        : STEEL_ROUTES.results
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, touched, errors, resetForm }) => {
        const isFormFilled = useIsFormFilled(
          values,
          requiredFieldsValidationSchema
        );
        if (
          values.customCriticalTemperature &&
          values.criticalTemperature !== TEMPERATURE_OTHER.id
        ) {
          resetForm({
            values: {
              ...values,
              customCriticalTemperature: emptyValues.customCriticalTemperature,
            },
          });
        }

        return (
          <StyledForm>
            <FormWrapper>
              <Steps items={progressSteps} active={ProgressStep.fireRating} />
              {fireRatings && (
                <FireRatings
                  fireRatingValues={fireRatings}
                  selectedFireRating={values.fireRating}
                  error={errors.fireRating as string}
                  touched={!!touched.fireRating}
                />
              )}
              {criticalTemperatures && (
                <CriticalTemperature
                  temperatures={criticalTemperatures}
                  selectedTemperature={values.criticalTemperature}
                />
              )}
            </FormWrapper>
            <BottomActions
              onBackClick={handleBackClick}
              hasContinueBtn
              isContinueDisabled={!isFormFilled}
            />
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default SteelFireRating;
