import { useEffect } from 'react';
import { selectIsLanguageChanging } from 'store/calculator/selectors/calculator';
import { useAppSelector } from 'store/hooks';

const useBeforeUnloadEffect = () => {
  const isLanguageChanging = useAppSelector(selectIsLanguageChanging);
  const sessionStorageEmpty = window.sessionStorage.length === 0;

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (sessionStorageEmpty || isLanguageChanging) {
        return;
      }
    e.preventDefault();

      return (e.returnValue = '');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isLanguageChanging, sessionStorageEmpty]);
};

export default useBeforeUnloadEffect;
