import useQuery from 'shared/hooks/useQuery';
import { Language } from 'shared/types';

const useGetLanguages = () => {
  const { data, error, isLoading, isError } = useQuery<Language[]>(
    'languages',
    'v1/languages',
    [],
    { staleTime: Infinity, cacheTime: Infinity }
  );

  return {
    languages: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetLanguages;
