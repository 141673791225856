import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { radii, sizing, spacing } from 'styles/helpers';
import { Theme } from 'styles/theme';
import { ButtonVariant } from './types';

const variantStyles = (theme: Theme, variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.grey:
      return css`
        background-color: ${theme.colors.background};
        color: ${theme.colors.black};
      `;
    case ButtonVariant.error:
      return css`
        background-color: ${theme.colors.error};
        color: ${theme.colors.white};
      `;
    case ButtonVariant.disabled:
      return css`
        background-color: ${theme.colors.buttonDisabled};
        color: ${theme.colors.white};
      `;
    case ButtonVariant.filled:
      return css`
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      `;
    default:
      return css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.primary};
      `;
  }
};

const hoverStyles = (theme: Theme, variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.filled:
      return css`
        background-color: ${theme.colors.hover};
      `;
    case ButtonVariant.outlined:
      return css`
        background-color: ${theme.colors.backgroundLight};
        border: ${theme.border.hover};
        color: ${theme.colors.hover};
      `;
  }
};

const disabledStyles = (theme: Theme, variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.filled:
      return css`
        background-color: ${theme.colors.buttonDisabled};
      `;
    case ButtonVariant.outlined:
      return css`
        background-color: ${theme.colors.white};
        border: ${theme.border.default};
        color: ${theme.colors.buttonDisabled};
      `;
  }
};

const buttonStyles = (variant: ButtonVariant, fullWidth?: boolean) => css`
  ${({ theme }) => variantStyles(theme, variant)};
  align-items: center;
  border: ${({ theme }) =>
    variant === ButtonVariant.outlined ? theme.border.primary : 0};
  border-radius: ${radii.s};
  display: flex;
  gap: ${spacing['2xs']};
  height: ${variant === ButtonVariant.default ? sizing(8) : sizing(10)};
  justify-content: center;
  padding: ${variant === ButtonVariant.default
    ? `${spacing['2xs']} 0`
    : `${spacing.xs} 14px`};
  width: ${fullWidth ? '100%' : 'fit-content'};

  &:disabled {
    ${({ theme }) => disabledStyles(theme, variant)}
    cursor: default;
  }

  &:not(:disabled) {
    &:active,
    &:focus {
      ${({ theme }) => hoverStyles(theme, variant)};
    }
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      ${({ theme }) => hoverStyles(theme, variant)};
    }
  }
`;

export const StyledButton = styled.button<{
  $fullWidth?: boolean;
  variant: ButtonVariant;
}>`
  ${({ $fullWidth, variant }) => buttonStyles(variant, $fullWidth)};
`;

export const StyledLink = styled(Link)<{
  $fullWidth?: boolean;
  variant: ButtonVariant;
}>`
  ${({ $fullWidth, variant }) => buttonStyles(variant, $fullWidth)};
`;
