import { css, styled } from 'styled-components';
import { spacing } from 'styles/helpers';
import { theme, typography } from 'styles/theme';

export const DisclaimerWrapper = styled.div<{
  $fullWidth?: boolean;
}>`
  ${({ $fullWidth }) => disclaimerStyles($fullWidth)};
`;

export const ParagraphWrapper = styled.p`
  color: ${theme.colors.disclaimer};
  font: ${typography.body5};
  margin-bottom: ${spacing['2xs']};
`;

const disclaimerStyles = (fullWidth?: boolean) => css`
  padding-top: ${fullWidth ? '12px' : '64px'};
`;