import { useTranslation } from 'react-i18next';
import DownloadIcon from 'assets/download-icon';
import Button from 'components/button';
import { ButtonVariant } from 'components/button/types';
import useIsMobile from 'shared/hooks/useIsMobile';

interface DownloadButtonProps {
  onClick: () => void;
}

const DownloadButton = ({ onClick }: DownloadButtonProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Button
      onClick={onClick}
      content={t('productDetails.download')}
      variant={ButtonVariant.filled}
      fullWidth={isMobile}
      startIcon={<DownloadIcon />}
    />
  );
};

export default DownloadButton;
