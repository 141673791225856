import { styled } from 'styled-components';
import ScreenTitle from 'components/screen-title';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const Wrapper = styled.div`
  ${breakpoint.mobile} {
    padding: ${spacing.s} ${spacing.m};
  }
`;

export const Title = styled(ScreenTitle)`
  margin-top: ${spacing.xl};

  ${breakpoint.mobile} {
    margin-top: ${spacing.s};
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  gap: ${spacing.s};
  grid-template-columns: 1fr;
  margin: ${spacing.xl} 0 0 0;

  ${breakpoint.mobile} {
    margin: ${spacing.s} 0;
  }
`;

export const CardsGrid = styled.div`
  ${grid.threeColumn};
  border-bottom: ${({ theme }) => theme.border.default};
  padding-bottom: ${spacing.s};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;