import { View, Text } from '@react-pdf/renderer';
import parse from 'html-react-parser';
import { NameValuePair } from 'shared/types';
import styles from './styles';

interface TablePdfProps {
  title: string;
  data: NameValuePair[];
}

const TablePdf = ({ title, data }: TablePdfProps) => (
  <View style={styles.table}>
    <View style={styles.tableTitleRow}>
      <Text>{title}</Text>
    </View>
    {data.map((detail, index) => (
      <View
        wrap={false}
        style={
          index === data.length - 1 ? styles.tableRowLast : styles.tableRow
        }
        key={detail.name}
      >
        <View style={styles.detailName}>
          <Text hyphenationCallback={(word) => [word]}>{detail.name}</Text>
        </View>
        <View style={styles.detailValue}>
          <Text hyphenationCallback={(word) => [word]}>
            {parse(detail.value)}
          </Text>
        </View>
      </View>
    ))}
  </View>
);

export default TablePdf;
