import { styled } from 'styled-components';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing['2xl']};

  ${breakpoint.mobile} {
    padding-bottom: ${spacing['2xs']};
  }
`;

export const InputsWrapper = styled.div`
  ${grid.threeColumn};

  ${breakpoint.mobile} {
    margin-top: ${spacing.s};
    padding-bottom: ${spacing['2xs']};
  }
`;
