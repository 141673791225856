import { useTranslation } from 'react-i18next';
import { View, Text, Image } from '@react-pdf/renderer';
import EtexLogo from 'assets/etex-logo.png';
import styles from './styles';

const FooterPdf = () => {
  const { t } = useTranslation();
  const today = new Date();

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <View style={styles.footer} fixed>
      <View style={styles.footerTop}>
        <Text
          render={({ pageNumber, totalPages }) =>
            t('pdf.pageNumber', { pageNumber: `${pageNumber}/${totalPages}` })
          }
        />
        <Text>{t('pdf.createdOn', { date: formatDate(today) })}</Text>
      </View>
      <View style={styles.footerBottom}>
        <Text hyphenationCallback={(word) => [word]} style={styles.footerText}>
          {t('pdf.footerText')}
        </Text>
        <Image src={EtexLogo} style={styles.footerLogo} />
      </View>
    </View>
  );
};

export default FooterPdf;
