import styled from 'styled-components';
import { Body3 } from 'components/typography';
import { spacing } from 'styles/helpers';
import { muiTabStyles, muiTabsStyles } from 'styles/mixins';
import { theme } from 'styles/theme';

export const tabsStyles = {
  ...muiTabsStyles,

  '& .MuiTabs-flexContainer': {
    gap: spacing.s,
  },
};

export const fullWidthTabsStyles = {
  ...tabsStyles,

  '& .MuiTabs-flexContainer': {
    ['@media (max-width:768px)']: {
      gap: spacing['2xs'],
    },

    ['@media (max-width:360px)']: {
      gap: spacing['3xs'],
    },
  },
};

export const tabStyles = {
  ...muiTabStyles,
  borderBottom: `2px solid ${theme.colors.textDisabled}`,
  color: theme.colors.black,

  '&.Mui-focusVisible': {
    backgroundColor: theme.colors.highlight,
  },

  '&.Mui-selected': {
    color: theme.colors.black,
  },
};

export const TabLabel = styled(Body3)`
  @media (width <= 360px) {
    font-size: 12px;
  }
`;
