import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/loader';
import ProtectedSidesDisclaimer from 'components/disclaimer-with-background/protected-sides-disclaimer';
import SelectCard from 'components/select-card';
import Steps from 'components/steps';
import BottomActions from 'features/bottom-actions';
import { STEEL_ROUTES } from 'shared/constants';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { LoadbearingStructure, ProgressStep } from 'shared/types';
import useGetSteelProtectedSides from '../api/useGetSteelProtectedSides';
import { PROTECTED_SIDES_ICONS } from './constants';
import {
  filterProtectedSides,
  getProtectedSidesLabelText,
  getProtectedSidesTooltipText,
} from './logic';
import {
  CardsGrid,
  CardsWrapper,
  Title,
  Wrapper,
} from './styles';
import Disclaimer from 'components/disclaimer-with-background';
import useLocalStorage from 'shared/hooks/useLocalStorage';

const countriesToHideDisclaimer = ['AU'];

const ProtectedSides = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { steelProtectedSides, isLoading } = useGetSteelProtectedSides();
  const progressSteps = useProgressSteps(LoadbearingStructure.steel, true);
  const [, setProtectedSides] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelProtectedSides
  );
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);

  const protectedSides = filterProtectedSides(steelProtectedSides ?? []);

  const { getValueFromLocalStorage } = useLocalStorage();
  const selectedCountry = getValueFromLocalStorage(
    SESSION_STORAGE_KEYS.country
  );

  const handleProtectedSidesClick = (value: number) => {
    setProtectedSides(value);
    navigate(STEEL_ROUTES.environment);
  };

  const navigateBack = () => navigate(STEEL_ROUTES.structuralElements);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Wrapper>
        <Steps items={progressSteps} active={ProgressStep.protectedSides} />
        <Title title={t('steel.protectedSides.title')} displayContactSupport />
        <CardsWrapper>
          {protectedSides.map((protectedSidesArray, index) => (
            <CardsGrid key={index}>
              {protectedSidesArray.map((item) => (
                <SelectCard
                  key={item.id}
                  title={getProtectedSidesLabelText(item.sidesToProtect, t)}
                  info={getProtectedSidesTooltipText(
                    item.code,
                    t,
                    product.category
                  )}
                  onClick={() => handleProtectedSidesClick(item.id)}
                  iconPng={PROTECTED_SIDES_ICONS[item.code]}
                  displayArrow
                />
              ))}
            </CardsGrid>
          ))}
        </CardsWrapper>
        { !countriesToHideDisclaimer.includes(selectedCountry?.code) &&
          <Disclaimer title={t('steel.protectedSides.nonStandardConfigurations')}>
            <ProtectedSidesDisclaimer disclaimer={t('steel.protectedSides.nonStandardConfigurationsDisclaimer')} />
          </Disclaimer>
        }
      </Wrapper>
      <BottomActions onBackClick={navigateBack} />
    </>
  );
};

export default ProtectedSides;
