import {
  DryIcon,
  HighHumidityIcon,
  HumidConstructionDryServiceIcon,
} from 'assets/environment';
import { EnvironmentHumidity } from 'features/environment/types';
import { SvgIconSet } from 'shared/types';

export const HUMIDITY_ICONS: SvgIconSet = {
  [EnvironmentHumidity.Dry]: <DryIcon />,
  [EnvironmentHumidity.HighHumidity]: <HighHumidityIcon />,
  [EnvironmentHumidity.HumidConstructionDryService]: (
    <HumidConstructionDryServiceIcon />
  ),
};
