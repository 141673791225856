import { useState, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import InfoModal from 'components/info-modal';
import Tooltip from 'components/tooltip';
import { Heading5, Heading6 } from 'components/typography';
import useIsMobile from 'shared/hooks/useIsMobile';
import {
  CardTitleWrapper,
  ContentWrapper,
  StyledArrowIcon,
  LargeCardTitleWrapper,
  LargeContentWrapper,
  StyledInfoIcon,
  MoreInfoButton,
  StyledImg,
  IconWrapper,
  StyledIconImg,
  ImageContainer,
  Text,
  Card,
} from './styles';

export interface SelectCardProps {
  large?: boolean;
  title: string;
  text?: string;
  onClick?: () => void;
  info?: string;
  selected?: boolean;
  tooltipMaxWidth?: number;
  className?: string;
  displayArrow?: boolean;
  image?: string;
  iconPng?: string;
  iconBackground?: string;
  iconSvg?: ReactNode;
}

const SelectCard = ({
  large,
  title,
  text,
  onClick,
  info,
  selected,
  tooltipMaxWidth,
  className,
  displayArrow,
  image,
  iconPng,
  iconSvg,
  iconBackground,
}: SelectCardProps) => {
  const { t } = useTranslation();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleOpenModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setInfoModalOpen(true);
  };

  const handleCloseModal = () => setInfoModalOpen(false);

  return (
    <>
      <Card
        $large={large}
        onClick={onClick}
        selected={selected}
        className={className}
        type="button"
      >
        <>
          <ImageContainer $iconBackground={iconBackground}>
            {image && <StyledImg src={image} alt={title} />}
            {iconPng && (
              <IconWrapper>
                <StyledIconImg src={iconPng} alt="" />
              </IconWrapper>
            )}
            {iconSvg && <IconWrapper $iconSvg>{iconSvg}</IconWrapper>}
          </ImageContainer>

          {large ? (
            <LargeContentWrapper>
              <LargeCardTitleWrapper>
                <Heading5>{title}</Heading5>
                {displayArrow && <StyledArrowIcon />}
              </LargeCardTitleWrapper>
              <Text>{text}</Text>
            </LargeContentWrapper>
          ) : (
            <ContentWrapper>
              <CardTitleWrapper>
                <Heading6>{parse(title ?? '')}</Heading6>
                {info &&
                  (isMobile ? (
                    <MoreInfoButton onClick={handleOpenModal}>
                      {t('common.moreInfo')}
                    </MoreInfoButton>
                  ) : (
                    <Tooltip text={info} maxWidth={tooltipMaxWidth}>
                      <StyledInfoIcon />
                    </Tooltip>
                  ))}
              </CardTitleWrapper>
              {displayArrow && <StyledArrowIcon />}
            </ContentWrapper>
          )}
        </>
      </Card>
      {info && (
        <InfoModal
          open={infoModalOpen}
          title={title}
          text={info}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default SelectCard;
