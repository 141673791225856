import { keyframes, styled } from 'styled-components';

const LOADER_DIMENSIONS = '50px';

const rotateAnimation = keyframes`to { transform: rotate(.5turn); }`;

export const LoaderOverlay = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

export const SpinningLoader = styled.div`
  animation: ${rotateAnimation} 0.8s infinite;
  border: 5px solid;
  border-color: ${({ theme }) => theme.colors.primary}
    ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  height: ${LOADER_DIMENSIONS};
  width: ${LOADER_DIMENSIONS};
`;
