import env from '@beam-australia/react-env';

const useIsFeatureEnabled = (envVarName: string) => {
  if (!env(envVarName)) {
    return;
  }

  const isFeatureEnabled = env(envVarName) === 'true';

  return isFeatureEnabled;
};

export default useIsFeatureEnabled;
