import { spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

export const helperTextStyles = {
  ...theme.typography.body3,
  margin: `${spacing['2xs']} 0 0`,

  '&.Mui-error': {
    color: theme.colors.error,
  },
};
