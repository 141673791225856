export const SESSION_STORAGE_KEYS = {
  country: 'country',
  structure: 'structure',
  product: 'product',
  inputParametersFromResults: 'inputParametersFromResults',
  steelStructuralElements: 'steelStructuralElements',
  steelProtectedSides: 'steelProtectedSides',
  steelEnvironment: 'steelEnvironment',
  steelFireRating: 'steelFireRating',
  concreteStructuralElements: 'concreteStructuralElements',
  concreteEnvironment: 'concreteEnvironment',
  concreteFireRating: 'concreteFireRating',
};

export const removeFromSession = (key: string) =>
  window.sessionStorage.removeItem(key);
